import React from "react";
import { inject } from "mobx-react";

import { Table, Segment } from "semantic-ui-react";
import { Image } from "../../models/Image";
import { Answer } from "../../models/model_types/classification";
import { S3Image } from "../helpers/S3Image";
import { ProvidedAppStore } from "../../store/AppStore";

import { fnGenerateVerifiedByJSX } from "../helpers/AnnotationInfo";

interface ClassificationExplorerProps {
    image: Image;
}
type Props = ClassificationExplorerProps & ProvidedAppStore;

const certaintyMap = new Map<number, string>([
    [0, "Unknown"],
    [3, "Very Uncertain"],
    [5, "Some Uncertainty"],
    [10, "Completely Certain"]]);

// Note: visibility was used as a predecessor to certainty
// Legacy files have a visibility annotation but no certainty annotation
const visibilityMap = new Map<number, string>([
    [0, "Completely Certain"],
    [1, "Some Uncertainty"],
    [2, "Very Uncertain"]]);

@inject("store")
export class ClassificationExplorer extends React.Component<Props> {
    render() {
        let timeFormat = { day: "numeric", month: "long", year: "numeric" };

        const { annotations } = this.props.image;

        if (annotations.length === 0) {
            return (
                <Segment compact>
                    <S3Image
                        size="large"
                        url={this.props.store!.hwkflowClient.getImageUrl(
                            this.props.image.bucketPath
                        )}
                    />
                    <Table compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Image</Table.HeaderCell>
                                <Table.HeaderCell>{this.props.image.id}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Sport:</Table.Cell>
                                <Table.Cell>{this.props.image.sport}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Stadium:</Table.Cell>
                                <Table.Cell>{this.props.image.stadium}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Date Uploaded:</Table.Cell>
                                <Table.Cell>
                                    {new Date(this.props.image.uploadedAt).toLocaleString(
                                        "en-US",
                                        timeFormat
                                    )}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Dataset Type: </Table.Cell>
                                <Table.Cell>{this.props.image.datasetType}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Annotation:</Table.Cell>
                                <Table.Cell>Not Annotated</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Certainty:</Table.Cell>
                                <Table.Cell>Unknown</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
            );
        }
        const lastAnnotation = annotations[annotations.length - 1];
        const answer = lastAnnotation.answerData as Answer;
        let verifiedByJSX = <div />;

        if (lastAnnotation.verifier) {
            verifiedByJSX = fnGenerateVerifiedByJSX(lastAnnotation);
        }

        return (
            <Segment compact>
                {verifiedByJSX}
                <S3Image
                    url={this.props.store!.hwkflowClient.getImageUrl(this.props.image.bucketPath)}
                    size="large"
                />
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Image</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.image.id}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Sport:</Table.Cell>
                            <Table.Cell>{this.props.image.sport}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Stadium:</Table.Cell>
                            <Table.Cell>{this.props.image.stadium}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date Uploaded:</Table.Cell>
                            <Table.Cell>
                                {new Date(this.props.image.uploadedAt).toLocaleString(
                                    "en-US",
                                    timeFormat
                                )}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Dataset Type: </Table.Cell>
                            <Table.Cell>{this.props.image.datasetType}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Annotation:</Table.Cell>
                            <Table.Cell>
                                {answer[2]
                                    ? answer[2].string === ""
                                        ? "No Numbers"
                                        : answer[2].string
                                    : "Not annotated"}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Certainty:</Table.Cell>
                            <Table.Cell>
                                {answer.length >= 4
                                    ? answer[4]
                                        ? certaintyMap.get(Number(answer[4]["choice"]))
                                        : "Unknown"
                                    : answer[1]
                                        ? visibilityMap.get(Number(answer[1]["choice"]))
                                        : "Unknown"
                                }
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}
