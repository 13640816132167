import { inject } from "mobx-react";
import React from "react";
import { Definition, SkeletonAnnotationAnswer } from "../../models/model_types/keypoints";
import { Image as SVGImage, InstanceGroupCrossValidation } from "../annotation/KeyPoint/Image";
import { ProvidedAppStore } from "../../store/AppStore";
import { Image } from "../../models/Image";
import { Divider, Grid, Icon, Input, Label, Segment, SemanticCOLORS } from "semantic-ui-react";

interface KeyPointExplorerProps {
    image: Image;
}

interface GroupName {
    name: string;
    color: string;
}

interface State {
    visibleGroups: InstanceGroupCrossValidation[];
}

const colors = ["orange", "olive", "purple", "pink", "blue"];

type Props = KeyPointExplorerProps & ProvidedAppStore;
@inject("store")
export class KeyPointCrossValExplorer extends React.Component<Props, State> {
    private groupNames: GroupName[] = [];
    private noAnnotations: string[] = [];
    private discarded: string[] = [];

    constructor(props: Props) {
        super(props);
        this.state = { visibleGroups: [] };
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.loadAnnotations();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.image !== prevProps.image) {
            this.loadAnnotations();
        }
    }

    loadAnnotations = () => {
        const { annotations } = this.props.image;
        const initialGroups: InstanceGroupCrossValidation[] = [];
        const labelOpacities: { [key: string]: number } = {};
        this.groupNames = [];
        this.noAnnotations = [];
        this.discarded = [];

        for (let idx = 0; idx < annotations.length; ++idx) {
            const annotation = annotations[idx];
            const color = colors[idx % colors.length];
            const answer = annotation.answerData as SkeletonAnnotationAnswer;
            if (answer.instances.length > 0) {
                this.groupNames.push({ color: color, name: annotation.user.name });
                initialGroups.push({ color: color, instances: answer.instances, opacity: 1 });
                labelOpacities[annotation.user.name] = 1;
            } else if (annotation.discarded) {
                this.discarded.push(annotation.user.name);
            } else {
                this.noAnnotations.push(annotation.user.name);
            }
        }

        this.setState({ visibleGroups: initialGroups });
    };

    toggleGroupVisibility = (groupName: string, color: string, opacity: number) => {
        this.setState((prevState) => {
            const { visibleGroups } = prevState;
            const isVisible = visibleGroups.some(
                (group) =>
                    group.instances ===
                    this.props.image.annotations.find(
                        (annotation) => annotation.user.name === groupName
                    )?.answerData.instances
            );

            if (isVisible) {
                return {
                    visibleGroups: visibleGroups.filter(
                        (group) =>
                            group.instances !==
                            this.props.image.annotations.find(
                                (annotation) => annotation.user.name === groupName
                            )?.answerData.instances
                    ),
                };
            } else {
                // Find the group to add
                const groupToAdd = this.props.image.annotations.find(
                    (annotation) => annotation.user.name === groupName
                );
                return {
                    visibleGroups: groupToAdd
                        ? [
                              ...visibleGroups,
                              {
                                  color: color,
                                  instances: groupToAdd.answerData.instances,
                                  opacity: opacity,
                              },
                          ]
                        : visibleGroups,
                };
            }
        });
    };

    handleOpacities = (userName: string, opacity: number) => {
        this.setState((prevState) => {
            const { visibleGroups } = prevState;

            const updateGroup = visibleGroups.map((group) => {
                if (
                    group.instances ===
                    this.props.image.annotations.find(
                        (annotation) => annotation.user.name === userName
                    )?.answerData.instances
                ) {
                    return { ...group, opacity };
                }
                return group;
            });

            return { visibleGroups: updateGroup };
        });
    };

    render() {
        const { modelType, bucketPath } = this.props.image;
        const definition = modelType.definition as Definition;

        const empty = (
            <div style={{ marginLeft: "85px" }}>
                <Icon name="ban" />
            </div>
        );
        const withAnnotationsJSX =
            this.groupNames.length > 0
                ? this.groupNames.map((group: GroupName, idx: number) => {
                      const opacity =
                          this.state.visibleGroups.find(
                              (g) =>
                                  g.instances ===
                                  this.props.image.annotations.find(
                                      (annotation) => annotation.user.name === group.name
                                  )?.answerData.instances
                          )?.opacity || 1;

                      const disable =
                          this.state.visibleGroups.find((g) => g.color === group.color) ===
                          undefined;
                      return (
                          <div>
                              <Label
                                  color={group.color as SemanticCOLORS}
                                  key={idx}
                                  onClick={() =>
                                      this.toggleGroupVisibility(group.name, group.color, opacity)
                                  }
                                  style={{
                                      cursor: "pointer",
                                      textDecoration: disable ? "line-through black" : "none",
                                      whiteSpace: "nowrap",
                                  }}
                              >
                                  {group.name}
                              </Label>

                              <Input
                                  type="range"
                                  min="0.1"
                                  max="1.0"
                                  step="0.01"
                                  value={opacity}
                                  disabled={disable}
                                  onChange={(e) =>
                                      this.handleOpacities(group.name, parseFloat(e.target.value))
                                  }
                              ></Input>
                          </div>
                      );
                  })
                : empty;

        const noAnnotationJSX =
            this.noAnnotations.length > 0
                ? this.noAnnotations.map((user: string) => {
                      return (
                          <div style={{ marginTop: "10px", whiteSpace: "nowrap" }}>
                              <Label color={"grey" as SemanticCOLORS}>{user}</Label>
                          </div>
                      );
                  })
                : empty;

        const discardedJSX =
            this.discarded.length > 0
                ? this.discarded.map((user: string) => {
                      return (
                          <div style={{ marginTop: "10px", whiteSpace: "nowrap" }}>
                              <Label color={"grey" as SemanticCOLORS}>{user}</Label>
                          </div>
                      );
                  })
                : empty;

        return (
            <>
                <Grid>
                    <Grid.Row style={{ marginTop: "40px" }}>
                        <Grid.Column width={10} textAlign="left">
                            <SVGImage
                                groups={this.state.visibleGroups}
                                url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                                definition={definition.keypoints}
                            />
                        </Grid.Column>
                        <Grid.Column widht={8} textAlign="left">
                            <h2
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    marginTop: "20px",
                                    paddingLeft: "50px",
                                }}
                            >
                                Annotators
                            </h2>
                            <Segment
                                style={{
                                    border: "1px solid #dee2e6",
                                    padding: "15px",
                                    paddingRight: "220px",
                                }}
                            >
                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="bookmark" />
                                    With annotation
                                </h4>
                                {withAnnotationsJSX}

                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="bookmark outline" /> Not annotated
                                </h4>
                                {noAnnotationJSX}

                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="trash" />
                                    Discarded
                                </h4>
                                {discardedJSX}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider hidden={true} />
                </Grid>
            </>
        );
    }
}
