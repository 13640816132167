import React from "react";
import { Divider, Menu, Button, Grid, Input, Dropdown, Popup } from "semantic-ui-react";
import { Container, Header, Form } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { ModelType } from "../../models/ModelType";

import { Loading } from "../helpers/Loading";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";
import { getImageStates, ImageState, ImageStateUse } from "../../models/Image";

interface ModelSummaryProps extends ProvidedAppStore {
    modelType?: ModelType;
}
interface ModelSummaryState {
    sports: string[];
    stadiums: string[];
    current: ImageState[];
    destination: ImageState;
    amount: number;
    enabled: boolean;
}

@inject("store")
@observer
class ModelSummary extends React.Component<ModelSummaryProps, ModelSummaryState> {
    constructor(props: ModelSummaryProps) {
        super(props);
        this.state = {
            sports: [],
            stadiums: [],
            current: [ImageState.Unused],
            destination: ImageState.Unused,
            amount: -1,
            enabled: true,
        };
    }

    handleGeneralChange = (event: any, data: any) => {
        const { name, value } = data;
        this.setState({ [name]: value } as Pick<ModelSummaryState, keyof ModelSummaryState>);
    };

    fnInputSelection = (name: string, values: string[], choices: string[]) => {
        let allChoices = [];
        allChoices.push(...choices);
        const options = allChoices.map((choice: string) => {
            return { text: choice, value: choice };
        });

        return (
            <Form.Field>
                <Dropdown
                    clearable
                    multiple
                    search
                    selection
                    name={name}
                    options={options}
                    placeholder={name}
                    onChange={this.handleGeneralChange}
                    defaultValue={values}
                />
            </Form.Field>
        );
    };

    handleNumberEntry = (e: any) => {
        const re = /^[0-9\b]+$/;
        //Check that entry is a number
        if (e.target.value !== "" && re.test(e.target.value)) {
            this.setState({ amount: e.target.value, enabled: true });
        } else if (e.target.value === "") {
            this.setState({ amount: -1, enabled: true });
        } else {
            this.setState({ amount: -1, enabled: false });
        }
    };

    generateDropdown = (entries: any) => {
        const dropDown = entries.map((entry: string) => {
            return {
                key: entry,
                text: entry,
                value: entry,
            };
        });
        return dropDown;
    };

    render() {
        if (this.props.modelType == null) {
            return <Loading />;
        }

        const { modelType, store } = this.props;
        const { modelStore } = store!;

        let discoverImagesJSX = <></>;

        discoverImagesJSX = (
            <Menu secondary={true}>
                <Menu.Item position="right">
                    <Button positive onClick={() => modelStore.discoverImages()}>
                        Discover Images
                    </Button>
                </Menu.Item>
            </Menu>
        );

        const pruneJSX = <Button negative>Prune Images</Button>;
        const pruneConfirmationPopUp = (
            <ConfirmationPopUp
                trigger={pruneJSX}
                description="This will remove all images marked as Unused in the database, and delete them from storage too. This is irreversible!"
                onYes={() => {
                    modelStore.pruneImages();
                }}
                onNo={() => {}}
            />
        );
        const moveJSX = (
            <Button negative disabled={!this.state.enabled}>
                Move Images
            </Button>
        );
        let numImages = this.state.amount !== -1 ? String(this.state.amount) : "all";
        let moveConfirmationPopUp = (
            <ConfirmationPopUp
                trigger={moveJSX}
                description={
                    "This will move " +
                    numImages +
                    " images of sports: " +
                    this.state.sports.join(" ") +
                    ", stadiums: " +
                    this.state.stadiums.join(" ") +
                    " from " +
                    this.state.current.join(" ") +
                    " to " +
                    this.state.destination
                }
                onYes={() => {
                    modelStore.moveImages(
                        this.state.sports,
                        this.state.stadiums,
                        this.state.current,
                        this.state.destination,
                        this.state.amount
                    );
                }}
                onNo={() => {}}
            />
        );
        if (this.state.current.length === 0) {
            moveConfirmationPopUp = (
                <Popup content="Please select at least one current state" trigger={moveJSX} />
            );
        }

        const destinationStatesDropDown = this.generateDropdown(Object.keys(ImageState));

        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Header
                            as="h2"
                            content={modelType.name}
                            subheader={`${modelType.type} - ${modelType.id}`}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Menu secondary={true}>
                            <Menu.Item position="right">{pruneConfirmationPopUp}</Menu.Item>
                            <Menu.Item position="right">{discoverImagesJSX}</Menu.Item>
                        </Menu>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}>{moveConfirmationPopUp}</Grid.Column>
                        <Grid.Column width={3}>
                            {this.fnInputSelection("sports", this.state.sports, modelStore.sports)}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {this.fnInputSelection(
                                "stadiums",
                                this.state.stadiums,
                                modelStore.stadiums
                            )}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {this.fnInputSelection(
                                "current",
                                this.state.current,
                                getImageStates(ImageStateUse.Explorer)
                            )}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Form.Dropdown
                                selection
                                options={destinationStatesDropDown}
                                placeholder={"destination"}
                                onChange={this.handleGeneralChange}
                                defaultValue={this.state.destination}
                                name={"destination"}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Form.Field
                                control={Input}
                                onChange={this.handleNumberEntry}
                                placeholder="Specific Number"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

@inject("store")
@observer
export class Admin extends React.Component<ProvidedAppStore, {}> {
    render() {
        const modelStore = this.props.store!.modelStore;

        return (
            <>
                <Divider horizontal>Model Summary</Divider>
                <ModelSummary modelType={modelStore.modelType} />
            </>
        );
    }
}
