import React from "react";
import { inject, observer } from "mobx-react";
import { Bar } from "react-chartjs-2";
import { Grid, Dropdown } from "semantic-ui-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { SessionKey, SessionData } from "../../store/AdminStore";

import { SessionChartWithOptionsProps, timeOptions, colours } from "./MetricsHelpers";

interface SessionLineChartProps {
    filterBy: { [keyName: string]: string };
    since: Date;
    to: Date;
    measureBy: string;
    measureText: string;
}

type ChartProps = ProvidedAppStore & SessionLineChartProps;

@inject("store")
@observer
class SessionLineChart extends React.Component<ChartProps> {
    render() {
        const adminStore = this.props.store!.adminStore;
        let data = adminStore.sessionData.slice();

        data = data.filter((elem: SessionData) => {
            return Object.entries(this.props.filterBy).every((filterElem) => {
                const key = filterElem[0] as keyof SessionKey;

                const dateOfCreation = new Date(elem.key.dateOfSession);
                const timeSinceSince = dateOfCreation.getTime() - this.props.since.getTime();
                const goodSince = timeSinceSince >= 0;
                const timeSinceTo = dateOfCreation.getTime() - this.props.to.getTime();
                const goodTo = timeSinceTo < 0;

                const goodFilterBy = filterElem[1] === "All" || elem.key[key] === filterElem[1];
                return goodSince && goodTo && goodFilterBy;
            });
        });

        // create our days... check with daysAsDate

        const msPerDay = 24 * 60 * 60 * 1000;
        const numDays = (this.props.to.getTime() - this.props.since.getTime()) / msPerDay;

        let daysAsDate: Date[] = [];

        for (let idx = 0; idx <= numDays; ++idx) {
            const date = new Date(this.props.since.getTime() + idx * msPerDay);
            daysAsDate.push(date);
        }

        let datasets: Chart.ChartDataSets[] = [];

        let values = daysAsDate.map((date) => 0);

        data.forEach((elem: SessionData) => {
            const date = new Date(elem.key.dateOfSession);
            const idx = daysAsDate.findIndex((day) => {
                const dayBegin = day.getTime();
                const dayEnd = dayBegin + msPerDay;
                const elemDate = date.getTime();

                return dayBegin <= elemDate && elemDate < dayEnd;
            });
            values[idx] += elem.totalTime / 3600000;
        });

        if (this.props.measureBy === "cumulative") {
            for (let idx = 1; idx < values.length; ++idx) {
                values[idx] += values[idx - 1];
            }
        }

        datasets.push({
            label: "Hours",
            data: values,
            borderWidth: 1,
            backgroundColor: colours[0 % colours.length],
        });

        const chartData: Chart.ChartData = {
            labels: daysAsDate.map((date) => date.toLocaleDateString()),
            datasets: datasets,
        };

        const chartOptions: Chart.ChartOptions = {
            title: {
                display: true,
                text: `Sessions from ${this.props.since.toDateString()} to ${this.props.to.toDateString()}`,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        },
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                        },
                    },
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                            labelString: this.props.measureText,
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    bottom: 50,
                },
            },
            responsive: true,
        };

        return <Bar data={chartData} options={chartOptions} />;
    }
}

interface State {
    since: string;
    measureBy: string;
}

type Props = ProvidedAppStore & SessionChartWithOptionsProps;

@inject("store")
@observer
export class SessionLineChartWithOptions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { since: "Last Week", measureBy: "perDay" };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event: any, data: any) {
        type NewState = Pick<State, keyof State>;
        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    render() {
        const { sinceDropDown, selectedTime } = timeOptions(new Date(), this.state.since);

        const measureByDropDown = [
            {
                key: "perDay",
                text: "Number of Hours Per Day",
                value: "perDay",
            },
            {
                key: "cumulative",
                text: "Cumulative Hours",
                value: "cumulative",
            },
        ];

        const measureBy = measureByDropDown.find((elem) => elem.value === this.state.measureBy);

        if (!selectedTime || !measureBy) {
            return <div>error!</div>;
        }

        return (
            <>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={4}>
                        <Dropdown
                            name="since"
                            header="Hours Since"
                            fluid
                            selection
                            value={this.state.since}
                            options={sinceDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Dropdown
                            name="measureBy"
                            header="Measure By"
                            fluid
                            selection
                            value={this.state.measureBy}
                            options={measureByDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={12}>
                        <SessionLineChart
                            filterBy={this.props.filterBy}
                            since={selectedTime.since}
                            to={selectedTime.to}
                            measureBy={measureBy.value}
                            measureText={measureBy.text}
                        />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }
}
