import { runInAction, observable, action, makeAutoObservable } from "mobx";

import { Image, ImageStateUse } from "../models/Image";
import { HwkFlowApiClient } from "./Client";
import { Sport } from "../models/Sport";

export interface FilterState {
    imageStates: string[];
    imageScanTypes: string[];
    cameraTypes: string[];
    add_sports: string[];
    add_stadiums: string[];
    applications: string[];
    uploadedAt: string[];
    tags: string[];
    start_date: Date;
    end_date: Date;
    intersectTags: boolean;
}

export function cloneFilterState(current: FilterState): FilterState {
    return {
        imageStates: [...current.imageStates],
        imageScanTypes: [...current.imageScanTypes],
        cameraTypes: [...current.cameraTypes],
        add_sports: [...current.add_sports],
        add_stadiums: [...current.add_stadiums],
        applications: [...current.applications],
        uploadedAt: [...current.uploadedAt],
        tags: [...current.tags],
        start_date: current.start_date,
        end_date: current.end_date,
        intersectTags: current.intersectTags,
    };
}

export class ImageStore {
    client: HwkFlowApiClient;
    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    numPerRow = 4 as const;
    numRows = 5;
    numPerPage = this.numRows * this.numPerRow;

    @observable
    public taskName: string = "";

    @observable
    public imageIds: number[] = [];

    @observable
    public images: Image[] = [];

    @observable
    public page: number = 0;

    @observable
    public active: boolean = false;

    @observable
    public filterState: FilterState = {
        imageStates: ["NeedsAnnotation"],
        imageScanTypes: [],
        cameraTypes: [],
        add_sports: [],
        add_stadiums: [],
        applications: [],
        uploadedAt: [],
        tags: [],
        start_date: new Date(0),
        end_date: new Date(),
        intersectTags: false,
    };

    @observable
    public numPendingRequests: number = 0;

    // Note, for either of the following functions, execution is blocked until
    // the request has a response, and there is nothing to stop another request
    // being sent through this function. If that happens, there is no guarantee
    // that the responses will come back in the same order that they were sent
    // (particularly if the second one completes quickly). I couldn't see a
    // simple solution to this, so I made sure that UI components to send requests
    // are disabled until the response is back - use isPending() to see if there
    // are pending responses
    @action
    async fetchImages() {
        const imageFrom = this.page * this.numPerPage;
        const imageTo = imageFrom + this.numPerPage;

        let imageIds = this.imageIds.slice();
        const numImages = imageIds.length;

        if (numImages !== 0 && imageFrom >= numImages) {
            this.page = 0;
            return;
        }

        imageIds = this.imageIds.slice(imageFrom, imageTo);

        ++this.numPendingRequests;
        const response = await this.client.fetchImages(imageIds, false);
        runInAction(() => {
            this.images = response.data as Image[];
            --this.numPendingRequests;
        });
    }

    @action
    async fetchImageIds() {
        ++this.numPendingRequests;
        let image_state_use = ImageStateUse.Explorer;

        const response = await this.client.fetchModelImages(
            this.taskName,
            image_state_use,
            this.filterState,
        );

        runInAction(() => {
            this.imageIds = response.data["image_ids"] as number[];
            this.imageIds = this.imageIds.sort((a, b) => a - b);
            --this.numPendingRequests;
        });

        await this.fetchImages();
    }

    @action
    async setFilterState(filterState: FilterState) {
        await this.setState(this.taskName, filterState, this.page);
    }

    @action
    async setTaskAndPage(taskName: string, page: number) {
        let filterState = this.filterState;
        if (taskName !== this.taskName) {
            filterState = {
                imageStates: ["NeedsAnnotation"],
                cameraTypes: [],
                imageScanTypes: [],
                add_stadiums: [],
                add_sports: [],
                applications: [],
                uploadedAt: [],
                tags: [],
                start_date: new Date(0),
                end_date: new Date(),
                intersectTags: false,
            };
        }

        await this.setState(taskName, filterState, page);
    }

    @action
    async getTaskById(taskId: number) {
        try {
            const response = await this.client.fetchTaskById(taskId);
            return response.data;
        } catch (error) {
            console.error(`Error fetching task ID ${taskId}`, error);
            throw error;
        }
    }

    @action
    async setState(taskName: string, filterState: FilterState, page: number) {
        if (taskName === this.taskName && this.filterState === filterState && this.page !== page) {
            this.page = page;
            await this.fetchImages();
        } else {
            this.page = page;
            this.taskName = taskName;
            this.filterState = filterState;
            await this.fetchImageIds();
        }
    }

    @action
    setActive(active: boolean) {
        this.active = active;
    }

    isPending() {
        return this.numPendingRequests > 0;
    }

    // Colors requests
    @action
    async getColors() {
        try {
            const response = await this.client.fetchColors();
            return response.data as string[];
        } catch (error) {
            console.error("Error fetching colors", error);
            throw error;
        }
    }

    @action
    async addNewColor(newColor: string) {
        try {
            await this.client.addColor(newColor);
        } catch (error) {
            console.error("Error adding new color", error);
            throw error;
        }
    }

    // Team metrics kits requests.
    @action
    async getKitsByAttributes(
        sport: string,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[],
    ) {
        try {
            const response = await this.client.fetchKitsByAttributes(
                sport,
                jerseyColors,
                shortColor,
                socksColor,
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching matching kits by attributes", error);
            throw error;
        }
    }

    @action
    async getKitByTeamId(teamId: number) {
        try {
            const response = await this.client.fetchKitById(teamId);
            return response.data;
        } catch (error) {
            console.error("Error fetching matching kits by teamId", error);
            throw error;
        }
    }

    @action
    async checkKitUpdate(
        teamId: number,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[],
    ) {
        try {
            const response = await this.client.checkAndUpdateKit(
                teamId,
                jerseyColors,
                shortColor,
                socksColor,
            );
            return response.data;
        } catch (error) {
            console.error("Error checking and updating the kit", error);
            throw error;
        }
    }

    @action
    async addNewKit(
        sport: string,
        jerseyColors: string[],
        shortColor: string[],
        socksColor: string[],
    ) {
        try {
            const response = await this.client.addKit(sport, jerseyColors, shortColor, socksColor);
            return response.data;
        } catch (error) {
            console.error("Error creating new kit", error);
            throw error;
        }
    }

    // Team metrics requests.
    @action
    async addNewTeamMetrics(teamId: number, imageId: number) {
        try {
            const response = await this.client.addTeamMetrics(teamId, imageId);
            return response.data;
        } catch (error) {
            console.error("Error addding new team metrics data", error);
            throw error;
        }
    }

    @action
    async getTeamMetricsById(teamId: number) {
        try {
            const response = await this.client.fetchTeamMetricsById(teamId);
            return response.data;
        } catch (error) {
            console.error("Error fetching team metric given the teamId", error);
            throw error;
        }
    }

    @action
    async removeTeamMetricsByImageId(imageId: number) {
        try {
            const response = await this.client.deleteTeamMetricsByImageId(imageId);
            return response.data;
        } catch (error) {
            console.error("Error fetching team metric given the teamId", error);
            throw error;
        }
    }

    @action
    async getCrossValidationImages(
        modelTypeId: string,
        stadiumArray: string[],
        sportsArray: Sport[]
    ) {
        try {
            const response = await this.client.fetchCrossValidationImages(
                modelTypeId,
                stadiumArray,
                sportsArray
            );
            return response.data;
        } catch (error) {
            console.error(
                "Error fetching cross validation image given the model type, stadium and sport",
                error
            );
            throw error;
        }
    }
}
