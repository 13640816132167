import { Definition as KeyPointDefinition } from "./model_types/keypoints";
import { Definition as ClassificationDefinition } from "./model_types/classification";
import { Definition as ObjectDetectionDefinition } from "./model_types/object_detection";
import { Definition as MetricDefinition } from "./model_types/metric";
import { DefinitionJSON as KeyPointDefinitionJSON } from "./model_types/keypoints";
import { DefinitionJSON as ClassificationDefinitionJSON } from "./model_types/classification";
import { DefinitionJSON as ObjectDetectionDefinitionJSON } from "./model_types/object_detection";
import { DefinitionJSON as MetricDefinitionJSON } from "./model_types/metric";

import { Image } from "./Image";
import { Model } from "./Model";

export type ModelDefinitionJSON =
    | KeyPointDefinitionJSON
    | ClassificationDefinitionJSON
    | ObjectDetectionDefinitionJSON
    | MetricDefinitionJSON;
export type ModelDefinition =
    | KeyPointDefinition
    | ClassificationDefinition
    | ObjectDetectionDefinition
    | MetricDefinition;

export enum ModelTypes {
    KeyPoints = "KeyPoints",
    Classification = "Classification",
    ObjectDetection = "ObjectDetection",
    Metric = "Metric",
}

export interface ModelType {
    id: string;
    name: string;
    description: string;
    thumbnail: string;
    type: ModelTypes;
    definition: ModelDefinition;
    images: Array<Image>;
    models: Array<Model>;
    expectedTime: number;
    bucketName: string;
}
