import { inject, observer } from "mobx-react";
import React from "react";
import {
    Button,
    Card,
    Container,
    Grid,
    Header,
    Icon,
    Loader,
    SemanticWIDTHS,
} from "semantic-ui-react";
import { ProvidedAppStore } from "../../store/AppStore";
import { RouteComponentProps } from "react-router";
import { CrossValidationStore } from "../../store/CrossValidationStore";
import { Image } from "../../models/Image";
import { Pagination } from "../helpers/Pagination";
import { S3Image } from "../helpers/S3Image";
import { CrossValidationFilter } from "./CrossValidationFilter";
import { Link } from "react-router-dom";

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class CrossValidationExplorerPage extends React.Component<Props> {
    store: CrossValidationStore | undefined;
    gridRows: number = 5;
    gridColumns: number = 4;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.crossValidationStore;
    }

    async componentDidMount() {
        if (!this.store) return;
        this.store.setPageSize(this.gridColumns * this.gridRows);
        this.store.calculateTotalPages();

        const routeParams = this.fetchRouteParameters();
        const typeName = routeParams.type;
        this.store.typeModelName = typeName;

        let desiredPage = routeParams.page;
        if (desiredPage > this.store.totalPages) desiredPage = 0;
        this.updatePage(desiredPage, typeName);

        await this.store.fetchFilteredCrossValidationIdsAndCount();
    }

    async componentDidUpdate() {
        if (!this.store) return;
        const routeParams = this.fetchRouteParameters();
        const type = routeParams.type;
        this.store.typeModelName = type;
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { type, page } = params as any;
        return { type: type, page: parseInt(page) };
    }

    async updatePage(newPage: number, type: string) {
        if (!this.store) return;

        this.props.history.push(`/cross-validation-explorer/${type}/${newPage}`);
        this.store.setCurrentPage(newPage);
        this.store.fetchEntitiesForCurrentExplorePage();
    }

    generateCrossValidationCardsJSX() {
        if (!this.store || this.store.requestPending) return <Loader />;

        return this.store.currentImage.map((image: Image) => {
            return (
                <Card key={image.id}>
                    <S3Image
                        url={this.store!.getCrossValUrl(image.bucketPath)}
                        wrapped={true}
                        ui={false}
                    />
                    <Card.Content>
                        <Card.Header>
                            Image {image.id}
                            <Icon name="image" color="grey" style={{ float: "right" }} />
                        </Card.Header>
                        <Card.Meta>
                            {image.sport}, {image.stadium}
                        </Card.Meta>
                        <Card.Meta style={{ fontStyle: "italic" }}>
                            {new Date(image.recordedAt).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                            })}
                        </Card.Meta>
                        <Grid>
                            <Grid.Column>
                                <Button
                                    icon
                                    basic
                                    as={Link}
                                    to={`/cross-validation-view/${image.id}`}
                                >
                                    <Icon name="external alternate" />
                                    View
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                </Card>
            );
        });
    }

    render() {
        let pagination = (
            <Pagination
                page={this.store?.currentPage || 0}
                maxPage={this.store?.totalPages || 0}
                updatePage={(page: number) =>
                    this.updatePage(page, this.store?.typeModelName || "")
                }
            />
        );

        return (
            <Container>
                <Header>Explore Cross Validation {this.store?.typeModelName}</Header>
                <CrossValidationFilter />

                <Header as="h3"> Number of images: {this.store?.filteredCount || 0}</Header>
                <Grid>
                    <Grid.Row>{pagination}</Grid.Row>
                    <Grid.Row>
                        <Card.Group itemsPerRow={this.gridColumns as SemanticWIDTHS}>
                            {this.generateCrossValidationCardsJSX()}
                        </Card.Group>
                    </Grid.Row>
                    <Grid.Row>{pagination}</Grid.Row>
                </Grid>
            </Container>
        );
    }
}
