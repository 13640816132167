import * as React from "react";
import { Image, StrictImageProps } from "semantic-ui-react";

interface S3ImageProps extends StrictImageProps {
    url: string;
}

export function S3Image(props: S3ImageProps) {
    if (props.url === "") {
        return <></>;
    }

    return <Image src={props.url} {...props} />;
}
