import { observable, action, makeAutoObservable, runInAction } from "mobx";

import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../constants/auth";
import { User } from "../models/User";
import { HwkFlowApiClient } from "./Client";

export enum QueryStatuses {
    LOADING = "loading",
    SUCCESS = "success",
    ERROR = "error",
}

export class UserAuthStore {
    client: HwkFlowApiClient;
    controller: AbortController = new AbortController();
    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
        // we get status from the updateSessions,
        // I guess we don't need to get data with interval now
        // because if change smth via keycloak user should relogin
        // setInterval(this.checkAuthentication, 60_000);
    }

    @observable
    waitForStatus: boolean = true;

    @observable
    userQueryStatus: string = QueryStatuses.LOADING;

    @observable
    user: User | null = null;

    @observable
    authError: boolean = false;

    @observable
    appError: boolean = false;

    @action
    public setUser = async (user: User) => {
        console.log("Checking login status...");
        const response = await this.client.setCurrentUser(user.name, user.department);

        if (response.status !== 200) {
            console.warn(`failed to set name and department!`);
        }

        await this.checkAuthentication();
        window.location.href = "/";
    };

    @action
    public checkAuthentication = async () => {
        try {
            this.userQueryStatus = QueryStatuses.LOADING;
            const response = await this.client.fetchCurrentUser();
            console.log("Checking login status...");
            this.userQueryStatus = QueryStatuses.SUCCESS;
            this.authError = false;
            this.appError = false;
            var newUser = response.data as User | null;
            const hasNeitherUser = newUser == null && this.user == null;
            const hasBothUsers = newUser != null && this.user != null;
            const hasNewEmail = hasBothUsers && this.user?.email !== newUser?.email;
            const eitherNull = !hasNeitherUser && !hasBothUsers;
            if (eitherNull || hasNewEmail) {
                runInAction(() => {
                    this.user = newUser;
                    this.waitForStatus = false;
                });
            }
        } catch (err) {
            console.error(err);
            this.userQueryStatus = QueryStatuses.ERROR;
            if ((err as any).response?.status === 403) {
                this.authError = true;
            } else {
                this.appError = true;
            }
            runInAction(() => {
                this.waitForStatus = false;
            });
        }
    };

    @action
    public async logOut() {
        console.log("logout");
        (window as any).keycloak?.logout();
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    @action
    public setAuthError = (value: boolean) => {
        this.authError = value;
    };

    @action
    public setAppError = (value: boolean) => {
        this.appError = value;
    };
}
