import React from "react";
import { inject, observer } from "mobx-react";
import { ProvidedAppStore } from "../../store/AppStore";
import {
    AnnotationCategories,
    AnnotationTypes,
    DEFAULT_VIDEO_FPS,
    KeypointAnnotationTypes,
    Media,
    mediaIsImage,
    MediaStore,
    ObjectDetectionAnnotationTypes,
} from "../../store/MediaStore";
import {
    Button,
    Container,
    Dimmer,
    DimmerDimmable,
    Divider,
    Grid,
    Header,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
} from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import {
    ObjectDetectionDescription,
    Instance as ObjectDetectionInstance,
} from "../../models/model_types/object_detection";
import {
    getFrameFromImage,
    getFramesFromVideoTrack,
    getVideoTrack,
} from "../helpers/MediaFunctions";
import { Image as ObjectDetectionAnnotationCanvas } from "../annotation/ObjectDetection/Image";
import { Hierarchy } from "./annotation/MediaAnnotationHierarchy";
import { Instance as KeypointInstance } from "../../models/model_types/keypoints";
import { fakeAnnotations } from "./annotation/fakeAnnotations";
import { AnnotationControls } from "./annotation/MediaAnnotationControls";

export enum Mode {
    Overview,
    NewInstance,
}

type Props = ProvidedAppStore & RouteComponentProps;

interface ObjectDetectionAnnotationState {
    objectDetectionInstances: Record<ObjectDetectionAnnotationTypes, ObjectDetectionInstance[]>;
    newObjectDescription: ObjectDetectionDescription;
}

interface MediaAnnotationPageState extends ObjectDetectionAnnotationState {
    media?: Media;
    videoPreviewModalOpen: boolean;
    timestep: number;
    frames: Map<number, ImageBitmap>;
    mode: Mode;

    keypointInstances: Record<KeypointAnnotationTypes, KeypointInstance[]>;
}

@inject("store")
@observer
export class MediaAnnotationPage extends React.Component<Props, MediaAnnotationPageState> {
    store: MediaStore | undefined;
    private previewElement?: HTMLVideoElement;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            media: undefined,
            videoPreviewModalOpen: false,
            timestep: 0,
            frames: new Map(),
            mode: Mode.Overview,
            newObjectDescription: { label: "", shape_type: "" },
            objectDetectionInstances: { Generic: [] },
            keypointInstances: { Humans: [] },
        };
    }

    async componentDidMount() {
        if (!this.store) return;
        this.store.fetchAnnotationTypes();
        const mediaId = this.fetchRouteParameters().id;
        this.loadMedia(mediaId).then(() => {
            this.loadFrames();
            this.setTimestep(this.state.timestep);
        });
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async loadMedia(id: number) {
        if (!this.store || !id) return;

        const media: Media = await this.store.getMedia(id);
        this.setState({
            media: { ...media, annotations: fakeAnnotations },
        });
    }

    async loadFrames() {
        if (!this.store || !this.state.media) return;
        const media = this.state.media;

        let frames: Map<number, ImageBitmap> = new Map();
        const mediaUrl = this.store.getMediaUrl(media.bucketPath);
        if (mediaIsImage(media)) {
            frames.set(0, await getFrameFromImage(mediaUrl));
        } else {
            // note(Alex.Shaw): The frame extraction method we use requires
            // us to play the whole video, so we pass in a preview element
            // so that the user has a chance to watch the video instead of
            // waiting for a loading screen. We skip this for images, as those
            // are near instant to process.
            const track = await getVideoTrack(mediaUrl, this.previewElement!);
            const takeEvery = this.calculateFrameGapBetweenAnnotations();
            const keepFrames: number[] = [];
            frames = await getFramesFromVideoTrack(track, takeEvery, keepFrames);
        }

        this.setState({
            frames: frames,
        });
    }

    calculateFrameGapBetweenAnnotations() {
        const minimumTimeBetweenFramesMs = 300;
        const variationTimeBetweenFramesMs = 100;

        let frameTime = 1000 / (this.state.media!.fps || DEFAULT_VIDEO_FPS);
        let frameDelta = Math.ceil(
            (Math.random() * variationTimeBetweenFramesMs + minimumTimeBetweenFramesMs) / frameTime
        );
        return frameDelta;
    }

    setTimestep(timestep: number) {
        this.setState({
            ...this.state,
            timestep: timestep,
            objectDetectionInstances: {
                Generic: this.getInstancesForAnnotationType(
                    AnnotationCategories.ObjectDetection,
                    ObjectDetectionAnnotationTypes.Generic,
                    timestep
                ),
            },
            keypointInstances: {
                Humans: this.getInstancesForAnnotationType(
                    AnnotationCategories.Keypoints,
                    KeypointAnnotationTypes.Humans,
                    timestep
                ),
            },
        });
    }

    getInstancesForAnnotationType(
        category: AnnotationCategories,
        type: AnnotationTypes,
        timestep?: number
    ) {
        return this.state.media?.annotations
            .filter((ann) => {
                const matches = ann.annotationCategory === category && ann.annotationType === type;
                if (timestep) return ann.timestep === timestep && matches;
                return matches;
            })
            .map((ann) => ann.answerData.instances as any)
            .reduce((accumulator, value) => accumulator.concat(value), []);
    }

    getAdjacentAnnotatableTimesteps(timestep: number, annotatableTimesteps: number[]) {
        const next = Math.min(...annotatableTimesteps.filter((i) => i > timestep));
        const last = Math.max(...annotatableTimesteps.filter((i) => i < timestep));

        return { previous: last, next: next };
    }

    createObjectDetectionIntance(description: ObjectDetectionDescription) {
        this.setState({ ...this.state, mode: Mode.NewInstance, newObjectDescription: description });
    }

    generateMediaControls() {
        const timestep = this.state.timestep;
        const annotatableTimesteps = Array.from(this.state.frames.keys());

        return (
            <Container
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "8px",
                }}
            >
                {this.state.frames.size === 0 ? (
                    <Header as="h3">Extracting frames, please wait...</Header>
                ) : (
                    <>
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Previous Annotatable Frame"
                            disabled={this.state.timestep === annotatableTimesteps[0]}
                            onClick={() =>
                                this.setTimestep(
                                    this.getAdjacentAnnotatableTimesteps(
                                        timestep,
                                        annotatableTimesteps
                                    ).previous
                                )
                            }
                        />
                        <Button
                            labelPosition="right"
                            icon="right chevron"
                            content="Next Annotatable Frame"
                            disabled={
                                this.state.timestep ===
                                annotatableTimesteps[annotatableTimesteps.length - 1]
                            }
                            onClick={() =>
                                this.setTimestep(
                                    this.getAdjacentAnnotatableTimesteps(
                                        timestep,
                                        annotatableTimesteps
                                    ).next
                                )
                            }
                        />
                        <Button
                            labelPosition="left"
                            icon={"play"}
                            content={"Watch Video"}
                            onClick={() => this.setState({ videoPreviewModalOpen: true })}
                        />
                    </>
                )}
            </Container>
        );
    }

    render() {
        if (!this.state.media) return <Loader active />;
        const media = this.state.media;

        return (
            <Container>
                <Header as="h2">Annotating Media {media?.id}</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <DimmerDimmable as={Container} dimmed={this.state.frames.size === 0}>
                                <Dimmer inverted active={this.state.frames.size === 0} />
                                <AnnotationControls
                                    currentTimestep={this.state.timestep}
                                    mode={this.state.mode}
                                    createObjectDetectionInstance={(
                                        desc: ObjectDetectionDescription
                                    ) => this.createObjectDetectionIntance(desc)}
                                    objectDescription={this.state.newObjectDescription}
                                />
                                <Divider />
                                <Header as="h3">Existing Annotations</Header>
                                <Hierarchy
                                    objectDetectionInstances={this.state.objectDetectionInstances}
                                    keypointInstances={this.state.keypointInstances}
                                    mode={this.state.mode}
                                />
                            </DimmerDimmable>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {!mediaIsImage(media) && this.generateMediaControls()}
                            {!mediaIsImage(media) && this.state.frames.size === 0 && (
                                <video
                                    className="media-view"
                                    ref={(child: HTMLVideoElement) => (this.previewElement = child)}
                                />
                            )}
                            {!mediaIsImage(media) && (
                                <Modal
                                    closeIcon
                                    size="large"
                                    open={this.state.videoPreviewModalOpen}
                                    onClose={() => this.setState({ videoPreviewModalOpen: false })}
                                >
                                    <ModalContent>
                                        <video
                                            controls
                                            className="media-view"
                                            src={this.store!.getMediaUrl(
                                                this.state.media.bucketPath
                                            )}
                                        />
                                    </ModalContent>
                                    <ModalActions>
                                        {" "}
                                        <Button
                                            negative
                                            onClick={() =>
                                                this.setState({ videoPreviewModalOpen: false })
                                            }
                                        >
                                            Close
                                        </Button>
                                    </ModalActions>
                                </Modal>
                            )}
                            {this.state.frames.size > 0 && (
                                <Container style={{ position: "relative" }}>
                                    <ObjectDetectionAnnotationCanvas
                                        image={this.state.frames.get(this.state.timestep)}
                                        groups={[]}
                                    />
                                </Container>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
