import React from "react";
import { inject } from "mobx-react";

import { Table, Header, Grid, Accordion, Icon, Button, Segment, Label } from "semantic-ui-react";
import {
    Instance,
    Definition,
    Keypoint,
    KeyPointDescription,
} from "../../../models/model_types/keypoints";
import { Mode } from "./Annotation";
import { Image, InstanceGroup } from "./Image";
import { Image as ImageModel } from "../../../models/Image";
import { ProvidedAppStore } from "../../../store/AppStore";

interface EditInstancesProps {
    updatedInstances: (instances: Instance[], mode: Mode) => void;
    definition: Definition;
    instances: Instance[];
    color: string;
    bucketPath: string;
    image: ImageModel;
}

interface State {
    instances: Instance[];
    accordianIdx: number;

    instanceIdx: number;
    keypointIdx: number;

    activeTags: boolean;
}

function closestInstance(x: number, y: number, instances: Instance[]) {
    let minDistance = Number.MAX_VALUE;
    let closestInstanceIdx = -1;
    let closestKeypointIdx = -1;

    for (let instanceIdx = 0; instanceIdx < instances.length; ++instanceIdx) {
        const { keypoints } = instances[instanceIdx];
        for (let keypointIdx = 0; keypointIdx < keypoints.length; ++keypointIdx) {
            const keypoint = keypoints[keypointIdx];

            if (!keypoint.visible) {
                continue;
            }

            const deltaX = x - keypoint.pixelX;
            const deltaY = y - keypoint.pixelY;
            const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

            if (distance < minDistance) {
                minDistance = distance;
                closestInstanceIdx = instanceIdx;
                closestKeypointIdx = keypointIdx;
            }
        }
    }

    return { minDistance, closestInstanceIdx, closestKeypointIdx };
}
type Props = EditInstancesProps & ProvidedAppStore;
@inject("store")
export class EditInstances extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.addMissingKeyPoints = this.addMissingKeyPoints.bind(this);
        this.handleAccordian = this.handleAccordian.bind(this);
        this.createKeyPoint = this.createKeyPoint.bind(this);
        this.deleteKeyPoint = this.deleteKeyPoint.bind(this);
        this.onClick = this.onClick.bind(this);

        this.addMissingKeyPoints(this.props);

        this.state = {
            instances: this.props.instances,
            accordianIdx: -1,
            instanceIdx: -1,
            keypointIdx: -1,
            activeTags: false,
        };
    }

    addMissingKeyPoints(props: Props) {
        const expected_names = props.definition.keypoints.map(
            (keypoint: KeyPointDescription) => keypoint.name
        );

        let instances = props.instances;
        for (let idx = 0; idx < instances.length; ++idx) {
            let instance = instances[idx];
            const names = instance.keypoints.map((keypoint: Keypoint) => keypoint.string);
            const missing_names = expected_names.filter((name: string) => !names.includes(name));

            missing_names.forEach((name: string) => {
                instance.keypoints.push({
                    string: name,
                    pixelX: 0.0,
                    pixelY: 0.0,
                    visible: false,
                    obscured: false,
                });
            });
        }
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            this.addMissingKeyPoints(this.props);
            this.setState({
                instances: this.props.instances,
                instanceIdx: -1,
                keypointIdx: -1,
            });
        }
    }

    handleAccordian(idx: number) {
        const { accordianIdx } = this.state;
        idx = accordianIdx === idx ? -1 : idx;
        this.setState({ accordianIdx: idx });
    }

    createKeyPoint(x: number, y: number) {
        const { instances, instanceIdx, keypointIdx } = this.state;

        if (instanceIdx < 0 || keypointIdx < 0) {
            const { closestInstanceIdx, closestKeypointIdx } = closestInstance(x, y, instances);
            if (closestInstanceIdx < 0 || closestKeypointIdx < 0) return;
            const keypoint = instances[closestInstanceIdx].keypoints[closestKeypointIdx];
            keypoint.obscured = !keypoint.obscured;
            this.setState({
                instances,
                instanceIdx: -1,
                keypointIdx: -1,
                accordianIdx: closestInstanceIdx,
            });
            this.props.updatedInstances(instances, Mode.Edit);
            return;
        }

        const keypoint = instances[instanceIdx].keypoints[keypointIdx];

        keypoint.visible = true;
        keypoint.pixelX = x;
        keypoint.pixelY = y;

        this.setState({
            instances,
            instanceIdx: -1,
            keypointIdx: -1,
            accordianIdx: instanceIdx,
        });
        this.props.updatedInstances(instances, Mode.Edit);
    }

    deleteKeyPoint(x: number, y: number) {
        // note(will.brennan) - find the closest keypoint within 10px (canvas) and delete it
        // set the current instanceIDX and definitionIdx acoordingly...

        let { instances } = this.state;
        const { minDistance, closestInstanceIdx, closestKeypointIdx } = closestInstance(
            x,
            y,
            instances
        );

        if (minDistance > 10 || closestInstanceIdx < 0 || closestKeypointIdx < 0) {
            return;
        }

        const keypoint = instances[closestInstanceIdx].keypoints[closestKeypointIdx];
        keypoint.visible = false;

        this.props.updatedInstances(instances, Mode.Edit);
        this.setState({
            instances: instances,
            instanceIdx: closestInstanceIdx,
            keypointIdx: closestKeypointIdx,
            accordianIdx: closestInstanceIdx,
        });
    }

    deleteInstance(idx: number) {
        let { instances } = this.state;
        instances.splice(idx, 1);
        this.setState({ instances });
    }

    onClick(button: number, x: number, y: number) {
        if (button === 0) {
            this.createKeyPoint(x, y);
        } else if (button === 2) {
            this.deleteKeyPoint(x, y);
        }
    }

    render() {
        const { instanceIdx, keypointIdx, instances, accordianIdx, activeTags } = this.state;
        const { bucketPath, definition } = this.props;
        const { image } = this.props;

        const instancesJSX = instances.map((instance: Instance, idx_i: number) => {
            const keypointsJSX = instance.keypoints.map((keypoint: Keypoint, idx_k: number) => {
                if (keypoint.visible) {
                    return <Table.Row key={idx_k} />;
                }

                const fnOnClick = () => {
                    this.setState({
                        instanceIdx: idx_i,
                        keypointIdx: idx_k,
                    });
                };

                const isSameInstance = instanceIdx === idx_i;
                const isSameKeyPoint = keypointIdx === idx_k;
                const isActive = isSameInstance && isSameKeyPoint;

                return (
                    <Table.Row key={idx_k} onClick={fnOnClick} active={isActive}>
                        <Table.Cell>{keypoint.string}</Table.Cell>
                    </Table.Row>
                );
            });

            return (
                <div key={idx_i}>
                    <Accordion.Title
                        active={accordianIdx === idx_i}
                        index={idx_i}
                        onClick={() => this.handleAccordian(idx_i)}
                    >
                        <Icon name="dropdown" />
                        {definition.objectNameSingular} {idx_i}
                    </Accordion.Title>
                    <Accordion.Content active={accordianIdx === idx_i}>
                        <Button color="red" onClick={() => this.deleteInstance(idx_i)}>
                            Delete {definition.objectNameSingular} {idx_i}
                        </Button>

                        <Table basic="very" celled={true} padded={true} selectable={true}>
                            <Table.Body>{keypointsJSX}</Table.Body>
                        </Table>
                    </Accordion.Content>
                </div>
            );
        });

        const activeInstances = instances.filter(
            (elem: Instance, index: number) => index === accordianIdx
        );
        const staticInstances = instances.filter(
            (elem: Instance, index: number) => index !== accordianIdx
        );

        const ann = image.annotations[image.annotations.length - 1];
        let annComment = "";
        if (image.annotations.length > 0) {
            annComment = ann.comment;
        }

        let textAreaJSX = <div />;
        // Checking if there is a comment on the annotation
        if (!!annComment) {
            textAreaJSX = (
                <div>
                    <Segment>
                        <b>Reason for Decline:</b>
                        <Segment>{annComment}</Segment>
                    </Segment>
                </div>
            );
        }

        const setActiveTags = (activeTags: boolean) => {
            this.setState({ activeTags });
        };

        const tagItems = image.tags.map((tag) => (
            <Label key={tag.name} style={{ "margin-bottom": "7px" }}>
                {tag.name}
            </Label>
        ));

        let tagSegmentJSX = <div />;
        if (tagItems.length !== 0) {
            tagSegmentJSX = (
                <Accordion styled={true} fluid={true}>
                    <Accordion.Title
                        active={activeTags}
                        index={0}
                        onClick={() => {
                            setActiveTags(!activeTags);
                        }}
                    >
                        <Icon name="dropdown" />
                        Tags
                    </Accordion.Title>
                    <Accordion.Content active={activeTags}>{tagItems}</Accordion.Content>
                </Accordion>
            );
        }

        const instanceGroups: InstanceGroup[] = [
            {
                color: "pink",
                instances: staticInstances,
            },
            {
                color: "yellow",
                instances: activeInstances,
            },
        ];

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <Header
                        as="h3"
                        content="Missing KeyPoints"
                        subheader="Select in dropdown to add, left click to place, right click to delete"
                    />
                    <Accordion>{instancesJSX}</Accordion>
                    <hr />
                    {textAreaJSX}
                    <br />
                    {tagSegmentJSX}
                </Grid.Column>
                <Grid.Column width={12}>
                    <Image
                        url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                        groups={instanceGroups}
                        definition={definition.keypoints}
                        onClick={this.onClick}
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
}
