import { action, makeAutoObservable, observable } from "mobx";
import { HwkFlowApiClient } from "./Client";
import { Image } from "../models/Image";

export interface FilterCrossValidationState {
    imageScanTypes: string[];
    cameraTypes: string[];
    addSports: string[];
    addStadiums: string[];
    applications: string[];
    uploadedAt: string[];
    tags: string[];
    startDate: Date;
    endDate: Date;
}

interface FilterOptions {
    availableSports: string[];
    availableStadiums: string[];
    availableApplications: string[];
    availableTags: string[];
}

export class CrossValidationStore {
    client: HwkFlowApiClient;
    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    @observable
    public filterOpen: boolean = true;

    @observable
    public filterState: FilterCrossValidationState = {
        addSports: [],
        addStadiums: [],
        imageScanTypes: [],
        cameraTypes: [],
        applications: [],
        uploadedAt: [],
        tags: [],
        startDate: new Date(0),
        endDate: new Date(),
    };

    @observable
    public filterOptions: FilterOptions = {
        availableSports: [],
        availableStadiums: [],
        availableApplications: [],
        availableTags: [],
    };

    @observable
    public requestPending: boolean = false;

    @observable
    public filteredCount: number = 0;

    @observable
    public filteredIds: number[] = [];

    @observable
    public currentImage: Image[] = [];

    @observable
    public currentPage: number = 0;

    @observable
    public pageSize: number = 20;

    @observable
    public totalPages: number = 0;

    @observable
    public typeModelName: string = "";

    @action
    public toggleFilterOpen() {
        this.filterOpen = !this.filterOpen;
    }

    @action
    public updateFilterState(newState: FilterCrossValidationState) {
        this.filterState = newState;
    }

    @action
    public async fetchFilterOptions(modelTypeName: string) {
        let filterResponse = await this.client.fetchCrossValidationFilterOptions(modelTypeName);
        console.log("fFF", filterResponse);
        let tagResponse = await this.client.fetchCrossValidationFilterTags(
            modelTypeName,
            this.filterState.addSports
        );

        this.filterOptions = {
            ...filterResponse.data,
            availableTags: tagResponse.data,
        } as FilterOptions;
    }

    @action
    public async fetchFilteredCrossValidationIdsAndCount() {
        this.requestPending = true;
        let response = await this.client.fetchFilteredCrossValidationIdsAndCount(
            this.filterState,
            this.typeModelName
        );
        this.filteredCount = response.data.count;
        this.filteredIds = response.data.crossValIds;

        this.calculateTotalPages();
        this.setCurrentPage(0);
        this.fetchEntitiesForCurrentExplorePage();
        this.requestPending = false;
    }

    @action
    public async fetchEntitiesForCurrentExplorePage() {
        this.requestPending = true;
        let pageStartIndex = this.pageSize * this.currentPage;
        let pageEndIndex = pageStartIndex + this.pageSize;

        let idsToFetch = this.filteredIds.slice(pageStartIndex, pageEndIndex);

        let response = await this.client.fetchCrossValidationByIds(idsToFetch, false);
        this.currentImage = (response.data as Image[]).sort((a: Image, b: Image) => {
            return new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime();
        });
        this.requestPending = false;
    }

    @action
    public setCurrentPage(page: number) {
        this.currentPage = page;
    }

    @action
    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    @action
    public calculateTotalPages() {
        this.totalPages = Math.ceil(this.filteredCount / this.pageSize);
    }

    public getCrossValUrl(bucketPath: string) {
        return this.client.getImageUrl(bucketPath);
    }

    public async getCrossValidationImage(id: number) {
        return this.client.fetchCrossValidationByIds([id], true).then((response) => {
            let images = response.data as Image[];
            return images;
        });
    }
}
