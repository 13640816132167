import React from "react";
import { inject, observer } from "mobx-react";
import { Line } from "react-chartjs-2";
import { Grid, Dropdown } from "semantic-ui-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { StatsKey, StatsData } from "../../store/AdminStore";

import { ChartWithOptionsProps, timeOptions, colours } from "./MetricsHelpers";

interface LineChartProps {
    groupBy: string;
    filterBy: { [keyName: string]: string };
    since: Date;
    to: Date;
    measureBy: string;
    measureText: string;
}

type ChartProps = ProvidedAppStore & LineChartProps;

@inject("store")
@observer
class LineChart extends React.Component<ChartProps> {
    render() {
        const adminStore = this.props.store!.adminStore;
        let data = adminStore.statisticsAnnotations.slice();

        data = data.filter((elem: StatsData) => {
            return Object.entries(this.props.filterBy).every((filterElem) => {
                const key = filterElem[0] as keyof StatsKey;

                const dateOfCreation = new Date(elem.key.dateOfCreation);
                const timeSinceSince = dateOfCreation.getTime() - this.props.since.getTime();
                const goodSince = timeSinceSince >= 0;
                const timeSinceTo = dateOfCreation.getTime() - this.props.to.getTime();
                const goodTo = timeSinceTo < 0;

                const goodFilterBy = filterElem[1] === "All" || elem.key[key] === filterElem[1];
                return goodSince && goodTo && goodFilterBy;
            });
        });

        const groupBy = this.props.groupBy as keyof StatsKey;
        const groups = Array.from(
            new Set(data.map((elem: StatsData) => String(elem.key[groupBy])))
        );

        // create our days... check with daysAsDate

        const msPerDay = 24 * 60 * 60 * 1000;
        const numDays = (this.props.to.getTime() - this.props.since.getTime()) / msPerDay;

        let daysAsDate: Date[] = [];

        for (let idx = 0; idx <= numDays; ++idx) {
            const date = new Date(this.props.since.getTime() + idx * msPerDay);
            daysAsDate.push(date);
        }

        let datasets: Chart.ChartDataSets[] = [];

        groups.forEach((group: string, idx: number) => {
            let values = daysAsDate.map((date) => 0);

            data.forEach((elem: StatsData) => {
                if (String(elem.key[groupBy]) !== group) {
                    return;
                }

                const date = new Date(elem.key.dateOfCreation);
                const idx = daysAsDate.findIndex((day) => {
                    const dayBegin = day.getTime();
                    const dayEnd = dayBegin + msPerDay;
                    const elemDate = date.getTime();

                    return dayBegin <= elemDate && elemDate < dayEnd;
                });
                values[idx] += elem.count;
            });

            if (this.props.measureBy === "cumulative") {
                for (let idx = 1; idx < values.length; ++idx) {
                    values[idx] += values[idx - 1];
                }
            }

            const name = data.find((item) => item.key.username === group)?.key?.name;
            datasets.push({
                label:
                    // if we group by username it is useful to show name too
                    groupBy === ("username" as keyof StatsKey) && name
                        ? `${group} (${name})`
                        : group,
                data: values,
                borderWidth: 1,
                backgroundColor: colours[idx % colours.length],
            });
        });

        const chartData: Chart.ChartData = {
            labels: daysAsDate.map((date) => date.toLocaleDateString()),
            datasets: datasets,
        };

        const chartOptions: Chart.ChartOptions = {
            title: {
                display: true,
                text: `Annotations grouped by ${
                    this.props.groupBy
                } from ${this.props.since.toDateString()} to ${this.props.to.toDateString()}`,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        },
                        stacked: true,
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                        },
                    },
                ],
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                            labelString: this.props.measureText,
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    bottom: 50,
                },
            },
            responsive: true,
        };

        return <Line data={chartData} options={chartOptions} />;
    }
}

interface State {
    since: string;
    barGroupBy: string;
    measureBy: string;
}

type Props = ProvidedAppStore & ChartWithOptionsProps;

@inject("store")
@observer
export class LineChartWithOptions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            since: "Last Week",
            barGroupBy: this.props.groupBys[0],
            measureBy: "cumulative",
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event: any, data: any) {
        type NewState = Pick<State, keyof State>;
        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    render() {
        const groupByDropDown = this.props.groupBys.map((elem) => {
            return {
                key: elem,
                text: elem,
                value: elem,
            };
        });

        const { sinceDropDown, selectedTime } = timeOptions(new Date(), this.state.since);

        const measureByDropDown = [
            {
                key: "perDay",
                text: "Number of Annotations Per Day",
                value: "perDay",
            },
            {
                key: "cumulative",
                text: "Cumulative Annotations",
                value: "cumulative",
            },
        ];

        const measureBy = measureByDropDown.find((elem) => elem.value === this.state.measureBy);

        if (!selectedTime || !measureBy) {
            return <div>error!</div>;
        }

        return (
            <>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={4}>
                        <Dropdown
                            name="since"
                            header="Annotations Since"
                            fluid
                            selection
                            value={this.state.since}
                            options={sinceDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Dropdown
                            name="barGroupBy"
                            header="Group By"
                            fluid
                            selection
                            value={this.state.barGroupBy}
                            options={groupByDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Dropdown
                            name="measureBy"
                            header="Measure By"
                            fluid
                            selection
                            value={this.state.measureBy}
                            options={measureByDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={12}>
                        <LineChart
                            groupBy={this.state.barGroupBy}
                            filterBy={this.props.filterBy}
                            since={selectedTime.since}
                            to={selectedTime.to}
                            measureBy={measureBy.value}
                            measureText={measureBy.text}
                        />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }
}
