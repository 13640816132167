import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Media, MediaStore } from "../../store/MediaStore";
import { Button, Container, Grid, Header } from "semantic-ui-react";
import { ImageState } from "../../models/Image";
import { Link } from "react-router-dom";

interface MediaInfoProps {
    media: Media;
    reloadMedia: Function;
}

type Props = ProvidedAppStore & MediaInfoProps;

interface MediaStateManagementState {
    media: Media;
}

@inject("store")
@observer
export class MediaStateManagement extends React.Component<Props, MediaStateManagementState> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            media: this.props.media,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<MediaStateManagementState>,
        snapshot?: any
    ): void {
        if (
            prevProps.media.id !== this.props.media.id ||
            prevProps.media.state !== this.props.media.state
        ) {
            this.setState({ ...prevState, media: this.props.media });
        }
    }

    setMediaState(state: ImageState) {
        if (!this.store) return;
        this.store
            .updateMedia({ id: this.state.media.id, state: state })
            .then(() => this.props.reloadMedia());
    }

    needsAnnotationButton(positive?: boolean) {
        return (
            <Button
                fluid
                positive={positive}
                negative={!positive}
                onClick={() => this.setMediaState(ImageState.NeedsAnnotation)}
            >
                Needs Annotation
            </Button>
        );
    }

    unusedButton() {
        return (
            <Button negative fluid onClick={() => this.setMediaState(ImageState.Unused)}>
                Mark as "Unused"
            </Button>
        );
    }

    annotateButton() {
        return (
            <Link to={`/media/annotate/${this.state.media.id}`}>
                <Button positive fluid>
                    Annotate Media
                </Button>
            </Link>
        );
    }

    verifyButton() {
        return (
            <Link to={`/media/verify/${this.state.media.id}`}>
                <Button positive fluid>
                    Verify Media
                </Button>
            </Link>
        );
    }

    singleRowGrid(cellOne: JSX.Element, cellTwo: JSX.Element) {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>{cellOne}</Grid.Column>
                    <Grid.Column width={8}>{cellTwo}</Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    render() {
        const mediaState = this.state.media.state;

        return (
            <Container>
                <Header as="h4">State Management</Header>
                {(mediaState === ImageState.Unused || mediaState === ImageState.Verified) &&
                    this.needsAnnotationButton(true)}
                {mediaState === ImageState.Incoming &&
                    this.singleRowGrid(this.unusedButton(), this.needsAnnotationButton(true))}
                {mediaState === ImageState.NeedsAnnotation &&
                    this.singleRowGrid(this.unusedButton(), this.annotateButton())}
                {mediaState === ImageState.NeedsVerification &&
                    this.singleRowGrid(this.needsAnnotationButton(false), this.verifyButton())}
            </Container>
        );
    }
}
