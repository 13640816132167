import React from "react";
import { Grid, Header, Table, Button, Container, Icon, Dropdown, Input } from "semantic-ui-react";
import { ProvidedAppStore } from "../../store/AppStore";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { Training, TrainingState } from "../../models/Training";
import { Project } from "../../models/Task";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";
import { AbortRequests } from "../helpers/AbortRequests";

type Props = RouteComponentProps & ProvidedAppStore;

interface State {
    currentTrainings: Training[];
    projects: Project[];
    projectID: number;
    newClearMLTaskID: string;
    newHwkNumber: number;
}

@inject("store")
@observer
export class Trainings extends React.Component<Props, State> implements AbortRequests {
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    idToProjectMap: Map<string, string> = new Map();

    constructor(props: Props) {
        super(props);

        this.state = {
            currentTrainings: [],
            projects: [],
            projectID: 0,
            newClearMLTaskID: "",
            newHwkNumber: 0,
        };
        this.fetchTrainings = this.fetchTrainings.bind(this);
        this.fetchProjects = this.fetchProjects.bind(this);
        this.handleGeneralChange = this.handleGeneralChange.bind(this);
        this.updateProjectID = this.updateProjectID.bind(this);
        this.updateProjectHwkNumber = this.updateProjectHwkNumber.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
        this.fetchTrainings();
        this.fetchProjects();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    fetchProjects() {
        this.props
            .store!.hwkflowClient.fetchProjects(this.controller.signal)
            .then((response) => {
                this.setState({ projects: response.data as Project[] });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchTrainings() {
        this.props
            .store!.hwkflowClient.fetchTrainings(this.controller.signal)
            .then((response) => {
                this.setState({
                    currentTrainings: response.data as Training[],
                });
                for (let training of response.data as Training[]) {
                    this.idToProjectMap.set(training.clearMLTaskID, training.project.name);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async train() {
        await this.props.store!.hwkflowClient.train([this.state.projectID]);
        this.fetchTrainings();
    }

    async retrain(id: number) {
        await this.props.store!.hwkflowClient.retrain(id);
        this.fetchTrainings();
    }

    async changeState(newState: TrainingState, id: number) {
        await this.props.store!.hwkflowClient.setTrainingState(id, newState);
        this.fetchTrainings();
    }

    async updateProjectID() {
        await this.props.store!.hwkflowClient.setProjectID(
            this.state.projectID,
            this.state.newClearMLTaskID
        );
        await this.fetchProjects();
    }
    async updateProjectHwkNumber() {
        await this.props.store!.hwkflowClient.setProjectHwkNumber(
            this.state.projectID,
            this.state.newHwkNumber
        );
        await this.fetchProjects();
    }

    handleGeneralChange = (event: any, data: any) => {
        const { name, value } = data;
        this.setState({ [name]: value } as Pick<State, keyof State>);
    };

    stateKeys: { [state: string]: any } = {
        queued: { icon: "spinner", colour: "yellow" },
        in_progress: { icon: "hourglass half", colour: "green" },
        completed: { icon: "thumbs up", colour: "green" },
        failed: { icon: "exclamation", colour: "red" },
        stopped: { icon: "exclamation", colour: "red" },
        closed: { icon: "exclamation", colour: "red" },
        "not queued": { icon: "exclamation", colour: "red" },
        published: { icon: "book", colour: "green" },
        publishing: { icon: "book", colour: "green" },
        unknown: { icon: "question", colour: "orange" },
    };

    render() {
        const dropdown = this.state.projects.map((project: Project) => {
            return {
                key: project.name,
                text: project.name,
                value: project.id,
            };
        });

        this.state.currentTrainings.sort((a: any, b: any) =>
            a.id > b.id ? -1 : b.id > a.id ? 1 : 0
        );

        const trainingsList = this.state.currentTrainings.map((training: Training) => {
            const createdAt = new Date(training.triggeredAt);
            let trainingState = String(training.state);

            if (trainingState === TrainingState.Created) {
                trainingState = "not queued"; //"Not queued" is much more relevant than "created" but enum has to match clearML
            } else if (Object.keys(this.stateKeys).indexOf(trainingState) < 0) {
                trainingState = "unknown";
            }

            const icon = this.stateKeys[trainingState]["icon"];
            const colour = this.stateKeys[trainingState]["colour"];

            let runTime = "0";
            if (training.runTime) runTime = (training.runTime / 3600).toPrecision(2);

            let archiveJSX = <div />;
            if (
                !(
                    training.state === TrainingState.InProgress ||
                    training.state === TrainingState.Queued
                )
            ) {
                archiveJSX = (
                    <Button
                        fluid
                        onClick={() => this.changeState(TrainingState.Archived, training.id)}
                        color="red"
                    >
                        Archive
                    </Button>
                );
            }

            let retrainJSX = (
                <Button fluid color="green">
                    Retrain
                </Button>
            );

            let confirmJSX = (
                <ConfirmationPopUp
                    trigger={retrainJSX}
                    description={"This will set off a new training on ClearML"}
                    onYes={() => this.retrain(training.id)}
                    onNo={() => {}}
                />
            );

            const timeOptions = {
                timeZoneName: "short",
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            };

            return (
                <Table.Row key={training.id}>
                    <Table.Cell>{training.project.name}</Table.Cell>
                    <Table.Cell>{training.name}</Table.Cell>
                    <Table.Cell>
                        {`${trainingState}     `}
                        <Icon color={colour} name={icon} />
                    </Table.Cell>
                    <Table.Cell>{createdAt.toLocaleString("en-GB", timeOptions)}</Table.Cell>
                    <Table.Cell>{runTime}</Table.Cell>
                    <Table.Cell>{training.iteration}</Table.Cell>
                    <Table.Cell>{training.workerId}</Table.Cell>
                    <Table.Cell>{training.owner ? training.owner.name : ""}</Table.Cell>
                    <Table.Cell>
                        {archiveJSX}
                        {confirmJSX}
                    </Table.Cell>
                </Table.Row>
            );
        });

        let trainingsDisplayJSX = (
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Project</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>State</Table.HeaderCell>
                        <Table.HeaderCell>Triggered</Table.HeaderCell>
                        <Table.HeaderCell>Run Time (hours)</Table.HeaderCell>
                        <Table.HeaderCell>Iteration</Table.HeaderCell>
                        <Table.HeaderCell>Worker ID</Table.HeaderCell>
                        <Table.HeaderCell>Owner</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{trainingsList}</Table.Body>
            </Table>
        );

        let confirmTrainJSX = (
            <ConfirmationPopUp
                trigger={<Button content="Train" />}
                description={"This will set off a new training on ClearML"}
                onYes={() => this.train()}
                onNo={() => {}}
            />
        );

        return (
            <Container>
                <Header as="h1">Training Page</Header>
                <Grid>
                    <Grid.Row>
                        <Dropdown
                            clearable
                            search
                            selection
                            name="projectID"
                            options={dropdown}
                            placeholder="Projects"
                            onChange={this.handleGeneralChange}
                        />

                        {confirmTrainJSX}
                        <Input
                            name={"newClearMLTaskID"}
                            onChange={this.handleGeneralChange}
                            value={this.state.newClearMLTaskID}
                            placeholder="New Project ID"
                        />
                        <Button content="Update Project ID" onClick={this.updateProjectID} />
                        <Input
                            name={"newHwkNumber"}
                            onChange={this.handleGeneralChange}
                            value={this.state.newHwkNumber}
                            placeholder="New Project Hwk Number"
                        />
                        <Button
                            content="Update Project Hwk Number"
                            onClick={this.updateProjectHwkNumber}
                        />
                    </Grid.Row>
                </Grid>
                <Header as="h2">Trainings</Header>
                {trainingsDisplayJSX}
                <QueueInfo idToProjectMap={this.idToProjectMap} />
            </Container>
        );
    }
}

interface QueueProps {
    idToProjectMap: Map<string, string>;
}

interface QueueState {
    entries: any[];
}

type QProps = QueueProps & ProvidedAppStore;

@inject("store")
export class QueueInfo extends React.Component<QProps, QueueState> {
    controller: AbortController = new AbortController();
    constructor(props: QProps) {
        super(props);
        this.state = {
            entries: [],
        };
    }

    async componentDidMount() {
        await this.fetchQueueInfo();
    }

    async fetchQueueInfo() {
        const response = await this.props.store!.hwkflowClient.fetchQueueInfo(
            this.controller.signal
        );
        if (response.data.length > 0) this.setState({ entries: response.data });
    }

    render() {
        const queue = this.state.entries.map((entry: any) => {
            let added = entry["added"];
            let task = entry["task"];
            let projectName = this.props.idToProjectMap.get(task);

            return (
                <Table.Row key={task}>
                    <Table.Cell>{task}</Table.Cell>
                    <Table.Cell>{projectName}</Table.Cell>
                    <Table.Cell>{added}</Table.Cell>
                </Table.Row>
            );
        });

        let queueDisplayJSX = (
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Task ID</Table.HeaderCell>
                        <Table.HeaderCell>Project</Table.HeaderCell>
                        <Table.HeaderCell>Added</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{queue}</Table.Body>
            </Table>
        );

        return (
            <Container>
                <Header as="h2">Queue</Header>
                {queueDisplayJSX}
            </Container>
        );
    }
}
