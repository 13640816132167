import * as React from "react";
import { Card, Image, Icon, Form, Dropdown, Input, Button, Message } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { User, departments } from "../../models/User";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";

interface State {
    name: string;
    department: string;
}

interface ProfileProps {
    user: User | null;
    onSubmit: (user: User) => void;
    canChangeUserType: boolean;
    withDeactivateUser?: boolean;
}

type Props = ProfileProps & ProvidedAppStore;

@inject("store")
@observer
export class Profile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (props.user) {
            this.state = {
                name: props.user.name,
                department: props.user.department,
            };
        } else {
            this.state = {
                name: "",
                department: "",
            };
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props === prevProps) {
            return;
        }

        if (this.props.user) {
            this.setState({
                name: this.props.user.name,
                department: this.props.user.department,
            });
        } else {
            this.setState({
                name: "",
                department: "",
            });
        }
    }

    onChange(event: any, data: any) {
        type NewState = Pick<State, keyof State>;

        const { name, value } = data;
        const newState = { [name]: value } as NewState;

        this.setState(newState);
    }

    async onSubmit() {
        const { user, onSubmit } = this.props;
        const { name, department } = this.state;

        if (user == null) {
            return;
        }

        user.name = name;
        user.department = department;
        onSubmit(user);
    }

    confirmUserDeactivation = async () => {
        const { deactivateUser } = this.props.store!.adminStore;
        const { user } = this.props;
        if (user?.id) {
            await deactivateUser(user.id);
        }
    };

    render() {
        const { user, withDeactivateUser } = this.props;

        if (user == null) {
            return (
                <Message icon>
                    <Icon name="info" />
                    <Message.Content>
                        <Message.Header>No User Selected</Message.Header>
                        Please select a user.
                    </Message.Content>
                </Message>
            );
        }

        const userJSX = (
            <>
                <div>User roles:</div>
                <ul>
                    {user.roles?.length ? (
                        user.roles.map((item) => {
                            return (
                                <li key={`${user.id}_${item}`}>
                                    {item.charAt(0)?.toUpperCase() + item.substring(1)}
                                </li>
                            );
                        })
                    ) : (
                        <li>Frozen Access</li>
                    )}
                </ul>
            </>
        );

        const deactivateJSX = (
            <Button
                fluid
                color={"red"}
                basic
                disabled={this.props.store?.userAuthStore?.user?.id === user.id}
            >
                Deactivate
            </Button>
        );

        const confirmJSX = (
            <ConfirmationPopUp
                trigger={deactivateJSX}
                description={`This will deactivate user ${this.props.user?.username || ""}`}
                onYes={() => this.confirmUserDeactivation()}
                onNo={() => {}}
            />
        );

        const departmentOptions = departments.map((d) => ({
            text: d,
            value: d,
        }));
        return (
            <Card className="simple-center">
                <Image src={user.profilePicture} wrapped ui={false} />
                <Card.Content>
                    <Card.Header>{user.name}</Card.Header>
                    <Card.Meta>{user.email}</Card.Meta>
                    <Card.Description>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <Input
                                    name="name"
                                    fluid={true}
                                    error={!this.state.name}
                                    placeholder="Enter your name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Department</label>
                                <Dropdown
                                    name="department"
                                    selection={true}
                                    fluid={true}
                                    error={!this.state.department}
                                    placeholder="Select your department"
                                    options={departmentOptions}
                                    value={this.state.department}
                                    onChange={this.onChange}
                                />
                            </Form.Field>
                        </Form>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra={true}>{userJSX}</Card.Content>
                <Card.Content extra={true} className="button-row">
                    <Button fluid color={"green"} basic onClick={() => this.onSubmit()}>
                        Update
                    </Button>
                    {withDeactivateUser && confirmJSX}
                </Card.Content>
            </Card>
        );
    }
}
