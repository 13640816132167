import React from "react";
import { Table, Grid, Header } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";

import { ProvidedAppStore } from "../../store/AppStore";
import { ModelType } from "../../models/ModelType";
import { BarChartWithOptions } from "./MetricsBarChart";
import { LineChartWithOptions } from "./MetricsLineChart";
import { OptionsModifier } from "./MetricsHelpers";

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class MetricsModelOverview extends React.Component<Props> {
    render() {
        const modelTypesStore = this.props.store?.modelTypesStore!;

        const tableRows = modelTypesStore.modelTypes.map((modelType: ModelType, idx: number) => {
            const url = `/admin/metrics/by-model/${modelType.name}`;
            return (
                <Table.Row
                    key={idx}
                    onClick={() => {
                        this.props.history.push(url);
                    }}
                >
                    <Table.Cell>{modelType.name}</Table.Cell>
                    <Table.Cell>{modelType.type}</Table.Cell>
                    <Table.Cell>{modelType.expectedTime} seconds</Table.Cell>
                    <Table.Cell>{modelType.description}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Table selectable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Model Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Expected Time</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{tableRows}</Table.Body>
            </Table>
        );
    }
}

@inject("store")
@observer
export class MetricsModel extends React.Component<Props> {
    render() {
        const { params } = this.props.match;
        const { modelName } = params as any;

        return (
            <>
                <Header as="h2">
                    {modelName}
                    <Header.Subheader>Machine Learning Model</Header.Subheader>
                </Header>
                <Grid>
                    <LineChartWithOptions
                        groupBys={["username", "department", "verified"]}
                        filterBy={{ model: modelName }}
                    />
                    <BarChartWithOptions
                        groupBys={["username", "department"]}
                        filterBy={{ model: modelName }}
                        modifier={OptionsModifier.ANNOTATIONS}
                    />
                </Grid>
            </>
        );
    }
}
