import { Project } from "./Task";
import { User } from "./User";

export enum TrainingState {
    Queued = "queued",
    Created = "created",
    Complete = "completed",
    InProgress = "in_progress",
    Failed = "failed",
    Stopped = "stopped",
    Archived = "Archived", //Special state for hwkflow use, not in Clear ML API
    Closed = "closed",
    Published = "published",
    Publishing = "publishing",
    Unknown = "unknown",
}

export interface Training {
    id: number;
    project: Project;
    clearMLTaskID: string;
    name: string;
    owner: User;
    state: TrainingState;
    triggeredAt: Date;
    runTime: number;
    workerId: string;
    iteration: number;
}
