import React from "react";
import { Dropdown, Form, Label } from "semantic-ui-react";

export const dropdownInputSelection = (
    name: string,
    values: string[],
    choices: string[],
    changeHandler: (data: any, event: any) => Promise<void>
) => {
    const options = choices.map((choice: string) => {
        return { text: choice, value: choice };
    });

    return (
        <Form.Field>
            <label>{name}</label>
            <Dropdown
                clearable
                fluid
                multiple
                search
                selection
                name={name}
                options={options}
                placeholder={name}
                onChange={changeHandler}
                defaultValue={values}
            />
        </Form.Field>
    );
};

export const tagCheckboxSelection = (
    choices: string[],
    isChecked: (tag: string) => boolean,
    handleChange: (e: any) => Promise<void>
) => {
    if (choices.length === 0) {
        return <Label size="large">No tags for given model and/or sport combination.</Label>;
    }

    const tagBoxes = choices
        .slice()
        .sort((a, b) => a.localeCompare(b))
        .map((tag: string) => {
            return (
                <Form.Field
                    key={tag}
                    width={10}
                    label={tag}
                    control="input"
                    type="checkbox"
                    value={tag}
                    checked={isChecked(tag)}
                    onChange={handleChange}
                />
            );
        });
    let formattedTagBoxes = [];
    let i = 0;
    while (i < tagBoxes.length) {
        let end = Math.min(tagBoxes.length, i + 6);
        formattedTagBoxes.push(<Form.Group key={String(i)}>{tagBoxes.slice(i, end)}</Form.Group>);
        i += 6;
    }
    return formattedTagBoxes;
};
