import React from "react";
import { Button, Container, Menu } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { AdminRoute } from "../auth/AuthRoutes";
import { NavLink, Switch, Redirect, RouteComponentProps } from "react-router-dom";

import { ProvidedAppStore } from "../../store/AppStore";

import { MetricsModelOverview, MetricsModel } from "./MetricsModel";
import { MetricsUserOverview, MetricsUser } from "./MetricsUser";
import { MetricsDepartmentOverview, MetricsDepartment } from "./MetricsDepartments";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";

type Props = ProvidedAppStore & RouteComponentProps;

interface State {
    leaderboardStart: Date;
    leaderboardEnd: Date;
}

@inject("store")
@observer
export class Metrics extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            leaderboardEnd: new Date(),
            leaderboardStart: new Date(),
        };

        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
    }

    async componentDidMount() {
        await this.props.store!.adminStore.getActiveUsers();
        await this.props.store!.adminStore.fetchStatistics();
    }

    handleStartChange = (value: any) => {
        this.setState({ leaderboardStart: value });
    };
    handleEndChange = (value: any) => {
        this.setState({ leaderboardEnd: value });
    };

    async newLeaderboard() {
        await this.props.store!.hwkflowClient.newLeaderboard(
            this.state.leaderboardStart,
            this.state.leaderboardEnd,
        );
    }

    render() {
        return (
            <Container>
                <ConfirmationPopUp
                    trigger={<Button positive>Create New Leaderboard</Button>}
                    description={
                        "This will create a new leaderboard starting and ending on the dates specified. It will end the current leaderboard"
                    }
                    onYes={() => {
                        this.newLeaderboard();
                    }}
                    onNo={() => {}}
                />

                <DayPickerInput
                    placeholder={"Start Date"}
                    onDayChange={this.handleStartChange}
                    value={this.state.leaderboardStart}
                />
                <DayPickerInput
                    placeholder={"End Date"}
                    onDayChange={this.handleEndChange}
                    value={this.state.leaderboardEnd}
                />

                <Menu secondary={true} pointing={true}>
                    <Menu.Item as={NavLink} to={"/admin/metrics/by-model"}>
                        By Model
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={"/admin/metrics/by-user"}>
                        By User
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={"/admin/metrics/by-department"}>
                        By Department
                    </Menu.Item>
                </Menu>
                <Button
                    labelPosition="left"
                    icon="left chevron"
                    content="Back"
                    onClick={this.props.history.goBack}
                />
                <Switch>
                    <Redirect from="/admin/metrics" to={"/admin/metrics/by-model"} exact={true} />
                    <AdminRoute
                        path="/admin/metrics/by-model/:modelName"
                        component={MetricsModel}
                    />
                    <AdminRoute path="/admin/metrics/by-user/:user" component={MetricsUser} />
                    <AdminRoute
                        path="/admin/metrics/by-department/:department"
                        component={MetricsDepartment}
                    />
                    <AdminRoute path="/admin/metrics/by-model" component={MetricsModelOverview} />
                    <AdminRoute path="/admin/metrics/by-user" component={MetricsUserOverview} />
                    <AdminRoute
                        path="/admin/metrics/by-department"
                        component={MetricsDepartmentOverview}
                    />
                </Switch>
            </Container>
        );
    }
}
