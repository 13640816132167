import React from "react";
import {
    Header,
    Card,
    Grid,
    Dropdown,
    Loader,
    Dimmer,
    Popup,
    Segment,
    Accordion,
    Icon,
    Button,
    Input,
    Form,
} from "semantic-ui-react";
import { Link, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";

import {
    Image,
    ImageScanType,
    CameraType,
    getImageStates,
    ImageStateUse,
    ImageState,
} from "../../models/Image";
import { ProvidedAppStore } from "../../store/AppStore";

import { S3Image } from "../helpers/S3Image";
import { Pagination } from "../helpers/Pagination";
import { FilterState, cloneFilterState } from "../../store/ImageStore";
import { dropdownInputSelection, tagCheckboxSelection } from "../helpers/FilterInputComponents";

interface SelectionProps {
    onSubmit: (image: Image, imageState: ImageState) => void;
}
type ExplorerProps = RouteComponentProps & ProvidedAppStore & SelectionProps;

const imageState = [
    { key: 0, text: "Archived", value: ImageState.Archived },
    { key: 1, text: "Unused", value: ImageState.Unused },
    { key: 2, text: "Incoming", value: ImageState.Incoming },
    { key: 3, text: "NeedsAnnotation", value: ImageState.NeedsAnnotation },
    { key: 4, text: "NeedsVerification", value: ImageState.NeedsVerification },
    { key: 5, text: "Verified", value: ImageState.Verified },
];

@inject("store")
@observer
export class FilterForm extends React.Component<ProvidedAppStore, FilterState> {
    constructor(props: ProvidedAppStore) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.state = this.props.store!.imageStore.filterState;
    }

    async componentDidMount() {
        if (!this.props.store!.modelStore.generated) {
            this.props.store!.modelStore.fetchStatistics(ImageStateUse.Regular);
        }
    }

    async handleChange(event: any, data: any) {
        const { name, value } = data;
        const filterState = { ...this.state, [name]: value };
        this.setState(filterState);

        if (name === "add_sports") {
            this.props.store!.modelStore.fetchTags(value);
        }
    }

    async handleCheckboxChange(e: any) {
        const filterState = cloneFilterState(this.state);
        if (e.target.checked) {
            filterState.tags.push(e.target.value);
        } else {
            filterState.tags = filterState.tags.filter((tag) => tag !== e.target.value);
        }
        this.setState(filterState);
    }

    async handleToggleChange(event: any) {
        const filterState = cloneFilterState(this.state);
        filterState.intersectTags = !filterState.intersectTags;
        this.setState(filterState);
    }

    async updateStore() {
        const store = this.props.store!.imageStore;
        store.setState(store.taskName, this.state, store.page);
    }

    alreadySelected = (tag: string) => {
        return this.state.tags.includes(tag);
    };

    render() {
        const store = this.props.store!.imageStore;
        const { active, filterState } = store;
        const pending = store.isPending();

        const modelStore = this.props.store!.modelStore;

        return (
            <Accordion styled={true} fluid={true}>
                <Accordion.Title
                    active={active}
                    index={0}
                    onClick={() => {
                        store.setActive(!active);
                    }}
                >
                    <Icon name="dropdown" />
                    Filter Images
                </Accordion.Title>
                <Accordion.Content active={active}>
                    <Form>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "imageStates",
                                filterState.imageStates,
                                getImageStates(ImageStateUse.Explorer),
                                this.handleChange,
                            )}
                            {dropdownInputSelection(
                                "cameraTypes",
                                filterState.cameraTypes,
                                Object.keys(CameraType),
                                this.handleChange,
                            )}
                            {dropdownInputSelection(
                                "imageScanTypes",
                                filterState.imageScanTypes,
                                Object.keys(ImageScanType),
                                this.handleChange,
                            )}
                        </Form.Group>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "add_sports",
                                filterState.add_sports,
                                modelStore.sports,
                                this.handleChange,
                            )}
                            {dropdownInputSelection(
                                "add_stadiums",
                                filterState.add_stadiums,
                                modelStore.stadiums,
                                this.handleChange,
                            )}
                            {dropdownInputSelection(
                                "applications",
                                filterState.applications,
                                modelStore.applications,
                                this.handleChange,
                            )}
                        </Form.Group>
                        <Form.Group inline widths="equal">
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="start_date"
                                    name="start_date"
                                    label="Start Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState.start_date}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="end_date"
                                    name="end_date"
                                    label="End Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState}
                                />
                            </Form.Field>
                        </Form.Group>
                        {tagCheckboxSelection(modelStore.tags, this.alreadySelected, (e: any) =>
                            this.handleCheckboxChange(e),
                        )}
                        <Button
                            toggle
                            onClick={(event) => this.handleToggleChange(event)}
                            active={this.state.intersectTags}
                        >
                            {this.state.intersectTags
                                ? "Tag selection: Intersection"
                                : "Tag selection: Union"}
                        </Button>
                    </Form>
                    <Button
                        loading={pending}
                        disabled={pending}
                        onClick={() => this.updateStore()}
                        fluid
                        color={"green"}
                        basic
                    >
                        Submit
                    </Button>
                </Accordion.Content>
            </Accordion>
        );
    }
}

const OpenButton = (imageLink: string) => (
    <Button as={Link} to={imageLink}>
        Open
    </Button>
);

interface ExplorerState {
    images: Image[];
    imageState: ImageState;
}

@inject("store")
@observer
export class DataExplorer extends React.Component<ExplorerProps, ExplorerState> {
    constructor(props: ExplorerProps) {
        super(props);
        this.fetchRouteParams = this.fetchRouteParams.bind(this);
        this.setPage = this.setPage.bind(this);
        this.state = { images: [], imageState: ImageState.NeedsAnnotation };
    }

    fetchRouteParams() {
        const { params } = this.props.match;
        const { page, task } = params as any;
        return { page: parseInt(page), task };
    }

    async componentDidMount() {
        const { task, page } = this.fetchRouteParams();
        await this.props.store!.imageStore.setTaskAndPage(task, page);
    }

    async componentDidUpdate(prevProps: RouteComponentProps) {
        const changedLocation = this.props.location !== prevProps.location;

        if (changedLocation) {
            const { task, page } = this.fetchRouteParams();
            const store = this.props.store!.imageStore;
            await store.setTaskAndPage(task, page);
        }

        if (prevProps !== this.props) {
            let images: Image[] = [];
            let imageState = ImageState.NeedsAnnotation;
            this.setState({ images, imageState });
        }
    }

    selectImage(image: Image) {
        let images: Image[] = [];
        if (this.state.images.includes(image)) {
            images = this.state.images.filter((im: Image) => im.id !== image.id);
            this.setState({ images: images });
        } else {
            images = this.state.images;
            images.push(image);
            this.setState({ images: images });
        }
    }

    selectAllImages() {
        this.props.store?.imageStore.images.forEach((image: Image) => {
            if (!this.state.images.includes(image)) {
                this.selectImage(image);
            }
        });
    }

    deSelectImages() {
        this.setState({ images: [] });
    }

    doUpdate() {
        this.state.images.forEach((image: Image) => {
            image.imageState = this.state.imageState;
            runInAction(() => {
                this.props.onSubmit(image, image.imageState);
            });
        });
        this.setState({ images: [] });
    }

    selectImageState(imageState: ImageState) {
        this.setState({ imageState: imageState });
    }
    async setPage(page: number) {
        const { task } = this.fetchRouteParams();
        const newLocation = `/model/${task}/explore/${page}`;
        this.props.history.push(newLocation);
    }

    cardColour(image: Image) {
        if (this.state.images.includes(image)) {
            return "green";
        } else {
            return "red";
        }
    }

    ImageCard = (image: Image, timeFormat: {}) => (
        <Card key={image.id} color={this.cardColour(image)}>
            <S3Image
                url={this.props.store!.hwkflowClient.getImageUrl(image.bucketPath)}
                wrapped={true}
                ui={false}
            />
            <Card.Content>
                <Card.Header>Image {image.id}</Card.Header>
                <Card.Meta>
                    {image.sport} - {image.stadium}
                </Card.Meta>
                <Card.Meta style={{ fontStyle: "italic" }}>
                    {new Date(image.recordedAt).toLocaleDateString("en-US", timeFormat)}
                </Card.Meta>
                <Card.Description>
                    <Header sub>Image State</Header>
                    <span>{image.imageState}</span>
                </Card.Description>
                <Card.Description>
                    <span>{image.datasetType}</span>
                </Card.Description>
            </Card.Content>
        </Card>
    );

    multiSelectTool = () => (
        <Segment.Group compact>
            <Segment>
                <Grid centered={true}>
                    <Grid.Row>Selected Images: {this.state.images.length}</Grid.Row>
                    <Button onClick={() => this.selectAllImages()} color="teal">
                        Select All
                    </Button>
                    <Grid.Row>
                        <Dropdown
                            floating
                            onChange={(e: any, value: any) => {
                                this.selectImageState(value.value);
                            }}
                            options={imageState}
                            placeholder="Choose an option"
                            selection
                            value={this.state.imageState}
                        />
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment>
                <Button onClick={() => this.doUpdate()} color="green">
                    Move
                </Button>
                <Button onClick={() => this.deSelectImages()} color="red">
                    Reset
                </Button>
            </Segment>
        </Segment.Group>
    );

    render() {
        const store = this.props.store!.imageStore;
        const timeFormat = { day: "numeric", month: "long", year: "numeric" };
        const pending = store.isPending();

        // Display the loading symbol if requests are ongoing, otherwise show images
        const loadingJSX = (
            <Dimmer active={pending} inverted>
                <Loader />
            </Dimmer>
        );
        const imagesJSX =
            !pending &&
            store.images.map((image: Image) => {
                return (
                    <Popup
                        key={image.id}
                        trigger={this.ImageCard(image, timeFormat)}
                        on="click"
                        position="bottom center"
                    >
                        {OpenButton(`/model/${store.taskName}/image/${image.id}`)}
                        <Button
                            toggle
                            active={this.state.images.includes(image)}
                            onClick={() => this.selectImage(image)}
                        >
                            Select
                        </Button>
                    </Popup>
                );
            });

        const numImages = store.imageIds.length;
        const numPages = Math.ceil(numImages / store.numPerPage);

        return (
            <Grid>
                <Grid.Row>
                    <FilterForm />
                </Grid.Row>
                <Grid.Row>
                    <div>
                        <Header sub>Number of Images:</Header>
                        <span>{numImages}</span>
                    </div>
                </Grid.Row>
                <Grid.Row>
                    <Pagination page={store.page} maxPage={numPages} updatePage={this.setPage} />
                </Grid.Row>
                <Grid.Row>{loadingJSX}</Grid.Row>
                <Grid.Row>
                    <Card.Group itemsPerRow={store.numPerRow}>{imagesJSX}</Card.Group>
                </Grid.Row>
                <Grid.Row>
                    <Pagination page={store.page} maxPage={numPages} updatePage={this.setPage} />
                </Grid.Row>
                <Grid.Row centered={true}>{this.multiSelectTool()}</Grid.Row>
            </Grid>
        );
    }
}
