import * as React from "react";
import { Container, Card, Header, Table, Segment, Grid, Popup } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../store/AppStore";
import { AbortRequests } from "./helpers/AbortRequests";
import { LeaderboardStats } from "../store/AdminStore";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

interface StatsboardState {
    daysLeft: number | undefined;
    userData: LeaderboardStats | null;
}

@inject("store")
@observer
export class Statsboard
    extends React.Component<ProvidedAppStore, StatsboardState>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.state = { daysLeft: 0, userData: null };

        this.newLeaderboard = this.newLeaderboard.bind(this);
        this.setUserData = this.setUserData.bind(this);
    }

    setUserData() {
        let leaderboardData = this.props.store!.leaderboardStore.rawStatistics;
        let user = this.props.store?.userAuthStore.user!;

        for (let stats of leaderboardData) {
            if (stats.name === user.name) {
                this.setState({ userData: stats as LeaderboardStats });
                break;
            }
        }
    }

    async componentDidMount() {
        await this.props.store?.leaderboardStore.fetchLeaderboardData();
        this.setUserData();
        let store = this.props.store!.leaderboardStore;
        let daysLeft = undefined;
        let today = new Date();
        if (store.current) {
            let endDate = new Date(this.props.store!.leaderboardStore.current!.finishDate);

            daysLeft = (endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);
            if (daysLeft < 0) {
                let startOfCurrentMonth = new Date(today.valueOf());
                startOfCurrentMonth.setDate(1);
                startOfCurrentMonth.setHours(0, 0, 0, 0);

                await this.newLeaderboard(startOfCurrentMonth);
            }
        } else {
            await this.newLeaderboard(today);
        }

        this.setState({ daysLeft: daysLeft });
    }

    async newLeaderboard(newStartDate: Date) {
        let newEndDate = new Date(newStartDate.valueOf());
        newEndDate.setMonth(newStartDate.getMonth() + 1);
        await this.props.store!.hwkflowClient.newLeaderboard(newStartDate, newEndDate);
        await this.props.store?.leaderboardStore.fetchLeaderboardData();
    }

    render() {
        let store = this.props.store!.leaderboardStore;
        let approvalLeaderboardData = store.approvalStatistics;
        let annotationsLeaderboardData = store.annotationStatistics;
        let userData = this.state.userData;
        let topApprovalData = store.topApproval;
        let topAnnotationData = store.topAnnotations;
        let minimumApprovalScore = 40;
        let minimumAnnotations = 100;

        let totalOneDayAnno = store.todayAnnotations;
        let approvPercentToday = store.todayApprovalScore;

        const approvalLeaderboard = approvalLeaderboardData.map(
            (stats: LeaderboardStats, index) => {
                return (
                    <Table.Row key={String(index)}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell> {stats.name} </Table.Cell>
                        <Table.Cell> {Math.round(stats.approvalScore * 10) / 10} </Table.Cell>
                    </Table.Row>
                );
            }
        );

        const annotationsLeaderboard = annotationsLeaderboardData.map(
            (stats: LeaderboardStats, index) => {
                return (
                    <Table.Row key={String(index)}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell> {stats.name} </Table.Cell>
                        <Table.Cell> {stats.totalAnnotations} </Table.Cell>
                    </Table.Row>
                );
            }
        );

        let content = "";
        let description = "No current competition";
        if (this.state.daysLeft) {
            content = Math.round(this.state.daysLeft) as any;
            description = "Days left until winner is crowned";
            if (this.state.daysLeft < 0) {
                content = "This round of competition has ended, refresh to find out who won!";
                description = "";
            }
        }
        let daysLeftJSX = (
            <Card>
                <Card.Content>
                    <Card.Header>{content}</Card.Header>
                    <Card.Description>{description}</Card.Description>
                </Card.Content>
            </Card>
        );

        let cardsJSX = (
            <Card.Group>
                <Card>
                    <Card.Content>
                        <Card.Description>
                            Unfortunately, you are not eligible for these leaderboards. This may be
                            because you haven't completed any annotations yet this round!
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>
        );

        if (!userData && this.props.store?.userAuthStore.user?.department === "Annotation") {
            let user = this.props.store?.userAuthStore.user!;
            userData = {
                name: user.name,
                totalAnnotations: 0,
                approvalScore: 0,
                verified: 0,
                declined: 0,
                totalTime: 0,
                totalAnnotationsToday: 0,
                approvalScoreToday: 0,
                verifiedToday: 0,
                declinedToday: 0,
                startOfStreak: new Date(),
                streak: 0,
            };
        }

        if (userData) {
            let verificationsNeeded = 0;
            if (topApprovalData) {
                if (topApprovalData.approvalScore === 100) verificationsNeeded = 0;
                else
                    verificationsNeeded =
                        Math.round(
                            (topApprovalData.approvalScore * userData.declined) /
                                (100 - topApprovalData.approvalScore)
                        ) - userData.verified;
            }
            let annotationsNeeded = 0 as any;
            if (topAnnotationData)
                annotationsNeeded = topAnnotationData.totalAnnotations - userData.totalAnnotations;

            let verifiedNeededContent = verificationsNeeded;
            let verifiedNeededDescription =
                "Verified annotations needed to reach the top of the approval leaderboard";
            if (userData.totalAnnotations < minimumAnnotations) {
                verifiedNeededContent = minimumAnnotations - userData.totalAnnotations;
                verifiedNeededDescription =
                    "Annotations needed to qualify for approval score leaderboard";
            }

            let annotationsNeededContent = annotationsNeeded;
            let annotationsNeededDescription =
                "Annotations needed to reach the top of the annotations leaderboard";
            if (userData.approvalScore < minimumApprovalScore) {
                annotationsNeededContent =
                    Math.round(
                        (minimumApprovalScore * userData.declined) / (100 - minimumApprovalScore)
                    ) - userData.verified;
                annotationsNeededDescription =
                    "Verified annotations needed to qualify for total annotations leaderboard";
                if (userData.declined === 0 && userData.verified === 0) {
                    annotationsNeededContent = ">0";
                }
            }

            cardsJSX = (
                <Card.Group>
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {Math.round(userData?.approvalScore * 10) / 10}
                            </Card.Header>
                            <Card.Description>Approval Score</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>
                                {Math.round(userData.totalTime / (1000 * 60))}
                            </Card.Header>
                            <Card.Description>Total minutes spent on annotations</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>{userData.totalAnnotations}</Card.Header>
                            <Card.Description>Total Annotations</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>{verifiedNeededContent}</Card.Header>
                            <Card.Description>{verifiedNeededDescription}</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>{annotationsNeededContent}</Card.Header>
                            <Card.Description>{annotationsNeededDescription}</Card.Description>
                        </Card.Content>
                    </Card>
                    {daysLeftJSX}
                    <Card>
                        <Card.Content>
                            <Card.Header>{userData.streak}</Card.Header>
                            <Card.Description>Days annotating in a row</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>{Math.round(userData.approvalScoreToday)}</Card.Header>
                            <Card.Description>Approval score in the last 24 hours</Card.Description>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>{Math.round(userData.totalAnnotationsToday)}</Card.Header>
                            <Card.Description>Annotations in the last 24 hours</Card.Description>
                        </Card.Content>
                    </Card>
                </Card.Group>
            );
        }

        let overviewStats = (
            <Card.Group>
                <Card>
                    <Card.Content>
                        <Card.Header>{Math.round(totalOneDayAnno)}</Card.Header>
                        <Card.Description>Annotations</Card.Description>
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>
                        <Card.Header>{Math.round(approvPercentToday * 10) / 10}</Card.Header>
                        <Card.Description>Approval score</Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>
        );

        let lastWinner = "No previous winner";
        if (store.previous) {
            if (store.previous.approvalWinner) {
                lastWinner = `Last round won by ${
                    this.props.store!.leaderboardStore.previous!.approvalWinner
                }`;
            }
        }
        let approvalScoreTrigger = (
            <Header size="small">
                Approval Score
                <HeaderSubHeader>{lastWinner}</HeaderSubHeader>
            </Header>
        );

        let approvalScorePopUp = (
            <Segment>
                Approval score is the percentage of your annotations that get accepted instead of
                declined. This does not include annotations that have not yet been reviewed. To
                ensure consistency, at least {minimumAnnotations} total annotations are required to
                qualify for the approval score leaderboard
            </Segment>
        );

        lastWinner = "No previous winner";
        if (store.previous) {
            if (store.previous.annotationsWinner) {
                lastWinner = `Last round won by ${
                    this.props.store!.leaderboardStore.previous!.annotationsWinner
                }`;
            }
        }
        let totalAnnotationTrigger = (
            <Header size="small">
                Total Annotations
                <HeaderSubHeader>{lastWinner}</HeaderSubHeader>
            </Header>
        );
        let totalAnnotationPopUp = (
            <Segment>
                Total annotations is the complete sum of all annotations completed, declined,
                approved or yet to be reviewed. To ensure good accuracy, an approval score of at
                least {minimumApprovalScore} is needed to qualify for the total annotations
                leaderboard
            </Segment>
        );

        return (
            <Container>
                <Header>Your Stats for This Leaderboard</Header>
                {cardsJSX}
                <Header>Overall Hwkflow Stats in the Last 24 Hours</Header>
                {overviewStats}
                <Header>
                    Leaderboards
                    <HeaderSubHeader>
                        The winner of each leaderboard will win a £25 Amazon voucher! A new round
                        starts every month!
                    </HeaderSubHeader>
                </Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Popup trigger={approvalScoreTrigger} content={approvalScorePopUp} />

                            <Table celled padded>
                                <Table.Body>{approvalLeaderboard}</Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Popup
                                trigger={totalAnnotationTrigger}
                                content={totalAnnotationPopUp}
                            />
                            <Table celled padded>
                                <Table.Body>{annotationsLeaderboard}</Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
