import React from "react";

import { Dropdown } from "semantic-ui-react";
import { Image } from "../../models/Image";

interface Props {
    image: Image;
    onSelect: Function;
}

// Annoyingly the dropdown doesn't control its own state properly, so we need to keep track of
// it here so that the dropdown remains correct when we move on to a different sample
interface State {
    bucketPath: string;
}

export class FrameSelect extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { bucketPath: this.props.image.bucketPath };

        this.onSelectFrame = this.onSelectFrame.bind(this);
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps.image.bucketPath !== this.props.image.bucketPath) {
            this.setState({ bucketPath: this.props.image.bucketPath });
        }
    }

    onSelectFrame(event: any, data: any) {
        const { value } = data;
        this.props.onSelect(value);
        this.setState({ bucketPath: value });
    }

    render() {
        const { image } = this.props;
        const { bucketPath } = this.state;

        const { otherFrameBucketPaths } = image;

        if (otherFrameBucketPaths.length === 0) {
            return <></>;
        }

        const allBucketPaths = otherFrameBucketPaths.concat([image.bucketPath]).reverse();
        const fnIdxToText = (idx: number): string => {
            if (idx === 0) {
                return "Latest Frame";
            } else if (idx === 1) {
                return "1 Frame Ago";
            }

            return idx + " Frames Ago";
        };

        const dropdownOptions = allBucketPaths.map((path: string, idx: number) => {
            return { key: path, text: fnIdxToText(idx), value: path };
        });

        return (
            <Dropdown
                selection
                options={dropdownOptions}
                onChange={this.onSelectFrame}
                value={bucketPath}
            />
        );
    }
}
