export enum KeycloakRole {
    ANNOTATOR = "annotator",
    VERIFIER = "verifier",
    ADMIN = "admin",
}

export const departments = [
    "Tennis",
    "SMART Sports",
    "Cricket",
    "Football",
    "AMEA",
    "USA",
    "Australia",
    "Development",
    "Product",
    "Annotation",
    "Other",
];

export enum UserStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface User {
    id: number;
    email: string;
    name: string;
    profilePicture: string;
    department: string;
    username: string;
    roles: KeycloakRole[];
    status: UserStatus;
}

export interface Leaderboard {
    id: number;
    startDate: Date;
    finishDate: Date;
    isLatest: boolean;
    isPrevious: boolean;
    annotationsWinner: string;
    approvalWinner: string;
}

export interface ActiveSession {
    id: number;
    user: User;
    uuid: string;
    timeStamp: Date;
    accessedTimeStamp: Date;
}
