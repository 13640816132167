import React from "react";
import { inject, observer } from "mobx-react";
import { Container, Table } from "semantic-ui-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { Model } from "../../models/Model";
import { Loading } from "../helpers/Loading";

@inject("store")
@observer
export class History extends React.Component<ProvidedAppStore, {}> {
    render() {
        const { modelStore } = this.props.store!;

        if (!modelStore.modelType) {
            return <Loading />;
        }

        const { models } = modelStore.modelType!;

        const tableRows = models.map((model: Model, idx: number) => {
            const date = model.createdAt;
            const hours = `0${date.getHours()}`.slice(-2);
            const minutes = `0${date.getMinutes()}`.slice(-2);
            const dateString = `${date.getDate()}-${
                date.getMonth() + 1
            }-${date.getFullYear()} ${hours}:${minutes}`;

            return (
                <Table.Row key={idx}>
                    <Table.Cell>{model.id}</Table.Cell>
                    <Table.Cell>{model.name}</Table.Cell>
                    <Table.Cell>{dateString}</Table.Cell>
                    <Table.Cell>{model.description}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Container>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>id</Table.HeaderCell>
                            <Table.HeaderCell>name</Table.HeaderCell>
                            <Table.HeaderCell>created</Table.HeaderCell>
                            <Table.HeaderCell>description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{tableRows}</Table.Body>
                </Table>
            </Container>
        );
    }
}
