import * as React from "react";
import { inject, observer } from "mobx-react";
import { Grid } from "semantic-ui-react";
import { Message, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import "./AuthComponents.css";
import { ProvidedAppStore } from "../../store/AppStore";
import { QueryStatuses } from "../../store/UserAuth";

interface OwnProps {
    errorText: string;
    errorTitle: string;
}

type Props = OwnProps & ProvidedAppStore;

export const FailedAuth = inject("store")(
    observer(({ errorText, errorTitle, store }: Props) => {
        const history = useHistory();
        const { userAuthStore } = store!;
        const { userQueryStatus } = userAuthStore;
        React.useEffect(() => {
            // if success status of user query and it wasn't error of access blocked redirect to taskboard page
            // as role can be changed only via keycloak and after re-login it will not be changed to success
            if (userQueryStatus === QueryStatuses.SUCCESS && history.location?.pathname === "/failed") {
                history.push("/taskboard");
            }
        }, [userQueryStatus, history.location?.pathname]);

        return (
            <div className="auth">
                <Grid textAlign="center" verticalAlign="middle">
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Message icon>
                            <Icon name="ban" />
                            <Message.Content>
                                <Message.Header>{errorTitle}</Message.Header>
                                {errorText}
                            </Message.Content>
                        </Message>
                    </Grid.Column>
                </Grid>
            </div>
        );
    })
);
