import * as React from "react";
import { Container, Card, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../store/AppStore";
import { Task, TaskState } from "../models/Task";
import { AbortRequests } from "./helpers/AbortRequests";
import { pdfButton } from "./helpers/AnnotationInfo";
import HwkflowGuide from "../guides/Hwkflow - Navigation Guide.pdf";

interface TaskboardState {
    currentTasks: Task[];
}

@inject("store")
@observer
export class Taskboard
    extends React.Component<ProvidedAppStore, TaskboardState>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.state = { currentTasks: [] };
        this.fetchTasks = this.fetchTasks.bind(this);
    }

    fetchTasks() {
        this.props
            .store!.hwkflowClient.fetchTasks(false, this.controller.signal)
            .then((response) => this.setState({ currentTasks: response.data as Task[] }))
            .catch(function (error) {
                console.log(error);
            });
    }

    async componentDidMount() {
        this.mounted = true;
        await this.fetchTasks();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    render() {
        let keyCount = 0; //Each div needs a unique key, console can show warnings if not
        const annotationCardsJSX = this.state.currentTasks.map((task: Task) => {
            keyCount += 1;
            if (task.state !== TaskState.InProgress) {
                return <div key={"no annotations tasks" + String(keyCount)} />;
            }

            return (
                <Card
                    key={task.model.name + String(keyCount)}
                    as={Link}
                    to={`/model/${task.model.name}/annotation/task/${task.id}`}
                >
                    <Card.Content>
                        <Card.Header>{task.model.name}</Card.Header>
                        <Card.Description>{task.description}</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid color={"green"} basic>
                            Annotate
                        </Button>
                    </Card.Content>
                </Card>
            );
        });

        const verificationCardsJSX = this.state.currentTasks.map((task: Task) => {
            keyCount += 1;
            if (
                task.state !== TaskState.InProgress &&
                task.state !== TaskState.AllAnnotated &&
                task.state !== TaskState.NoData
            ) {
                return <div key={"no verification tasks" + String(keyCount)} />;
            }

            return (
                <Card
                    key={task.model.name + String(keyCount)}
                    as={Link}
                    to={`/model/${task.model.name}/verification/task/${task.id}`}
                >
                    <Card.Content>
                        <Card.Header>{task.model.name}</Card.Header>
                        <Card.Description>{task.description}</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Button fluid color={"green"} basic>
                            Verify
                        </Button>
                    </Card.Content>
                </Card>
            );
        });

        let hwkflowGuideJSX = pdfButton(HwkflowGuide, "View Hwkflow Guide");
        return (
            <Container>
                {hwkflowGuideJSX}
                <Header>Annotation</Header>
                <Card.Group itemsPerRow={4}>{annotationCardsJSX}</Card.Group>
                <Header>Verification</Header>
                <Card.Group itemsPerRow={4}>{verificationCardsJSX}</Card.Group>
            </Container>
        );
    }
}
