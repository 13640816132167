import { observable, action, makeAutoObservable, runInAction } from "mobx";

import { ModelType } from "../models/ModelType";
import { HwkFlowApiClient } from "./Client";

export class ModelTypesStore {
    client: HwkFlowApiClient;
    constructor(client: HwkFlowApiClient) {
        makeAutoObservable(this);
        this.client = client;
    }

    @observable
    public modelTypes: Array<ModelType> = [];

    @action
    public async fetchModelTypes() {
        const response = await this.client.fetchTypes();
        runInAction(() => {
            this.modelTypes = response.data;
        });
    }

    @action
    public async seedTypes() {
        await this.client
            .seedTypes(false)
            .catch((error: any) => {
                console.log(`seeding tasks - ${error.response.status} ${error.response.data}`);
            })
            .then(async () => {
                await this.fetchModelTypes();
            });
    }
}
