import { ModelType } from "./ModelType";

export enum TaskState {
    Complete = "Complete",
    InProgress = "InProgress",
    OnHold = "OnHold",
    AllAnnotated = "AllAnnotated",
    Incoming = "Incoming",
    NoData = "NoData",
    Archived = "Archived",
    Training = "Training",
}

export enum TaskPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

export interface Project {
    id: number;
    name: string;
    clearMLTaskID: string;
}

export interface Task {
    id: number;
    target: number;
    annotated: number;
    verified: number;
    discarded: number;
    description: string;
    instructions: string;
    model: ModelType;
    deadline: Date;
    sports: string[];
    stadiums: string[];
    applications: string[];
    failureTags: string[];
    createdAt: Date;
    state: TaskState;
    priority: TaskPriority;
    needsAnnotation: number;
    needsVerification: number;
    projects: Project[];
    triggerTraining: boolean;
    crossValidationPercentage: number;
    earliestUploadDate?: Date;
    latestUploadDate?: Date;
}
