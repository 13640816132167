const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

export class TrackedCTX {
    private xform: SVGMatrix;
    private savedTransforms: SVGMatrix[] = [];
    private ctx: CanvasRenderingContext2D;
    private canvasScale: number;

    constructor(ctx: CanvasRenderingContext2D) {
        this.ctx = ctx;
        this.xform = svg.createSVGMatrix();
        this.canvasScale = 1.0;
    }
    // scale, translate, save, restore, clear rect

    save() {
        this.savedTransforms.push(this.xform.translate(0, 0));
        this.ctx.save();
    }

    restore() {
        const savedTransform = this.savedTransforms.pop();
        if (savedTransform) {
            this.xform = savedTransform;
        }
        this.ctx.restore();
    }

    getTransform(): SVGMatrix {
        return this.xform;
    }

    getScale(): number {
        return this.canvasScale;
    }

    scale(
        scaleFactor: number,
        lastPos: { x: number; y: number },
        translation: { x: number; y: number }
    ) {
        let newScale = this.canvasScale * scaleFactor;
        newScale = Math.max(1, Math.min(newScale, 50));
        scaleFactor = newScale / this.canvasScale;

        const newTransX = (lastPos.x - translation.x) * scaleFactor;
        const newTransY = (lastPos.y - translation.y) * scaleFactor;

        translation.x += newTransX;
        translation.y += newTransY;

        this.canvasScale *= scaleFactor;
        if (newScale === 1 || translation.x < 0 || translation.y < 0) {
            translation.x = 0;
            translation.y = 0;
            this.canvasScale = 1.0;
            this.xform = svg.createSVGMatrix();

            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            this.xform = this.xform.scale(scaleFactor);
            this.ctx.scale(scaleFactor, scaleFactor);
        } else {
            this.translate(translation.x, translation.y);
            this.xform = this.xform.scale(scaleFactor);
            this.ctx.scale(scaleFactor, scaleFactor);
            this.translate(-translation.x, -translation.y);
        }

        return translation;
    }

    translate(tx: number, ty: number): void {
        this.xform = this.xform.translate(tx, ty);
        this.ctx.translate(tx, ty);
    }

    rotate(radians: number): void {
        this.xform = this.xform.rotate((radians * 180) / Math.PI);
        return this.ctx.rotate(radians);
    }

    clearRect(x: number, y: number, w: number, h: number): void {
        this.ctx.clearRect(x, y, w, h);
    }

    transformedPoint(x: number, y: number): SVGPoint {
        let point = svg.createSVGPoint();
        point.x = x;
        point.y = y;
        return point.matrixTransform(this.xform.inverse());
    }
}
