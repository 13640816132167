import React from "react";
import { inject } from "mobx-react";

import { Grid, Label, Divider, SemanticCOLORS } from "semantic-ui-react";
import { Image } from "../../models/Image";
import {
    Instance,
    ObjectDetectionAnswer,
    deserialiseInstances,
    objectColor,
    Definition,
    ObjectDetectionDescription,
} from "../../models/model_types/object_detection";
import {
    Image as SVGImage,
    DrawingInstance,
    InstanceGroup,
} from "../annotation/ObjectDetection/Image";
import { FrameSelect } from "../helpers/FrameSelect";
import { fnGenerateVerifiedByJSX } from "../helpers/AnnotationInfo";
import { ProvidedAppStore } from "../../store/AppStore";

interface ObjectDetectionExplorerProps {
    image: Image;
}

interface State {
    bucketPath: string;
}

interface GroupName {
    label: string;
    color: string;
}

type Props = ObjectDetectionExplorerProps & ProvidedAppStore;
@inject("store")
export class ObjectDetectionExplorer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { bucketPath: this.props.image.bucketPath };

        this.updatedFrame = this.updatedFrame.bind(this);
    }

    componentDidUpdate(previousProps: Props) {
        if (this.props !== previousProps) {
            this.setState({ bucketPath: this.props.image.bucketPath });
        }
    }

    updatedFrame(bucketPath: string) {
        this.setState({ bucketPath });
    }

    render() {
        const { image } = this.props;
        const { annotations, predictions } = image as Image;
        const selectedBucketPath = this.state.bucketPath;

        const groupNames: GroupName[] = [];
        const groups: InstanceGroup[] = [];
        let verifiedByJSX = <div />;

        if (annotations.length > 0) {
            const lastAnnotation = annotations[annotations.length - 1];
            const answer = lastAnnotation.answerData;
            if (lastAnnotation.verifier) {
                verifiedByJSX = fnGenerateVerifiedByJSX(lastAnnotation);
            }

            const definition = this.props.image.modelType.definition as Definition;
            const [deserialisedInstances, objects] = deserialiseInstances(
                definition.objects,
                answer.instances
            );
            answer.instances = deserialisedInstances as Instance[];

            const drawingInstances: DrawingInstance[] = answer.instances.map(
                (i: Instance): DrawingInstance => {
                    return { instance: i, activeVertex: -1 };
                }
            );

            let object_map = new Map<string, string>();
            for (let idx = 0; idx < drawingInstances.length; ++idx) {
                const drawingInstance = drawingInstances[idx];
                const object = objects[idx] as ObjectDetectionDescription;

                object_map.set(drawingInstance.instance.label, objectColor(object));
            }
            const objs = Array.from(object_map);

            objs.forEach(([label, color]) => {
                const objectInstances = drawingInstances.filter(
                    (i: DrawingInstance) => i.instance.label === label
                );

                groupNames.push({ color: color, label: label });
                groups.push({ color: color, instances: objectInstances });
            });
        }

        // TODO: find a way of using colors to distingiush between object types without making predictions
        // and annotations look identical
        const colors = [
            "red",
            "orange",
            "yellow",
            "olive",
            "green",
            "teal",
            "blue",
            "violet",
            "purple",
            "brown",
            "grey",
            "black",
        ];

        for (let idx = 0; idx < predictions.length; ++idx) {
            const prediction = predictions[idx];
            const color = colors[idx % colors.length];
            const answer = prediction.answerData as ObjectDetectionAnswer;
            const drawingInstances = answer.instances.map((instance: Instance): DrawingInstance => {
                return { instance: instance, activeVertex: -1 };
            });

            groupNames.push({ color: color, label: prediction.model.name });
            groups.push({ color: color, instances: drawingInstances });
        }

        const labels = groupNames.map((group: GroupName, idx: number) => {
            return (
                <Label color={group.color as SemanticCOLORS} key={idx}>
                    {group.label}
                </Label>
            );
        });

        return (
            <>
                <Grid.Row>{verifiedByJSX}</Grid.Row>
                <Grid.Row>
                    {labels}
                    <FrameSelect image={image} onSelect={this.updatedFrame} />
                </Grid.Row>
                <Divider hidden={true} />
                <Grid.Row>
                    <SVGImage
                        groups={groups}
                        url={this.props.store!.hwkflowClient.getImageUrl(selectedBucketPath)}
                    />
                </Grid.Row>
            </>
        );
    }
}
