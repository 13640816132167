import React from "react";
import { inject, observer } from "mobx-react";
import { Bar } from "react-chartjs-2";

import { ProvidedAppStore } from "../../store/AppStore";
import { StatsKey, StatsData } from "../../store/ModelStore";
import { getImageStates, ImageStateUse } from "../../models/Image";

interface ImageChartProps {
    groupBy: string;
    filterBy: { [keyName: string]: string };
    imageStateUse: ImageStateUse;
}

type Props = ProvidedAppStore & ImageChartProps;

@inject("store")
@observer
export class ImageChart extends React.Component<Props> {
    render() {
        let modelStore = this.props.store!.modelStore;
        if (this.props.imageStateUse === ImageStateUse.Stats) {
            modelStore = this.props.store!.modelArchivedStore;
        }

        let data = modelStore.statistics.slice();

        data = data.filter((elem: StatsData) => {
            return Object.entries(this.props.filterBy).every((filterElem) => {
                const key = filterElem[0] as keyof StatsKey;
                return filterElem[1] === "All" || elem.key[key] === filterElem[1];
            });
        });

        const groupBy = this.props.groupBy as keyof StatsKey;
        const groups = Array.from(
            new Set(
                data.map((elem: StatsData) => {
                    return elem.key[groupBy];
                })
            )
        ).sort();

        const colours = [
            "#003f5c",
            "#374c80",
            "#7a5195",
            "#bc5090",
            "#ef5675",
            "#ff764a",
            "#ffa600",
        ];

        let datasets: Chart.ChartDataSets[] = [];

        getImageStates(this.props.imageStateUse).forEach((state: string, idx: number) => {
            let values = groups.map((group) => 0);

            data.forEach((elem: StatsData) => {
                const group = elem.key[groupBy];
                const idx = groups.indexOf(group);

                if (elem.key.imageState === state) {
                    values[idx] += elem.count;
                }
            });

            datasets.push({
                label: state,
                data: values,
                borderWidth: 1,
                backgroundColor: colours[idx % colours.length],
            });
        });

        const barData: Chart.ChartData = {
            labels: groups,
            datasets: datasets,
        };

        const barOptions: Chart.ChartOptions = {
            title: {
                display: true,
                text: `Image States by ${this.props.groupBy}`,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        },
                        stacked: true,
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            autoSkip: false,
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    bottom: 50,
                },
            },
            responsive: true,
        };

        return <Bar data={barData} options={barOptions} />;
    }
}
