import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import {
    Button,
    Grid,
    Form,
    DropdownProps,
    Divider,
    Header,
    Accordion,
    Icon,
    List,
    Dropdown,
} from "semantic-ui-react";
import { inject } from "mobx-react";

import { Image, CameraType, ImageScanType, ImageState, DatasetType } from "../../models/Image";
import { Loading } from "../helpers/Loading";
import { S3Image } from "../helpers/S3Image";
import { ModelType, ModelTypes } from "../../models/ModelType";
import { KeyPointExplorer } from "./KeyPointExplorer";
import { ClassificationExplorer } from "./ClassificationExplorer";
import { MetricExplorer } from "./MetricExplorer";
import { ObjectDetectionExplorer } from "./ObjectDetectionExplorer";
import { AbortRequests } from "../helpers/AbortRequests";
import { ProvidedAppStore } from "../../store/AppStore";
import { findNewId, NavigationDirection } from "../helpers/Navigation";

interface ImageFormState {
    modelType: ModelType;
    sport: string;
    stadium: string;
    cameraType: CameraType;
    imageScanType: ImageScanType;
    imageState: ImageState;
    datasetType: DatasetType;
    inputDownsampleFactor: number;
    pixelSize: number;
    midHipPos: number[];
    upDir: number[];
    disabled: boolean;
    application: string;
    uploadedAt: Date;
    recordedAt: Date;
    tags: string[];
    accordion: boolean;
    chosenTags: string[];
    allTags: string[];
}

interface ImageFormProps {
    image: Image;
    editable: boolean;
    onSubmit: (image: Image) => void;
}

function imageStateButtons(
    imageState: ImageState,
    image: Image,
    setImageState: (imageState: ImageState) => void
) {
    if (imageState === ImageState.Unused || imageState === ImageState.Verified) {
        return (
            <Button
                type="button"
                positive
                onClick={() => {
                    setImageState(ImageState.NeedsAnnotation);
                }}
            >
                Annotate
            </Button>
        );
    } else if (imageState === ImageState.Incoming) {
        return (
            <div>
                <Button
                    type="button"
                    positive
                    onClick={() => {
                        setImageState(ImageState.NeedsAnnotation);
                    }}
                >
                    Annotate
                </Button>
                <Divider hidden={true} />
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setImageState(ImageState.Unused);
                    }}
                >
                    Dont Annotate
                </Button>
            </div>
        );
    } else if (imageState === ImageState.NeedsAnnotation) {
        return (
            <div>
                <Link to={`/model/${image.modelType.name}/annotation/image/${image.id}`}>
                    <Button type="button" positive>
                        Annotate this Image
                    </Button>
                </Link>
                <Divider hidden={true} />
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setImageState(ImageState.Unused);
                    }}
                >
                    Dont Annotate
                </Button>
            </div>
        );
    } else if (imageState === ImageState.NeedsVerification) {
        return (
            <div>
                <Button
                    type="button"
                    negative
                    onClick={() => {
                        setImageState(ImageState.NeedsAnnotation);
                    }}
                >
                    Annotation
                </Button>
                <Divider hidden={true} />
                <Button
                    type="button"
                    positive
                    onClick={() => {
                        setImageState(ImageState.Verified);
                    }}
                >
                    Verify Annotation
                </Button>
            </div>
        );
    } else {
        return <div />;
    }
}

type IFProps = ImageFormProps & ProvidedAppStore;

@inject("store")
// When you click on an image on the Explore tab, this class deals with the metadata options down the side of the image
class ImageForm extends React.Component<IFProps, ImageFormState> implements AbortRequests {
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: IFProps) {
        super(props);
        this.handleText = this.handleText.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setImageState = this.setImageState.bind(this);

        const { image } = this.props;
        let tags = [] as string[];
        if (image.tags) {
            tags = image.tags.map((tag) => tag.name);
        }

        this.state = {
            ...image,
            tags: tags,
            disabled: true,
            accordion: false,
            allTags: [],
            chosenTags: [],
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchTags();
    }

    componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    componentDidUpdate(prevProps: IFProps) {
        if (this.props.image.id !== prevProps.image.id) {
            this.setStateWithImageFromProps();
        }
    }

    setStateWithImageFromProps() {
        const { image } = this.props;
        let tags = [] as string[];
        if (image.tags) {
            tags = image.tags.map((tag) => tag.name);
        }

        this.setState({
            modelType: image.modelType,
            sport: image.sport,
            stadium: image.stadium,
            cameraType: image.cameraType,
            imageScanType: image.imageScanType,
            imageState: image.imageState,
            datasetType: image.datasetType,
            inputDownsampleFactor: image.inputDownsampleFactor,
            pixelSize: image.pixelSize,
            midHipPos: image.midHipPos,
            upDir: image.upDir,
            application: image.application,
            uploadedAt: image.uploadedAt,
            recordedAt: image.recordedAt,
            tags: tags,
        });
    }

    fetchTags() {
        this.props
            .store!.hwkflowClient.fetchTags(this.controller.signal)
            .then((response) => this.setState({ allTags: response.data }))
            .catch(function (error) {
                console.log(error);
            });
    }

    onSubmit() {
        this.setImageState(this.state.imageState);
        this.setState({ disabled: true });
    }

    setImageState(imageState: ImageState) {
        let { image } = this.props;
        image.sport = this.state.sport;
        image.stadium = this.state.stadium;
        image.cameraType = this.state.cameraType;
        image.imageScanType = this.state.imageScanType;
        image.datasetType = this.state.datasetType;
        image.inputDownsampleFactor = this.state.inputDownsampleFactor;
        image.pixelSize = this.state.pixelSize;
        image.midHipPos = this.state.midHipPos;
        image.upDir = this.state.upDir;
        image.application = this.state.application;
        image.imageState = imageState;

        this.props.onSubmit(image);

        this.setState({ imageState });
    }

    handleText(event: React.FormEvent<HTMLInputElement>, data: any) {
        type NewState = Pick<ImageFormState, keyof ImageFormState>;

        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    handleSelect(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) {
        type NewState = Pick<ImageFormState, keyof ImageFormState>;

        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    handleAccordion = () => {
        const newState = !this.state.accordion;
        this.setState({ accordion: newState });
    };

    handleTagChange = (choice: any) => {
        this.setState({ chosenTags: choice });
    };

    addTag() {
        let formattedTags = [];
        for (let tag of this.state.tags.concat(this.state.chosenTags)) {
            formattedTags.push({ name: tag });
        }
        this.props
            .store!.hwkflowClient.updateTag(this.props.image.id, formattedTags)
            .then((response) => {
                if (response.status !== 200) {
                    alert(`failed to add tag! ${response.status} - ${response.statusText}`);
                } else if (this.mounted) {
                    this.setState({
                        tags: this.state.tags.concat(this.state.chosenTags),
                        chosenTags: [],
                    });
                    this.fetchTags();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    removeTag() {
        let formattedTags = [];
        for (let tag of this.state.tags.filter(
            (imageTag) => !this.state.chosenTags.includes(imageTag)
        )) {
            formattedTags.push({ name: tag });
        }
        this.props
            .store!.hwkflowClient.updateTag(this.props.image.id, formattedTags)
            .then((response) => {
                if (response.status !== 200) {
                    alert(`failed to remove tag! ${response.status} - ${response.statusText}`);
                } else if (this.mounted) {
                    this.setState({
                        tags: this.state.tags.filter(
                            (imageTag) => !this.state.chosenTags.includes(imageTag)
                        ),
                        chosenTags: [],
                    });
                    this.fetchTags();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { image } = this.props;
        const { disabled, imageState } = this.state;

        const fnInputText = (name: string, value: string) => {
            return (
                <Form.Field>
                    <label>{name}</label>
                    <Form.Input
                        name={name}
                        placeholder={name}
                        value={value}
                        required={true}
                        disabled={disabled}
                        onChange={this.handleText}
                    />
                </Form.Field>
            );
        };

        const fnInputSelection = (name: string, value: string, choices: string[]) => {
            const options = choices.map((choice: string) => {
                return { text: choice, value: choice };
            });

            return (
                <Form.Field>
                    <label>{name}</label>
                    <Form.Select
                        name={name}
                        placeholder={name}
                        options={options}
                        value={value}
                        onChange={this.handleSelect}
                        disabled={disabled}
                    />
                </Form.Field>
            );
        };

        let submitJSX = <div />;

        if (this.props.editable) {
            if (disabled) {
                submitJSX = (
                    <Button
                        type="button"
                        onClick={() => {
                            this.setState({ disabled: false });
                        }}
                    >
                        Edit
                    </Button>
                );
            } else {
                submitJSX = (
                    <Button
                        type="submit"
                        onClick={() => {
                            this.onSubmit();
                        }}
                    >
                        Submit
                    </Button>
                );
            }
        }
        const addTagOptions = this.state.allTags
            .sort((a, b) => a.localeCompare(b))
            .map((tag: string) => {
                return { key: tag, value: tag, text: tag };
            });
        const removeTagOptions = this.state.tags
            .sort((a, b) => a.localeCompare(b))
            .map((tag: string) => {
                return { key: tag, value: tag, text: tag };
            });

        let addTagJSX = (
            <Dropdown
                clearable
                fluid
                multiple
                search
                selection
                options={addTagOptions}
                placeholder="Select"
                onChange={(_e, props) => {
                    this.handleTagChange(props.value);
                }}
            />
        );
        let removeTagJSX = (
            <Dropdown
                clearable
                fluid
                multiple
                search
                selection
                options={removeTagOptions}
                placeholder="Select"
                onChange={(_e, props) => {
                    this.handleTagChange(props.value);
                }}
            />
        );

        const imageStateJSX = imageStateButtons(imageState, image, this.setImageState);
        const uploadedDate = new Date(this.state.uploadedAt);
        const recordedDate = new Date(this.state.recordedAt);
        const tagItems = this.state.tags.map((tag) => <List.Item key={tag}>{tag}</List.Item>);
        const numTags = String(this.state.tags.length);

        return (
            <Form>
                <Divider horizontal>
                    <Header as="h4">Image Properties</Header>
                </Divider>
                {fnInputText("sport", this.state.sport)}
                {fnInputText("stadium", this.state.stadium)}
                {fnInputSelection("datasetType", this.state.datasetType, Object.keys(DatasetType))}
                {fnInputText("inputDownsampleFactor", this.state.inputDownsampleFactor.toString())}
                {image.modelType.type === "Classification" && this.state.pixelSize != null
                    ? fnInputText("pixelSize", this.state.pixelSize.toString())
                    : null}
                {image.modelType.type === "Classification" && this.state.midHipPos != null
                    ? fnInputText("midhipPosition (x, y)", this.state.midHipPos.toString())
                    : null}
                {image.modelType.type === "Classification" && this.state.upDir != null
                    ? fnInputText("upDirection (x, y)", this.state.upDir.toString())
                    : null}
                {fnInputText("Application", this.state.application)}
                {fnInputText("Date of Upload", uploadedDate.toDateString())}
                {fnInputText("Date of Recording", recordedDate.toDateString())}
                {submitJSX}
                <Divider horizontal>
                    <Header as="h4">Tags</Header>
                </Divider>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>{addTagJSX}</Grid.Column>
                        <Grid.Column>
                            <Button
                                type="button"
                                onClick={() => {
                                    this.addTag();
                                }}
                            >
                                Add Tag
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={10}>{removeTagJSX}</Grid.Column>
                        <Grid.Column>
                            <Button
                                type="button"
                                onClick={() => {
                                    this.removeTag();
                                }}
                            >
                                Remove Tag
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Accordion>
                    <Accordion.Title
                        active={this.state.accordion}
                        index={0}
                        onClick={this.handleAccordion}
                    >
                        <Icon name="dropdown" />
                        {numTags + " Tags"}
                    </Accordion.Title>
                    <Accordion.Content active={this.state.accordion}>
                        <List>{tagItems}</List>
                    </Accordion.Content>
                </Accordion>
                <Divider horizontal>
                    <Header as="h4">
                        Image State
                        <Header.Subheader>{imageState}</Header.Subheader>
                    </Header>
                </Divider>
                <Form.Field>{imageStateJSX}</Form.Field>
            </Form>
        );
    }
}

class ImageExplorerState {
    image?: Image = undefined;
}

type Props = RouteComponentProps & ProvidedAppStore;

// When you click on an image in the Explore tab, this displays the image and its annotation
@inject("store")
export class ImageExplorer
    extends React.Component<Props, ImageExplorerState>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = { image: undefined };
        this.loadImage = this.loadImage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(image: Image) {
        this.props
            .store!.hwkflowClient.updateImage(image)
            .then((response) => {
                if (response.status !== 200) {
                    alert(
                        `failed to submit image properties - ${response.status} - ${response.statusText}`
                    );
                    return;
                }
                if (this.mounted) this.setState({ image });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    loadImage() {
        const { params } = this.props.match;
        const { id } = params as any;

        this.props
            .store!.hwkflowClient.fetchImages([id], true)
            .then((response) => {
                const images = response.data as Image[];
                if (images.length !== 1) {
                    console.log("failed to load image correctly");
                    return;
                }
                const image = images[0];
                if (this.mounted) this.setState({ image });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleDownload = (downloadUrl: string) => {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "image.jpg";
        link.click();
    };

    shouldDisableNavigation() {
        return (
            !this.props.store!.imageStore.imageIds ||
            this.props.store!.imageStore.imageIds.length === 0
        );
    }

    getTaskFromProps() {
        const { params } = this.props.match;
        const { task } = params as any;
        return task;
    }

    async goToExplorer() {
        let task = this.getTaskFromProps();
        const newLocation = `/model/${task}/explore/${this.props.store?.imageStore.page}`;
        this.props.history.push(newLocation);
    }

    navigateToImage(currentId: number, direction: NavigationDirection) {
        const imageIds = this.props.store!.imageStore.imageIds.slice();
        const newId = findNewId(imageIds, currentId, direction);

        if (!newId) return;

        let task = this.getTaskFromProps();
        const newLocation = `/model/${task}/image/${newId}`;
        this.props.history.push(newLocation);
    }

    async componentDidMount() {
        this.mounted = true;
        await this.loadImage();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    async componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location !== prevProps.location) {
            await this.loadImage();
        }
    }

    render() {
        const { image } = this.state;

        if (!image) {
            return (
                <Grid>
                    <Grid.Row>
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Back"
                            onClick={this.props.history.goBack}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Loading />
                    </Grid.Row>
                </Grid>
            );
        }

        let imageJSX = <div />;

        if (image.modelType.type === ModelTypes.KeyPoints) {
            imageJSX = <KeyPointExplorer image={image} />;
        } else if (image.modelType.type === ModelTypes.Classification) {
            imageJSX = <ClassificationExplorer image={image} />;
        } else if (image.modelType.type === ModelTypes.Metric) {
            imageJSX = <MetricExplorer image={image} />;
        } else if (image.modelType.type === ModelTypes.ObjectDetection) {
            imageJSX = <ObjectDetectionExplorer image={image} />;
        } else {
            imageJSX = (
                <S3Image
                    url={this.props.store!.hwkflowClient.getImageUrl(image.bucketPath)}
                    rounded
                />
            );
        }

        return (
            <Grid>
                <Grid.Row>
                    <Button
                        labelPosition="left"
                        icon="left chevron"
                        content="Back to explorer"
                        color="red"
                        onClick={() => this.goToExplorer()}
                    />
                    <Button
                        type="button"
                        color="blue"
                        icon="download"
                        content="Download This Image"
                        onClick={() =>
                            this.handleDownload(
                                this.props.store!.hwkflowClient.getImageUrl(image.bucketPath)
                            )
                        }
                    />
                    <Button
                        labelPosition="left"
                        icon="left chevron"
                        content="Previous image"
                        onClick={() => {
                            this.navigateToImage(image.id, NavigationDirection.Previous);
                        }}
                        disabled={this.shouldDisableNavigation()}
                    />
                    <Button
                        labelPosition="right"
                        icon="right chevron"
                        content="Next image"
                        onClick={() => {
                            this.navigateToImage(image.id, NavigationDirection.Next);
                        }}
                        disabled={this.shouldDisableNavigation()}
                    />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <ImageForm image={image} editable={true} onSubmit={this.onSubmit} />
                    </Grid.Column>
                    <Grid.Column width={12}>{imageJSX}</Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
