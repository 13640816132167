import React from "react";

import { TrackedCTX } from "../../helpers/TrackedSVG";
import { Point, Instance } from "../../../models/model_types/object_detection";

import "./Image.css";

const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

function toSVGColor(color: string) {
    const svgColors = [
        { color: "red", svgColor: "#DB2828" },
        { color: "green", svgColor: "#16ab39" },
        { color: "orange", svgColor: "#F2711C" },
        { color: "yellow", svgColor: "#FBBD08" },
        { color: "olive", svgColor: "#B5CC18" },
        { color: "teal", svgColor: "#00B5AD" },
        { color: "blue", svgColor: "#2185D0" },
        { color: "violet", svgColor: "#6435C9" },
        { color: "purple", svgColor: "#A333C8" },
        { color: "pink", svgColor: "#E03997" },
        { color: "brown", svgColor: "#A5673F" },
        { color: "grey", svgColor: "#767676" },
        { color: "black", svgColor: "#27292a" },
    ];

    const svgColor = svgColors.find((elem) => elem.color === color);

    if (!svgColor) {
        return "grey";
    }

    return svgColor.svgColor;
}

export interface DrawingInstance {
    instance: Instance;
    activeVertex: number;
}

export interface InstanceGroup {
    color: string;
    instances: DrawingInstance[];
}

//note(Alex.Shaw): Provide one of image or url.
interface ImageProps {
    image?: ImageBitmap;
    url?: string;
    groups: InstanceGroup[];

    onClick?: (button: number, x: number, y: number) => void;
    onMouseDown?: (button: number, x: number, y: number) => void;
    onMove?: (x: number, y: number) => void;
}

function filledCircle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number,
    svgColor: string,
    lineWidth: number
) {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fillStyle = svgColor;
    ctx.fill();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#003300";
    ctx.stroke();
}

export class Image extends React.Component<ImageProps> {
    private canvasElement?: HTMLCanvasElement;
    private zoomCanvasElement?: HTMLCanvasElement;
    private imageElement?: HTMLImageElement;
    private divElement?: HTMLDivElement;
    private sourceMode: "url" | "imageBitmap";

    private imageScale: number = 0;
    private beingDragged: boolean = false;
    private dragStarted: boolean = false;
    private imageWidth: number = 0;
    private imageHeight: number = 0;

    private trackedCTX?: TrackedCTX;
    private lastClick: SVGPoint;
    private lastPos: SVGPoint;
    private translation: { x: number; y: number };
    private delta: { x: number; y: number };

    constructor(props: ImageProps) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onResize = this.onResize.bind(this);

        this.drawAnnotatedImage = this.drawAnnotatedImage.bind(this);
        this.drawInstance = this.drawInstance.bind(this);
        this.estimateCanvasSize = this.estimateCanvasSize.bind(this);

        this.lastClick = svg.createSVGPoint();
        this.lastPos = svg.createSVGPoint();
        this.translation = { x: 0, y: 0 };
        this.delta = { x: 0, y: 0 };

        this.sourceMode = props.image ? "imageBitmap" : "url";
        if (this.sourceMode === "imageBitmap") {
            this.imageWidth = props.image!.width;
            this.imageHeight = props.image!.height;
        }
    }

    //note(Alex.Shaw): Add support for ImageBitmaps drawn directly to the canvas.
    // If we have this, we dont need the <img> tag so can skip the check.
    isImageElementRequired() {
        if (this.sourceMode === "imageBitmap") return false;
        return this.imageElement == null;
    }

    componentDidMount() {
        if (
            this.canvasElement == null ||
            this.isImageElementRequired() ||
            this.divElement == null
        ) {
            console.warn("mounted without dom refs");
            return;
        }

        this.canvasElement.oncontextmenu = function (event: MouseEvent) {
            event.preventDefault();
        };

        this.estimateCanvasSize(this.divElement.clientWidth, this.canvasElement);

        this.lastPos.x = 0;
        this.lastPos.y = 0;
        this.lastClick = this.lastPos;

        const ctx = this.canvasElement.getContext("2d");
        if (ctx) {
            this.trackedCTX = new TrackedCTX(ctx);
        }

        this.canvasElement.onmousedown = this.onMouseDown;
        this.canvasElement.onmousemove = this.onMouseMove;
        this.canvasElement.onmouseup = this.onMouseUp;
        this.canvasElement.onwheel = this.onMouseWheel;
        this.canvasElement.addEventListener("wheel", this.onMouseWheel, false);

        window.addEventListener("resize", this.onResize, true);

        if (this.sourceMode === "url") {
            this.imageElement!.onload = () => {
                if (this.canvasElement == null || this.divElement == null) {
                    console.warn("updated without dom refs");
                    return;
                }
                this.imageWidth = this.imageElement!.width;
                this.imageHeight = this.imageElement!.height;

                this.estimateCanvasSize(this.divElement.clientWidth, this.canvasElement);

                this.canvasElement.width = this.divElement.clientWidth;
                this.canvasElement.height = this.divElement.clientHeight;

                const ctx = this.canvasElement.getContext("2d");
                if (ctx) {
                    this.trackedCTX = new TrackedCTX(ctx);
                }
                this.drawAnnotatedImage();
            };
        } else if (this.sourceMode === "imageBitmap") {
            this.drawAnnotatedImage();
        }
    }

    componentDidUpdate(prevProps: Readonly<ImageProps>) {
        this.drawAnnotatedImage();
    }

    render() {
        return (
            <div
                className="ObjectDetectionImage"
                ref={(child: HTMLDivElement) => (this.divElement = child)}
            >
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.canvasElement = child)}
                    width={`100%`}
                    height={`100%`}
                    style={{ border: "1px solid gray" }}
                />
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.zoomCanvasElement = child)}
                    width={150}
                    height={150}
                    style={{
                        border: "1px solid gray",
                        position: "absolute",
                        top: "5px",
                        right: "15px",
                        zIndex: 10,
                    }}
                />
                {this.sourceMode === "url" && (
                    <img
                        alt="object detection wizard"
                        ref={(child: HTMLImageElement) => (this.imageElement = child)}
                        src={this.props.url}
                        style={{ display: "none" }}
                    />
                )}
            </div>
        );
    }

    // Adapt canvas size to the image size.
    private estimateCanvasSize(maxCanvasSize: number, canvas: HTMLCanvasElement) {
        if (this.isImageElementRequired()) {
            console.warn("updated without dom refs");
            return;
        }
        const maxImageSize = Math.max(this.imageWidth, this.imageHeight);
        canvas.width = this.imageWidth / (maxImageSize / maxCanvasSize);
        canvas.height = this.imageHeight / (maxImageSize / maxCanvasSize);
    }

    private onMouseDown(event: MouseEvent) {
        if (event.button === 0) {
            this.beingDragged = false;
            this.dragStarted = true;

            if (!this.trackedCTX) {
                console.warn(`onMouseDown without trackedCTX...`);
                return;
            }

            this.lastClick = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
            this.drawAnnotatedImage();
        } else if (event.button === 2 && this.props.onMouseDown) {
            if (!this.trackedCTX) {
                console.warn(`onMouseDown without trackedCTX...`);
                return;
            }

            const canvasPoint = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
            const imageX = canvasPoint.x / this.imageScale;
            const imageY = canvasPoint.y / this.imageScale;

            if (imageX < 0 || imageX >= this.imageWidth) {
                return;
            }

            if (imageY < 0 || imageY >= this.imageHeight) {
                return;
            }
            this.props.onMouseDown(event.button, imageX, imageY);
        }
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.trackedCTX) {
            console.warn(`onMouseMove without trackedCTX...`);
            return;
        }

        this.lastPos = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);

        if (this.dragStarted) {
            this.beingDragged = true;

            const deltaX = this.lastPos.x - this.lastClick.x;
            const deltaY = this.lastPos.y - this.lastClick.y;
            this.delta.x += deltaX;
            this.delta.y += deltaY;

            this.trackedCTX.translate(deltaX, deltaY);
            this.drawAnnotatedImage();
        }

        if (!this.props.onMove) {
            return;
        }

        const imageX = this.lastPos.x / this.imageScale;
        const imageY = this.lastPos.y / this.imageScale;

        if (imageX < 0 || imageX >= this.imageWidth) {
            return;
        }

        if (imageY < 0 || imageY >= this.imageHeight) {
            return;
        }

        this.props.onMove(imageX, imageY);
    }

    private onMouseWheel(event: WheelEvent) {
        if (!this.trackedCTX) {
            console.warn(`onMouseWheel without trackedCTX...`);
            return;
        }

        event.preventDefault();

        const wheelDelta = event.deltaY ? -event.deltaY / 130 : -event.detail ? event.detail : 0;

        const scaleFactor = 1.05;
        const zoomFactor = Math.pow(scaleFactor, wheelDelta);

        this.translation = this.trackedCTX.scale(zoomFactor, this.lastPos, this.translation);

        this.drawAnnotatedImage();
    }

    private onMouseUp(event: MouseEvent) {
        const wasBeingDragged = this.beingDragged && this.dragStarted;
        this.beingDragged = false;
        this.dragStarted = false;
        if (wasBeingDragged) {
            return;
        }

        if (!this.props.onClick) {
            return;
        }

        if (!this.trackedCTX) {
            console.warn(`mouse up without trackedCTX...`);
            return;
        }

        const canvasPoint = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
        const imageX = canvasPoint.x / this.imageScale;
        const imageY = canvasPoint.y / this.imageScale;

        if (imageX < 0 || imageX >= this.imageWidth) {
            return;
        }

        if (imageY < 0 || imageY >= this.imageHeight) {
            return;
        }

        this.props.onClick(event.button, imageX, imageY);
    }

    private onResize() {
        if (this.canvasElement == null || this.divElement == null) {
            return;
        }

        this.estimateCanvasSize(this.divElement.clientWidth, this.canvasElement);
        this.drawAnnotatedImage();
    }

    private drawInstance(
        ctx: CanvasRenderingContext2D,
        instance: Instance,
        color: string,
        activeVertex: number
    ) {
        if (!this.trackedCTX) {
            console.warn(`drawInstance without trackedCTX...`);
            return;
        }

        const svgColor = toSVGColor(color);
        for (const fromVertex of instance.points) {
            if (!fromVertex.visible) {
                continue;
            }
            const fromX = fromVertex.pixelX * this.imageScale;
            const fromY = fromVertex.pixelY * this.imageScale;

            const jointColor = fromVertex.obscured ? toSVGColor("orange") : svgColor;
            const obscuredResize = fromVertex.obscured ? 2 : 1;

            const radius = (obscuredResize * 3) / this.trackedCTX.getScale();
            const lineWidth = 2 / this.trackedCTX.getScale();

            filledCircle(ctx, fromX, fromY, radius, jointColor, lineWidth);

            const dynamicFontSize = 10 / this.trackedCTX.getScale();
            const defaultFontSize = 3;
            const fontSize = dynamicFontSize < defaultFontSize ? dynamicFontSize : defaultFontSize;

            ctx.beginPath();
            ctx.font = `${fontSize}px Arial`;
            ctx.stroke();
        }

        const shape = instance.shape();
        if (shape.length > 1) {
            let toVertex = shape[shape.length - 1];
            for (const fromVertex of shape) {
                const fromX = fromVertex.pixelX * this.imageScale;
                const fromY = fromVertex.pixelY * this.imageScale;

                const toX = toVertex.pixelX * this.imageScale;
                const toY = toVertex.pixelY * this.imageScale;

                ctx.beginPath();
                ctx.moveTo(fromX, fromY);
                ctx.lineWidth = 3 / this.trackedCTX.getScale();
                ctx.strokeStyle = svgColor;
                ctx.lineTo(toX, toY);
                ctx.stroke();

                toVertex = fromVertex;
            }
        }

        if (activeVertex >= 0 && 0 < instance.points.length) {
            // Note that we use the points directly here rather than the shape() method, because
            // activeVertex is an index of the points
            const thisVertex = activeVertex < instance.points.length ? activeVertex : 0;
            const prevVertex = activeVertex > 0 ? activeVertex - 1 : instance.points.length - 1;
            const points = [instance.points[thisVertex], instance.points[prevVertex]];
            points.forEach((point: Point) => {
                const x = point.pixelX * this.imageScale;
                const y = point.pixelY * this.imageScale;
                const radius = 6 / this.trackedCTX!.getScale();
                const lineWidth = 2 / this.trackedCTX!.getScale();

                filledCircle(ctx, x, y, radius, svgColor, lineWidth);
            });
        }
    }

    private getDrawImageSizes(canvas: HTMLCanvasElement, to_save: boolean) {
        const scaleWidth = canvas.width / this.imageWidth;
        const scaleHeight = canvas.height / this.imageHeight;

        const scaleZoom = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
        if (to_save) {
            this.imageScale = scaleZoom;
        }

        const canvasImageWidth = this.imageWidth * scaleZoom;
        const canvasImageHeight = this.imageHeight * scaleZoom;

        return { width: canvasImageWidth, height: canvasImageHeight };
    }

    private drawAnnotatedImage() {
        if (
            this.canvasElement == null ||
            this.zoomCanvasElement == null ||
            this.divElement == null ||
            this.isImageElementRequired()
        ) {
            console.warn("mounted without dom refs");
            return;
        }

        if (!this.trackedCTX) {
            console.warn(`onMouseWheel without trackedCTX...`);
            return;
        }

        const ctx = this.canvasElement.getContext("2d");
        const ctxZoom = this.zoomCanvasElement.getContext("2d");
        if (!ctx || !ctxZoom) {
            return;
        }

        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        ctx.restore();

        const imageToDisplay = this.sourceMode === "url" ? this.imageElement : this.props.image;
        const imageDrawSizes = this.getDrawImageSizes(this.canvasElement, true);
        ctx.drawImage(imageToDisplay!, 0, 0, imageDrawSizes.width, imageDrawSizes.height);

        const { groups } = this.props;

        for (const group of groups) {
            const { color, instances } = group;
            for (const drawingInstance of instances) {
                const instance = drawingInstance.instance;
                this.drawInstance(ctx, instance, color, drawingInstance.activeVertex);
            }
        }

        // Draw zoom preview
        const scale = this.trackedCTX.getScale();
        this.estimateCanvasSize(this.divElement?.clientWidth / 5, this.zoomCanvasElement);
        const imageDrawSizesZoom = this.getDrawImageSizes(this.zoomCanvasElement, false);
        ctxZoom.drawImage(
            imageToDisplay!,
            0,
            0,
            imageDrawSizesZoom.width,
            imageDrawSizesZoom.height
        );

        // Add zoom rectagle
        ctxZoom.strokeStyle = "red";
        ctxZoom.lineWidth = 2;
        if (scale === 1) {
            this.delta = { x: 0, y: 0 };
        }

        const widthScale = this.zoomCanvasElement.width / this.canvasElement.width;
        const heightScale = this.zoomCanvasElement.height / this.canvasElement.height;
        const rectX = ((this.lastPos.x - this.delta.x) * (scale - 1) * widthScale) / scale;
        const rectY = ((this.lastPos.y - this.delta.y) * (scale - 1) * heightScale) / scale;
        const rectWidth = this.zoomCanvasElement.width / scale;
        const rectHeight = this.zoomCanvasElement.height / scale;
        ctxZoom.strokeRect(rectX, rectY, rectWidth, rectHeight);
    }
}
