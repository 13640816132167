import React from "react";
import { Divider, Grid, Dropdown, Tab, Button } from "semantic-ui-react";
import { Container, Message, Table, Header, Accordion, Icon } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { ModelType, ModelTypes } from "../../models/ModelType";
import {
    Definition as KeyPointDefinition,
    KeyPointDescription,
} from "../../models/model_types/keypoints";
import { Definition as ClassificationDefinition } from "../../models/model_types/classification";
import {
    Definition as ObjectDetectionDefinition,
    ObjectDetectionDescription,
} from "../../models/model_types/object_detection";
import { Definition as MetricDefinition } from "../../models/model_types/metric";

import { Loading } from "../helpers/Loading";
import { ImageChart } from "./ImageChart";
import { ImageState, ImageStateUse } from "../../models/Image";

interface ModelSummaryProps extends ProvidedAppStore {
    modelType?: ModelType;
}
interface ModelSummaryState {
    sport: string;
    stadium: string;
    currentState: ImageState;
    destination: ImageState;
    amount: number;
    enabled: boolean;
}

interface KeyPointSummaryProps {
    summary: KeyPointDefinition;
}

interface ClassificationSummaryProps {
    summary: ClassificationDefinition;
}

interface ObjectDetectionSummaryProps {
    summary: ObjectDetectionDefinition;
}

interface MetricSummaryProps {
    summary: MetricDefinition;
}

interface SummaryState {
    active: boolean;
}

class KeyPointSummary extends React.Component<KeyPointSummaryProps, SummaryState> {
    constructor(props: KeyPointSummaryProps) {
        super(props);
        this.state = { active: false };
    }

    render() {
        const tableRows = this.props.summary.keypoints.map(
            (keypoint: KeyPointDescription, idx: number) => {
                return (
                    <Table.Row key={idx}>
                        <Table.Cell>{keypoint.name}</Table.Cell>
                        <Table.Cell>{keypoint.description}</Table.Cell>
                    </Table.Row>
                );
            }
        );

        const { active } = this.state;

        return (
            <Accordion styled={true} fluid={true}>
                <Accordion.Title
                    active={active}
                    index={0}
                    onClick={() => {
                        this.setState({ active: !active });
                    }}
                >
                    <Icon name="dropdown" />
                    KeyPoints
                </Accordion.Title>
                <Accordion.Content active={active}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{tableRows}</Table.Body>
                    </Table>
                </Accordion.Content>
            </Accordion>
        );
    }
}

class ClassificationSummary extends React.Component<ClassificationSummaryProps, SummaryState> {
    constructor(props: ClassificationSummaryProps) {
        super(props);
        this.state = { active: false };
    }

    render() {
        return <div />;
    }
}

class MetricSummary extends React.Component<MetricSummaryProps, SummaryState> {
    constructor(props: MetricSummaryProps) {
        super(props);
        this.state = { active: false };
    }

    render() {
        return <div />;
    }
}

class ObjectDetectionSummary extends React.Component<ObjectDetectionSummaryProps, SummaryState> {
    constructor(props: ObjectDetectionSummaryProps) {
        super(props);
        this.state = { active: false };
    }

    render() {
        const tableRows = this.props.summary.objects.map(
            (object: ObjectDetectionDescription, idx: number) => {
                return (
                    <Table.Row key={idx}>
                        <Table.Cell>{object.label}</Table.Cell>
                        <Table.Cell>{object.shape_type}</Table.Cell>
                    </Table.Row>
                );
            }
        );

        const { active } = this.state;

        return (
            <Accordion styled={true} fluid={true}>
                <Accordion.Title
                    active={active}
                    index={0}
                    onClick={() => {
                        this.setState({ active: !active });
                    }}
                >
                    <Icon name="dropdown" />
                    Objects
                </Accordion.Title>
                <Accordion.Content active={active}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{tableRows}</Table.Body>
                    </Table>
                </Accordion.Content>
            </Accordion>
        );
    }
}

@inject("store")
@observer
class ModelSummary extends React.Component<ModelSummaryProps, ModelSummaryState> {
    constructor(props: ModelSummaryProps) {
        super(props);
        this.state = {
            sport: "All",
            stadium: "All",
            currentState: ImageState.Unused,
            destination: ImageState.Unused,
            amount: -1,
            enabled: true,
        };
    }

    handleSportSelection = (choice: any) => {
        this.setState({ sport: choice });
    };
    handleStadiumSelection = (choice: any) => {
        this.setState({ stadium: choice });
    };
    handleStateSelection = (choice: any) => {
        this.setState({ currentState: choice });
    };
    handleDestinationSelection = (choice: any) => {
        this.setState({ destination: choice });
    };

    handleNumberEntry = (e: any) => {
        const re = /^[0-9\b]+$/;
        //Check that entry is a number
        if (e.target.value !== "" && re.test(e.target.value)) {
            this.setState({ amount: e.target.value, enabled: true });
        } else if (e.target.value === "") {
            this.setState({ amount: -1, enabled: true });
        } else {
            this.setState({ amount: -1, enabled: false });
        }
    };

    generateDropdown = (entries: any, addAll: boolean) => {
        const dropDown = entries.map((entry: string) => {
            return {
                key: entry,
                text: entry,
                value: entry,
            };
        });
        if (addAll) {
            dropDown.push({
                key: "All",
                text: "All",
                value: "All",
            });
        }
        return dropDown;
    };

    render() {
        if (this.props.modelType == null) {
            return <Loading />;
        }

        const { modelType } = this.props;

        let definitionJSX = <Message>Unknown Definition Type</Message>;

        if (modelType.type === ModelTypes.KeyPoints) {
            const definition = modelType.definition as KeyPointDefinition;
            definitionJSX = <KeyPointSummary summary={definition} />;
        } else if (modelType.type === ModelTypes.Classification) {
            const definition = modelType.definition as ClassificationDefinition;
            definitionJSX = <ClassificationSummary summary={definition} />;
        } else if (modelType.type === ModelTypes.ObjectDetection) {
            const definition = modelType.definition as ObjectDetectionDefinition;
            definitionJSX = <ObjectDetectionSummary summary={definition} />;
        } else if (modelType.type === ModelTypes.Metric) {
            const definition = modelType.definition as MetricDefinition;
            definitionJSX = <MetricSummary summary={definition} />;
        }

        return (
            <Container>
                <Grid>
                    <Grid.Row>
                        <Header
                            as="h2"
                            content={modelType.name}
                            subheader={`${modelType.type} - ${modelType.id}`}
                        />
                    </Grid.Row>
                    <Grid.Row>{modelType.description}</Grid.Row>
                    <Grid.Row>{definitionJSX}</Grid.Row>
                </Grid>
            </Container>
        );
    }
}

interface DataSummaryState {
    sport: string;
}

interface DataSummaryProps extends ProvidedAppStore {
    imageStateUse: ImageStateUse;
}

@inject("store")
@observer
class DataSummary extends React.Component<DataSummaryProps, DataSummaryState> {
    constructor(props: DataSummaryProps) {
        super(props);
        this.state = { sport: "All" };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event: any, data: any) {
        type NewState = Pick<DataSummaryState, keyof DataSummaryState>;
        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    render() {
        let modelStore = this.props.store!.modelStore;
        if (this.props.imageStateUse === ImageStateUse.Stats) {
            modelStore = this.props.store!.modelArchivedStore;
        }

        const sportsDropDown = modelStore.sports.map((sport: string) => {
            return {
                key: sport,
                text: sport,
                value: sport,
            };
        });

        sportsDropDown.push({
            key: "All",
            text: "All",
            value: "All",
        });

        const panes = [
            {
                menuItem: "By Sport",
                render: () => (
                    <ImageChart
                        groupBy={"sport"}
                        filterBy={{}}
                        imageStateUse={this.props.imageStateUse}
                    />
                ),
            },
            {
                menuItem: "By Stadium",
                render: () => (
                    <>
                        <Dropdown
                            name="sport"
                            fluid
                            selection
                            value={this.state.sport}
                            options={sportsDropDown}
                            onChange={this.onChange}
                        />
                        <ImageChart
                            groupBy={"stadium"}
                            filterBy={{ sport: this.state.sport }}
                            imageStateUse={this.props.imageStateUse}
                        />
                    </>
                ),
            },
            {
                menuItem: "By Application",
                render: () => (
                    <ImageChart
                        groupBy={"application"}
                        filterBy={{}}
                        imageStateUse={this.props.imageStateUse}
                    />
                ),
            },
        ];

        return (
            <Grid>
                <Grid.Row columns={16}>
                    <Grid.Column width={16}>
                        <Tab panes={panes} menu={{ secondary: true }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={16}>
                    <Grid.Column width={2} />
                    <Grid.Column width={12}></Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

@inject("store")
@observer
export class Summary extends React.Component<ProvidedAppStore> {
    constructor(props: ProvidedAppStore) {
        super(props);
        this.generateStats = this.generateStats.bind(this);
        this.generateUnusedStats = this.generateUnusedStats.bind(this);
    }

    async generateStats() {
        await this.props.store!.modelStore.fetchStatistics(ImageStateUse.Regular);
    }
    async generateUnusedStats() {
        await this.props.store!.modelArchivedStore.fetchStatistics(ImageStateUse.Stats);
    }

    render() {
        const modelStore = this.props.store!.modelStore;
        const modelArchivedStore = this.props.store!.modelArchivedStore;

        let summaryJSX = <div />;
        if (modelStore.generated) {
            summaryJSX = <DataSummary imageStateUse={ImageStateUse.Regular} />;
        }

        let unusedSummaryJSX = <div />;
        if (modelArchivedStore.generated) {
            unusedSummaryJSX = <DataSummary imageStateUse={ImageStateUse.Stats} />;
        }

        return (
            <>
                <Divider horizontal>Model Summary</Divider>
                <ModelSummary modelType={modelStore.modelType} />
                <Divider horizontal>Data Summary</Divider>

                <Button
                    color="green"
                    content={"Generate Summary Statistics"}
                    onClick={this.generateStats}
                />
                {summaryJSX}
                <Divider horizontal>Unused Data Summary</Divider>
                <Button
                    color="green"
                    content={"Generate Unused Summary Statistics"}
                    onClick={this.generateUnusedStats}
                />
                {unusedSummaryJSX}
            </>
        );
    }
}
