import React from "react";
import { Header, Table, Button, Container, Icon, Modal, Form, TextArea } from "semantic-ui-react";
import { ProvidedAppStore } from "../../store/AppStore";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { DataFile } from "../../models/DataFile";
import { AbortRequests } from "../helpers/AbortRequests";

type Props = RouteComponentProps & ProvidedAppStore;

interface State {
    files: DataFile[];
    description: string;
    open: boolean;
    id: number;
}

@inject("store")
@observer
export class DataFiles extends React.Component<Props, State> implements AbortRequests {
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            files: [],
            description: "",
            open: false,
            id: 0,
        };
        this.fetchFiles = this.fetchFiles.bind(this);
        this.discoverFiles = this.discoverFiles.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
        this.fetchFiles();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    fetchFiles() {
        this.props
            .store!.hwkflowClient.fetchFiles(this.controller.signal)
            .then((response) => {
                this.setState({ files: response.data as DataFile[] });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async discoverFiles() {
        await this.props.store!.hwkflowClient.discoverFiles();
        if (this.mounted) this.fetchFiles();
    }

    async updateDescription() {
        await this.props.store!.hwkflowClient.updateDescription(
            this.state.id,
            this.state.description
        );
        if (this.mounted) this.fetchFiles();
    }

    handleDescriptionChange = (event: any, data: any) => {
        const { value } = data;
        this.setState({ description: value });
    };

    render() {
        this.state.files.sort((a: any, b: any) => (a.id > b.id ? -1 : b.id > a.id ? 1 : 0));
        const filesList = this.state.files.map((dataFile: DataFile) => {
            let editJSX = (
                <Modal
                    onClose={() => this.setState({ open: false })}
                    onOpen={() =>
                        this.setState({
                            open: true,
                            description: dataFile.description,
                            id: dataFile.id,
                        })
                    }
                    open={this.state.open}
                    trigger={
                        <Button>
                            <Icon name="pencil" />
                        </Button>
                    }
                >
                    <Modal.Header>Edit Description</Modal.Header>
                    <Modal.Content>
                        <Form onSubmit={() => this.updateDescription()}>
                            <Form.Field
                                width={16}
                                name={"description"}
                                control={TextArea}
                                placeholder="Description"
                                value={this.state.description}
                                onChange={this.handleDescriptionChange}
                            />
                            <Form.Button content="Submit" />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="black" onClick={() => this.setState({ open: false })}>
                            Exit
                        </Button>
                    </Modal.Actions>
                </Modal>
            );

            const createdAt = new Date(dataFile.uploadedAt);

            return (
                <Table.Row key={dataFile.id}>
                    <Table.Cell>{dataFile.filename}</Table.Cell>
                    <Table.Cell>{dataFile.description}</Table.Cell>
                    <Table.Cell>{createdAt.toLocaleDateString()}</Table.Cell>
                    <Table.Cell>{editJSX}</Table.Cell>
                </Table.Row>
            );
        });

        let trainingsDisplayJSX = (
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>File Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Uploaded</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{filesList}</Table.Body>
            </Table>
        );

        return (
            <Container>
                <Header as="h1">Files Page</Header>

                <Button color="green" onClick={this.discoverFiles}>
                    Discover Files
                </Button>
                {trainingsDisplayJSX}
            </Container>
        );
    }
}
