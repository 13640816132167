import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Button, Container, Divider, Grid, Header, Loader } from "semantic-ui-react";
import { Media, mediaIsImage, MediaStore } from "../../store/MediaStore";
import { RouteComponentProps } from "react-router";
import { findNewId, NavigationDirection } from "../helpers/Navigation";
import { MediaInfoPanel } from "./MediaInfo";
import { S3Image } from "../helpers/S3Image";
import { MediaStateManagement } from "./MediaStateManagement";
type Props = ProvidedAppStore & RouteComponentProps;

interface MediaViewState {
    media?: Media;
}

@inject("store")
@observer
export class MediaViewPage extends React.Component<Props, MediaViewState> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = { media: undefined };
    }

    async componentDidMount() {
        if (!this.store) return;
        const mediaId = this.fetchRouteParameters().id;
        this.loadMedia(mediaId);
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async loadMedia(id: number) {
        if (!this.store || !id) return;
        this.setState({ media: await this.store.getMedia(id) });
    }

    async goToExplorer() {
        this.props.history.push(`/media/explore/${this.store?.currentPage || 0}`);
    }

    handleDownload() {
        if (!this.store) return;
        if (!this.state.media) return;
        const link = document.createElement("a");
        link.href = this.store.getMediaUrl(this.state.media.bucketPath);
        link.click();
    }

    async navigateFromMedia(direction: NavigationDirection) {
        if (!this.store) return;
        if (!this.state.media) return;
        const mediaIds = this.store.filteredMediaIds.slice();
        const currentId = this.state.media?.id;
        const newId = findNewId(mediaIds, currentId, direction);

        if (!newId) return;
        const newLocation = `/media/view/${newId}`;
        this.props.history.push(newLocation);
        await this.loadMedia(newId);
    }

    shouldDisableNavigation() {
        return !this.store!.filteredMediaIds || this.store?.filteredMediaCount === 0;
    }

    render() {
        if (!this.state.media) return <Loader active />;
        const media = this.state.media;
        const isImage = mediaIsImage(media);

        return (
            <Container className="main-content">
                <Header as="h1">View Media {media.id || 0}</Header>
                <Grid>
                    <Grid.Row>
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Back to explorer"
                            color="red"
                            onClick={() => this.goToExplorer()}
                        />
                        <Button
                            labelPosition="left"
                            type="button"
                            color="blue"
                            icon="download"
                            content="Download Media"
                            disabled={!this.store}
                            onClick={() => {
                                this.handleDownload();
                            }}
                        />
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Previous Media"
                            onClick={() => {
                                this.navigateFromMedia(NavigationDirection.Previous);
                            }}
                            disabled={this.shouldDisableNavigation()}
                        />
                        <Button
                            labelPosition="right"
                            icon="right chevron"
                            content="Next Media"
                            onClick={() => {
                                this.navigateFromMedia(NavigationDirection.Next);
                            }}
                            disabled={this.shouldDisableNavigation()}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <MediaInfoPanel
                                media={this.state.media}
                                reloadMedia={() => this.loadMedia(this.state.media!.id)}
                            />
                            <Divider />
                            <MediaStateManagement
                                media={this.state.media}
                                reloadMedia={() => this.loadMedia(this.state.media!.id)}
                            />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {isImage ? (
                                <S3Image url={this.store!.getMediaUrl(media.bucketPath)} />
                            ) : (
                                <video
                                    src={this.store!.getMediaUrl(media.bucketPath)}
                                    controls
                                    className="media-view"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
