import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Button, Container, Grid, Header, Loader } from "semantic-ui-react";
import { Image } from "../../models/Image";
import { RouteComponentProps } from "react-router";
import { findNewId, NavigationDirection } from "../helpers/Navigation";
import { CrossValidationStore } from "../../store/CrossValidationStore";
import { KeyPointCrossValExplorer } from "./CrossValidationKeypoints";
type Props = ProvidedAppStore & RouteComponentProps;

interface CrossValidationViewState {
    image?: Image;
}

@inject("store")
@observer
export class CrossValidationViewPage extends React.Component<Props, CrossValidationViewState> {
    store: CrossValidationStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.crossValidationStore;
        this.state = { image: undefined };
    }

    async componentDidMount() {
        if (!this.store) return;
        const imageId = this.fetchRouteParameters().id;
        this.loadImage(imageId);
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async loadImage(id: number) {
        if (!this.store) return;
        const images = await this.store.getCrossValidationImage(id);
        this.setState({ image: images[0] });
    }

    async goToExplorer() {
        if (!this.store) return;
        this.props.history.push(
            `/cross-validation-explorer/${this.store?.typeModelName}/${
                this.store?.currentPage || 0
            }`
        );
    }

    handleDownload() {
        if (!this.store) return;
        if (!this.state.image) return;
        const link = document.createElement("a");
        link.href = this.store.getCrossValUrl(this.state.image.bucketPath);
        link.click();
    }

    navigate(direction: NavigationDirection) {
        if (!this.store) return;
        if (!this.state.image) return;
        const mediaIds = this.store.filteredIds.slice();
        const currentId = this.state.image?.id;
        const newId = findNewId(mediaIds, currentId, direction);

        if (!newId) return;
        const newLocation = `/cross-validation-view/${newId}`;
        this.loadImage(newId);
        this.props.history.push(newLocation);
    }

    shouldDisableNavigation() {
        return !this.store!.filteredIds || this.store?.filteredCount === 0;
    }

    render() {
        if (!this.state.image) return <Loader />;
        const image = this.state.image;

        return (
            <Container className="main-content">
                <Header as="h1">View Cross Validation image {image.id || 0}</Header>
                <Grid style={{ marginTop: "40px" }}>
                    <Button
                        labelPosition="left"
                        icon="left chevron"
                        content="Back to explorer"
                        color="red"
                        onClick={() => this.goToExplorer()}
                    />
                    <Button
                        labelPosition="left"
                        type="button"
                        color="blue"
                        icon="download"
                        content="Download Media"
                        disabled={!this.store}
                        onClick={() => {
                            this.handleDownload();
                        }}
                    />
                    <Button
                        labelPosition="left"
                        icon="left chevron"
                        content="Previous Image"
                        onClick={() => {
                            this.navigate(NavigationDirection.Previous);
                        }}
                        disabled={this.shouldDisableNavigation()}
                    />
                    <Button
                        labelPosition="right"
                        icon="right chevron"
                        content="Next Image"
                        onClick={() => {
                            this.navigate(NavigationDirection.Next);
                        }}
                        disabled={this.shouldDisableNavigation()}
                    />
                </Grid>
                <KeyPointCrossValExplorer image={image} />
            </Container>
        );
    }
}
