import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import {
    Button,
    Card,
    Container,
    Grid,
    Header,
    Icon,
    Loader,
    SemanticWIDTHS,
} from "semantic-ui-react";
import { MediaFilter } from "./MediaFilter";
import { Media, mediaIsImage, MediaStore } from "../../store/MediaStore";
import { RouteComponentProps } from "react-router";
import { Pagination } from "../helpers/Pagination";
import { S3Image } from "../helpers/S3Image";
import { Link } from "react-router-dom";

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class MediaExplorePage extends React.Component<Props, {}> {
    store: MediaStore | undefined;
    gridRows: number = 5;
    gridColumns: number = 4;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
    }

    async componentDidMount() {
        if (!this.store) return;
        this.store.setPageSize(this.gridColumns * this.gridRows);
        this.store.calculateTotalPages();

        let desiredPage = this.fetchRouteParameters().page;
        if (desiredPage > this.store.totalPages) desiredPage = 0;
        this.updatePage(desiredPage);

        await this.store.fetchFilteredMediaIdsAndCount();
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { page } = params as any;
        return { page: parseInt(page) };
    }

    async updatePage(newPage: number) {
        if (!this.store) return;
        this.props.history.push(`/media/explore/${newPage}`);
        this.store.setCurrentPage(newPage);
        this.store.fetchMediaEntitiesForCurrentExplorePage();
    }

    generateMediaCardJsx() {
        if (!this.store || this.store.requestPending) return <Loader />;

        return this.store.currentMedia.map((media: Media) => {
            const isImage = mediaIsImage(media);

            return (
                <Card key={media.id}>
                    {isImage ? (
                        <S3Image
                            url={this.store!.getMediaUrl(media.bucketPath)}
                            wrapped={true}
                            ui={false}
                        />
                    ) : !this.store?.videosToPreview.includes(media.id) ? (
                        <Button
                            icon
                            labelPosition="left"
                            onClick={() => {
                                this.store?.updateVideosToPreview(media.id);
                            }}
                        >
                            <Icon name="play" />
                            Preview Video
                        </Button>
                    ) : (
                        <video autoPlay loop muted controls className="explorer-video">
                            <source src={this.store.getMediaUrl(media.bucketPath)} />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <Card.Content>
                        <Card.Header>
                            Media {media.id}
                            <Icon
                                name={isImage ? "image" : "video"}
                                color="grey"
                                style={{ float: "right" }}
                            />
                        </Card.Header>
                        <Card.Meta>
                            {media.sport}, {media.stadium}
                        </Card.Meta>
                        <Card.Meta style={{ fontStyle: "italic" }}>
                            {new Date(media.recordedAt).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                            })}
                        </Card.Meta>
                        <Grid>
                            <Grid.Column width={11}>
                                <Card.Description>
                                    <Header sub>State</Header>
                                    {media.state}
                                </Card.Description>
                                <Card.Description>{media.datasetType}</Card.Description>
                            </Grid.Column>
                            <Grid.Column>
                                <Button icon basic as={Link} to={`/media/view/${media.id}`}>
                                    <Icon name="external alternate" />
                                    View
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                </Card>
            );
        });
    }

    render() {
        let pagination = (
            <Pagination
                page={this.store?.currentPage || 0}
                maxPage={this.store?.totalPages || 0}
                updatePage={(page: number) => this.updatePage(page)}
            />
        );

        return (
            <Container className="main-content">
                <Header as="h1">Explore Media</Header>
                <MediaFilter />
                <Header as="h3">Number of media: {this.store?.filteredMediaCount || 0}</Header>
                <Grid>
                    <Grid.Row>{pagination}</Grid.Row>
                    <Grid.Row>
                        <Card.Group
                            itemsPerRow={this.gridColumns as SemanticWIDTHS}
                            style={{ width: "100%" }}
                        >
                            {this.generateMediaCardJsx()}
                        </Card.Group>
                    </Grid.Row>
                    <Grid.Row>{pagination}</Grid.Row>
                </Grid>
            </Container>
        );
    }
}
