import React from "react";
import { Button, Container, Modal } from "semantic-ui-react";

interface Props {
    trigger: any;
    description: string;
    onYes: Function;
    onNo: Function;
}

interface State {
    show: boolean;
}

export class ConfirmationPopUp extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { show: false };

        this.toggle = this.toggle.bind(this);
        this.confirmed = this.confirmed.bind(this);
    }

    toggle(show: boolean) {
        this.setState({ show });
    }

    confirmed(yes: boolean) {
        const { onYes, onNo } = this.props;

        this.toggle(false);
        yes ? onYes() : onNo();
    }

    render() {
        const { trigger, description } = this.props;

        return (
            <Modal
                onClose={() => this.toggle(false)}
                onOpen={() => this.toggle(true)}
                open={this.state.show}
                trigger={trigger}
                size="small"
            >
                <Modal.Header>Are You Sure?</Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Container text>
                            {description + ". Are you sure this is what you want to do?"}
                        </Container>
                        <p></p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.confirmed(false)}>
                        No!
                    </Button>
                    <Button
                        content="Yes, that's fine"
                        labelPosition="right"
                        icon="checkmark"
                        onClick={() => this.confirmed(true)}
                        negative
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
