import React from "react";

import { Grid, Button, Icon, Segment, SegmentGroup } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { Image as ImageModel, ImageState } from "../../../models/Image";
import { ModelType } from "../../../models/ModelType";
import {
    SkeletonAnnotationAnswer,
    Definition,
    Instance,
} from "../../../models/model_types/keypoints";
import { NewInstance } from "./NewInstance";
import { EditInstances } from "./EditInstances";
import { AnnotationInfo, pdfJSX } from "../../helpers/AnnotationInfo";
import { AppStore } from "../../../store/AppStore";

export enum Mode {
    NewInstance,
    Edit,
}

const LineIcons = ({ line_colour = "rgb(255, 0, 0)" }) => (
  <>
    <Icon name="minus" style={{color: line_colour}}/>
    <Icon name="minus"  style={{ marginLeft: "-8px", color: line_colour }} />
    <Icon name="minus"  style={{ marginLeft: "-8px", color: line_colour }} />
  </>
);
 
interface Props {
    image: ImageModel;
    modelType: ModelType;
    taskId?: string;
    verification: boolean;
    onSubmit: (
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean,
        isCrossValidation: boolean
    ) => void;
    onVerify: (approved: boolean, comment: string, discarded: boolean) => void;
    store: AppStore | undefined;
    checkCrossVal: boolean;
}

class State {
    answer: SkeletonAnnotationAnswer = { instances: [] };
    mode: Mode = Mode.Edit;
}

export class Annotation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { image } = this.props;
        let answer: SkeletonAnnotationAnswer = { instances: [] };

        if (image.annotations.length > 0) {
            answer = image.annotations[0].answerData;
        }

        this.state = { answer, mode: Mode.Edit };

        this.updatedInstances = this.updatedInstances.bind(this);
        this.enableNew = this.enableNew.bind(this);
    }

    async componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            const { image } = this.props;
            let answer: SkeletonAnnotationAnswer = { instances: [] };

            if (image.annotations.length > 0) {
                answer = image.annotations[0].answerData;
            }
            this.setState({ answer });
        }
    }

    updatedInstances(instances: Instance[], mode: Mode) {
        this.setState({ answer: { instances: instances }, mode });
    }

    enableNew() {
        this.setState({ mode: Mode.NewInstance });
    }

    render() {
        const definition = this.props.modelType.definition as Definition;
        const { image, modelType } = this.props;
        const { answer, mode } = this.state;
        const verification = this.props.verification;
        const ann = image.annotations[image.annotations.length - 1];

        let wizardJSX = <></>;
        if (mode === Mode.Edit) {
            wizardJSX = (
                <EditInstances
                    updatedInstances={this.updatedInstances}
                    definition={definition}
                    instances={answer.instances}
                    color="magenta"
                    bucketPath={image.bucketPath}
                    image={image}
                />
            );
        } else if (mode === Mode.NewInstance) {
            wizardJSX = (
                <NewInstance
                    updatedInstances={this.updatedInstances}
                    definition={definition.keypoints}
                    instances={answer.instances}
                    color="magenta"
                    bucketPath={image.bucketPath}
                />
            );
        }

        let redirectPath = verification
            ? `/model/${modelType.name}/verification`
            : `/model/${modelType.name}/annotation`;
        if (this.props.taskId != null) {
            redirectPath = verification
                ? `/model/${modelType.name}/verification/task/${this.props.taskId}`
                : `/model/${modelType.name}/annotation/task/${this.props.taskId}`;
        }

        let buttonsJSX = (
            <Link to={redirectPath}>
                <Button
                    color="green"
                    disabled={mode !== Mode.Edit}
                    onClick={() =>
                        this.props.onSubmit(
                            false,
                            this.state.answer,
                            ImageState.NeedsVerification,
                            false,
                            this.props.checkCrossVal
                        )
                    }
                    content={`Submit Annotation`}
                />
                <Button
                    disabled={mode === Mode.NewInstance}
                    content="Discard image"
                    labelPosition="left"
                    icon="trash"
                    color="red"
                    onClick={() =>
                        this.props.onSubmit(
                            true,
                            this.state.answer,
                            ImageState.NeedsVerification,
                            false,
                            this.props.checkCrossVal
                        )
                    }
                />
            </Link>
        );

        if (verification) {
            buttonsJSX = (
                <AnnotationInfo
                    approveButton={true}
                    ann={ann}
                    answer={answer}
                    onVerify={this.props.onVerify}
                    onSubmit={this.props.onSubmit}
                />
            );
        }

        return (
            <Grid>
                <Grid.Row>
                <Grid.Column width={10}>
                    <Button
                        color="blue"
                        disabled={mode === Mode.NewInstance}
                        onClick={this.enableNew}
                        content={`New ${definition.objectNameSingular}`}
                    />
                </Grid.Column>
                <Grid.Column width={5} floated="right">{pdfJSX(modelType)}</Grid.Column>
                </Grid.Row>
                {wizardJSX}

                    <Grid.Column width={10}>
                        {buttonsJSX}
                    </Grid.Column>
                    <Grid.Column width={5} floated="right">
                      {
                        <SegmentGroup>
                          <Segment textAlign="center">
                            {"Obscured    "}
                            <Icon name="circle" color="blue" />
                            {"   Visible   "}
                            <Icon name="circle" color="yellow" />
                            {"/ "}
                            <Icon name="circle" color="pink" />
                          </Segment>
                          <Segment textAlign="center">
                            {"Left    "}
                            <LineIcons line_colour="rgb(0, 0, 255)" />
                            {"   Right   "}
                            <LineIcons line_colour="rgb(255, 0, 0)" />
                            {"   Central"}
                            <LineIcons line_colour="rgb(0, 255, 0)" />
                          </Segment>
                        </SegmentGroup>
                      }
                    </Grid.Column>
            </Grid>
        );
    }
}
