import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Accordion, Button, Form, Icon, Input } from "semantic-ui-react";
import { MediaStore } from "../../store/MediaStore";
import { dropdownInputSelection, tagCheckboxSelection } from "../helpers/FilterInputComponents";
import { CameraType, getImageStates, ImageScanType, ImageStateUse } from "../../models/Image";

type Props = ProvidedAppStore;

export enum MediaFormats {
    Video = "video",
    Images = "images",
}

@inject("store")
@observer
export class MediaFilter extends React.Component<Props, {}> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
    }

    async componentDidMount() {
        if (!this.store) return;
        await this.store.fetchFilterOptions();
    }

    async handleChange(event: any, data: any) {
        if (!this.store) return;

        const { name, value } = data;
        const filterState = { ...this.store.filterState, [name]: value };
        this.store.updateFilterState(filterState);

        if (name === "addSports") {
            this.store.fetchFilterOptions();
        }
    }

    async handleCheckboxChange(event: any) {
        if (!this.store) return;

        let tags = [...this.store.filterState.tags];
        if (event.target.checked) {
            tags.push(event.target.value);
        } else {
            tags = tags.filter((tag) => tag !== event.target.value);
        }
        await this.handleChange(null, { name: "tags", value: tags });
    }

    async handleToggleChange(event: any) {
        if (!this.store) return;
        this.store.filterState.intersectTags = !this.store.filterState.intersectTags;
    }

    async fetchFilteredMedia() {
        if (!this.store) return;
        this.store.fetchFilteredMediaIdsAndCount();
    }

    isTagSelected(tag: string) {
        if (!this.store) return false;
        return this.store.filterState.tags.includes(tag);
    }

    render() {
        const store = this.props.store!.mediaStore;
        const { filterOpen, filterState } = store;

        return (
            <Accordion styled={true} fluid={true}>
                <Accordion.Title
                    active={filterOpen}
                    index={0}
                    onClick={() => {
                        store.toggleFilterOpen();
                    }}
                >
                    <Icon name="dropdown" />
                    Filter Media
                </Accordion.Title>
                <Accordion.Content active={filterOpen}>
                    <Form>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "mediaStates",
                                filterState.mediaStates,
                                getImageStates(ImageStateUse.Explorer),
                                (e, d) => this.handleChange(e, d),
                            )}
                            {dropdownInputSelection(
                                "addSports",
                                filterState.addSports,
                                store.filterOptions.availableSports,
                                (e, d) => this.handleChange(e, d),
                            )}
                            {dropdownInputSelection(
                                "addStadiums",
                                filterState.addStadiums,
                                store.filterOptions.availableStadiums,
                                (e, d) => this.handleChange(e, d),
                            )}
                        </Form.Group>
                        <Form.Group widths="equal">
                            {dropdownInputSelection(
                                "cameraTypes",
                                filterState.cameraTypes,
                                Object.keys(CameraType),
                                (e, d) => this.handleChange(e, d),
                            )}
                            {dropdownInputSelection(
                                "imageScanTypes",
                                filterState.imageScanTypes,
                                Object.keys(ImageScanType),
                                (e, d) => this.handleChange(e, d),
                            )}
                            {dropdownInputSelection(
                                "applications",
                                filterState.applications,
                                store.filterOptions.availableApplications,
                                (e, d) => this.handleChange(e, d),
                            )}
                        </Form.Group>
                        <Form.Group inline widths="equal">
                            {dropdownInputSelection(
                                "mediaFormats",
                                filterState.mediaFormats,
                                Object.keys(MediaFormats),
                                (e, d) => this.handleChange(e, d),
                            )}
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="startDate"
                                    name="startDate"
                                    label="Start Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState.startDate}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    type="date"
                                    id="endDate"
                                    name="endDate"
                                    label="End Date"
                                    onChange={(event: any, data: any) => {
                                        data["value"] = new Date(data["value"]);
                                        this.handleChange(event, data);
                                    }}
                                    defaultValue={filterState.endDate}
                                />
                            </Form.Field>
                        </Form.Group>
                        {tagCheckboxSelection(
                            store.filterOptions.availableTags,
                            (tag) => this.isTagSelected(tag),
                            (event) => this.handleCheckboxChange(event),
                        )}
                        <Button
                            toggle
                            onClick={(event) => this.handleToggleChange(event)}
                            active={filterState.intersectTags}
                        >
                            {filterState.intersectTags
                                ? "Tag selection: Intersection"
                                : "Tag selection: Union"}
                        </Button>
                    </Form>
                    <Button
                        loading={store.requestPending}
                        disabled={store.requestPending}
                        onClick={() => this.fetchFilteredMedia()}
                        fluid
                        color={"green"}
                        basic
                    >
                        Submit
                    </Button>
                </Accordion.Content>
            </Accordion>
        );
    }
}
