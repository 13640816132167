import React from "react";
import { Container, Menu } from "semantic-ui-react";
import { NavLink, Switch, RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { AdminRoute } from "../auth/AuthRoutes";
import { ProvidedAppStore } from "../../store/AppStore";

import { ModelsOverview } from "./ModelsOverview";
import { Users } from "./Users";
import { Metrics } from "./Metrics";
import { Tasks } from "./Tasks";
import { Trainings } from "./Trainings";
import { DataFiles } from "./DataFiles";
import { KeycloakRole } from "../../models/User";
import { MediaAnnotationTypesPage } from "./MediaAnnotationTypes";

const ACTIVE_USERS_INTERVAL = 60_000;

export const AdminPage = inject("store")(
    observer((props: RouteComponentProps & ProvidedAppStore) => {
        const interval = React.useRef(NaN);
        const { userAuthStore, adminStore } = props.store!;

        React.useEffect(() => {
            if (userAuthStore.user?.roles.includes(KeycloakRole.ADMIN)) {
                interval.current = window.setInterval(
                    adminStore.getActiveUsers,
                    ACTIVE_USERS_INTERVAL
                );
            }
            return () => {
                if (interval.current) {
                    window.clearInterval(interval.current);
                }
            };
        }, []);

        return (
            <Container className="main-content">
                <Menu secondary>
                    <Menu.Item as={NavLink} to={`/admin/tasks`}>
                        Tasks
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/trainings`}>
                        Training
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/users`}>
                        Users
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/files`}>
                        Files
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/models`}>
                        Models
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/metrics`}>
                        Metrics
                    </Menu.Item>
                    <Menu.Item as={NavLink} to={`/admin/annotation-types`}>
                        Media Annotation Types
                    </Menu.Item>
                </Menu>
                <Switch>
                    <AdminRoute path="/admin/tasks" component={Tasks} />
                    <AdminRoute path="/admin/trainings" component={Trainings} />
                    <AdminRoute path="/admin/users" component={Users} />
                    <AdminRoute path="/admin/files" component={DataFiles} />
                    <AdminRoute path="/admin/models" component={ModelsOverview} />
                    <AdminRoute path="/admin/metrics" component={Metrics} />
                    <AdminRoute
                        path="/admin/annotation-types"
                        component={MediaAnnotationTypesPage}
                    />
                </Switch>
            </Container>
        );
    })
);
