import { ModelType } from "./ModelType";
import { Prediction, Annotation } from "./Annotation";

export enum ImageState {
    Archived = "Archived",
    Unused = "Unused",
    Incoming = "Incoming",
    NeedsAnnotation = "NeedsAnnotation",
    NeedsVerification = "NeedsVerification",
    Verified = "Verified",
}

export enum ImageStateUse {
    All = "all",
    Regular = "regular",
    Explorer = "explorer",
    Stats = "stats",
}

export enum CameraType {
    Unknown = "Unknown",
    Broadcast = "Broadcast",
    Tracking = "Tracking",
    Synthetic = "Synthetic",
}

export enum ImageScanType {
    Unknown = "Unknown",
    Progressive = "Progressive",
    Interlaced = "Interlaced",
}

export enum DatasetType {
    NotSet = "NotSet",
    Train = "Train",
    Validation = "Validation",
    Test = "Test",
    PreTrain = "PreTrain",
    Query = "Query",
    Gallery = "Gallery",
}

interface ImageProperties {
    sport: string;
    stadium: string;
    cameraType: CameraType;
    imageScanType: ImageScanType;
    datasetType: DatasetType;
    inputDownsampleFactor: number;
    pixelSize: number;
    midHipPos: number[];
    upDir: number[];
}

export function getImageStates(type: any) {
    if (type === ImageStateUse.Regular)
        return Object.keys(ImageState).filter(
            (state) => state !== ImageState.Archived && state !== ImageState.Unused
        );

    if (type === ImageStateUse.Explorer)
        return Object.keys(ImageState).filter((state) => state !== ImageState.Archived);

    if (type === ImageStateUse.Stats)
        return Object.keys(ImageState).filter(
            (state) => state === ImageState.Archived || state === ImageState.Unused
        );

    if (type === ImageStateUse.All) return Object.keys(ImageState);

    console.log("Unsupported Image State Use");
    return [];
}

export interface Image extends ImageProperties {
    id: number;
    modelType: ModelType;
    predictions: Prediction[];
    bucketPath: string;
    otherFrameBucketPaths: string[];
    annotations: Annotation[];
    imageState: ImageState;
    application: string;
    uploadedAt: Date;
    recordedAt: Date;
    tags: ImageTag[];
}

export interface ImageTag {
    id: number;
    name: string;
    images: Image[];
    createdAt: Date;
}
