export enum QuestionType {
    Boolean = "boolean",
    String = "string",
    Choice = "choice",
}

export interface AnswerItem {
    type: QuestionType;
    bool?: boolean;
    string?: string;
    choice?: number;
}

export interface Question {
    question: string;
    help: string;
    type: QuestionType;
    choiceOptions: Array<string>;

    blankEntryPrompt?: string;
}

export type Answer = Array<AnswerItem>;

export interface Definition {
    questions: Array<Question>;
}

export interface DefinitionJSON extends Definition {
    type: "Classification";
    name: string;
    description: string;
    expectedTime: number;
    bucketName: string;
}
