import React from "react";
import { Table, Grid, Icon, Header } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";

import { ProvidedAppStore } from "../../store/AppStore";
import { BarChartWithOptions } from "./MetricsBarChart";
import { LineChartWithOptions } from "./MetricsLineChart";
import { KeycloakRole, User } from "../../models/User";
import { SessionLineChartWithOptions } from "./MetricsSessionLineChart";
import { OptionsModifier } from "./MetricsHelpers";

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class MetricsUserOverview extends React.Component<Props> {
    async componentDidMount() {
        await this.props.store!.adminStore.fetchUsers();
    }

    render() {
        const adminStore = this.props.store?.adminStore!;

        const tableRows = adminStore.users.map((user: User, idx: number) => {
            const url = `/admin/metrics/by-user/${user.username}`;
            const admin = user.roles?.some((item: KeycloakRole) => item === KeycloakRole.ADMIN) ? (
                <Icon name="star" color="yellow" title="Administrator" />
            ) : (
                <div />
            );

            return (
                <Table.Row
                    key={idx}
                    onClick={() => {
                        this.props.history.push(url);
                    }}
                >
                    <Table.Cell>{user.name}</Table.Cell>
                    <Table.Cell>{user.username}</Table.Cell>
                    <Table.Cell>{user.department}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{admin}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Table selectable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Department</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Admin</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{tableRows}</Table.Body>
            </Table>
        );
    }
}

@inject("store")
@observer
export class MetricsUser extends React.Component<Props> {
    componentDidMount(): void {
        if (!this.props.store!.adminStore.users?.length) {
            this.props.store!.adminStore.fetchUsers();
        }
    }
    render() {
        const { params } = this.props.match;
        const { user } = params as any;
        const currentUser = this.props.store!.adminStore.users?.find(
            (item) => item.username === user
        );

        return (
            <>
                <Header as="h2">
                    {user}
                    {currentUser && <Header.Subheader>{currentUser.name}</Header.Subheader>}
                    <Header.Subheader>User</Header.Subheader>
                </Header>
                <Grid>
                    <LineChartWithOptions
                        groupBys={["model", "verified"]}
                        filterBy={{ username: user }}
                    />
                    <BarChartWithOptions
                        groupBys={["model"]}
                        filterBy={{ username: user }}
                        modifier={OptionsModifier.ANNOTATIONS}
                    />
                    <BarChartWithOptions
                        groupBys={["model"]}
                        filterBy={{ username: user }}
                        modifier={OptionsModifier.VERIFICATIONS}
                    />
                    <SessionLineChartWithOptions filterBy={{ username: user }} />
                </Grid>
            </>
        );
    }
}
