export enum ReportType {
    BugFix = "Bug",
    UserExperience = "UserExperience",
}

export class Bug {
    id: number = 0;

    report: string = "";

    type: ReportType = ReportType.BugFix;

    votes: number = 0;
}
