export async function getFrameFromImage(url: string): Promise<ImageBitmap> {
    let image = new Image();
    image.src = url;
    await image.decode();
    return await createImageBitmap(image);
}

export async function getFramesFromVideoTrack(
    track: MediaStreamVideoTrack,
    takeEvery: number,
    keepFrames: number[]
): Promise<Map<number, ImageBitmap>> {
    const processor = new MediaStreamTrackProcessor({ track: track });
    const reader = processor.readable.getReader();

    let frames: Map<number, ImageBitmap> = new Map();
    let finished: boolean = false;

    let frameCount = 0;
    let lastFrame: VideoFrame;
    while (!finished) {
        await reader.read().then(async ({ done, value }) => {
            if (value) {
                if (frameCount % takeEvery === 0 || keepFrames.includes(frameCount)) {
                    const bitmap = await createImageBitmap(value);
                    frames.set(frameCount, bitmap);
                }
                if (lastFrame) lastFrame.close();
                lastFrame = value;
            }
            if (done) {
                finished = true;
                frames.set(frameCount - 1, await createImageBitmap(lastFrame));
                lastFrame.close();
            }
            frameCount++;
        });
    }
    return frames;
}

// note(Alex.Shaw): This is currently unavailable in Typescript, but is
// supported by all modern browsers (except Safari)
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream
interface HTMLVideoElementWithCaptureStream extends HTMLVideoElement {
    captureStream(): MediaStream;
}

export async function getVideoTrack(url: string, previewElement: HTMLVideoElement) {
    const video = previewElement as HTMLVideoElementWithCaptureStream;
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.src = url;
    await video.play();
    const [track] = video.captureStream().getVideoTracks();
    video.onended = (evt) => track.stop();
    return track;
}
