import React from "react";
import { Table, Grid, Icon, Input, Segment, Rail } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { KeycloakRole, User } from "../../models/User";
import { Profile } from "../helpers/Profile";

interface State {
    searchString: string;
    selectedUserId: number;
}

@inject("store")
@observer
export class Users extends React.Component<ProvidedAppStore, State> {
    constructor(props: ProvidedAppStore) {
        super(props);
        this.state = { searchString: "", selectedUserId: -1 };
        this.searchChange = this.searchChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    searchChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchString: event.target.value });
    }

    onClick(userId: number) {
        this.setState({ selectedUserId: userId });
    }

    async componentDidMount() {
        await this.props.store!.adminStore.getActiveUsers();
        await this.props.store!.adminStore.fetchUsers();
    }

    getRoleIcon(requiredRole: KeycloakRole, roles?: KeycloakRole[]) {
        if (roles?.some((item) => item === requiredRole)) {
            return <Icon name="check" color="green" title={requiredRole} />;
        }
        return null;
    }

    render() {
        const { adminStore } = this.props.store!;

        let users = adminStore.users.slice();
        const { activeUsers } = adminStore;

        users = users.filter((user: User) => {
            const searchString = this.state.searchString.toLowerCase();
            const name = user.name.toLowerCase();
            const email = user.email.toLowerCase();
            const department = user.department.toLowerCase();

            const values = [name, email, department];
            return values.some((value: string) => {
                return value.includes(searchString);
            });
        });

        const usersJSX = users.map((user: User) => {
            let activeJSX = <div />;

            if (activeUsers.some((item) => item === user.username)) {
                activeJSX = <Icon name="circle" color="green" />;
            }

            const annotatorRole = this.getRoleIcon(KeycloakRole.ANNOTATOR, user.roles);
            const verifierRole = this.getRoleIcon(KeycloakRole.VERIFIER, user.roles);
            const adminRole = this.getRoleIcon(KeycloakRole.ADMIN, user.roles);

            return (
                <Table.Row
                    key={user.id}
                    onClick={() => {
                        this.onClick(user.id);
                    }}
                >
                    <Table.Cell>{activeJSX}</Table.Cell>
                    <Table.Cell collapsing={true} className="text-break">{user.name}</Table.Cell>
                    <Table.Cell>{user.department}</Table.Cell>
                    <Table.Cell className="users-email">{user.email}</Table.Cell>
                    <Table.Cell>{annotatorRole}</Table.Cell>
                    <Table.Cell>{verifierRole}</Table.Cell>
                    <Table.Cell>{adminRole}</Table.Cell>
                </Table.Row>
            );
        });

        let userIdx = adminStore.users.findIndex((user: User) => {
            return user.id === this.state.selectedUserId;
        });
        const user = userIdx === -1 ? null : adminStore.users[userIdx];
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Input
                            icon="search"
                            placeholder="Search..."
                            fluid={true}
                            onChange={this.searchChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Segment>
                            <Table compact selectable={true} width={12}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Department</Table.HeaderCell>
                                        <Table.HeaderCell>Email</Table.HeaderCell>
                                        <Table.HeaderCell>Annotator</Table.HeaderCell>
                                        <Table.HeaderCell>Verifier</Table.HeaderCell>
                                        <Table.HeaderCell>Admin</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{usersJSX}</Table.Body>
                            </Table>
                        </Segment>
                        <Rail attached position="right">
                            <Segment>{String(activeUsers.length) + " Active Users"}</Segment>
                            <Segment>
                                <Profile
                                    user={user}
                                    onSubmit={adminStore.changeUser}
                                    canChangeUserType={true}
                                    withDeactivateUser
                                />
                            </Segment>
                        </Rail>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
