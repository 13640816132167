import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import {
    AnnotationCategories,
    KeypointAnnotationTypes,
    MediaStore,
    ObjectDetectionAnnotationTypes,
    TopLevelAnnotationTypes,
} from "../../../store/MediaStore";
import { inject, observer } from "mobx-react";
import {
    Accordion,
    Button,
    Container,
    Divider,
    Header,
    Icon,
    Loader,
    Popup,
} from "semantic-ui-react";

import { Instance as KeypointInstance } from "../../../models/model_types/keypoints";
import {
    objectColor,
    ObjectDetectionDescription,
    Instance as ObjectDetectionInstance,
} from "../../../models/model_types/object_detection";
import { Mode } from "../MediaAnnotation";

// TODO: Add mode, focus etc
interface MediaAnnotationControlsProps {
    currentTimestep: number;
    mode: Mode;
    createObjectDetectionInstance: CallableFunction;
    objectDescription: ObjectDetectionDescription;
}

type Props = ProvidedAppStore & MediaAnnotationControlsProps;

@inject("store")
@observer
export class AnnotationControls extends React.Component<Props, {}> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {};
    }

    generateObjectDetectionNewInstanceButtonsJsx() {
        if (this.store?.annotationTypes.length == 0) return <Loader active />;

        const annotationType = this.store!.annotationTypes.filter(
            (type) =>
                type.category === AnnotationCategories.ObjectDetection && type.type === "Generic"
        )[0];

        const newInstanceButtonsJsx = (
            annotationType.definition as ObjectDetectionDescription[]
        ).map((object) => {
            return (
                <Button
                    key={object.label}
                    color={objectColor(object)}
                    content={object.label}
                    style={{ marginBottom: "4px" }}
                    onClick={() => this.props.createObjectDetectionInstance(object)}
                />
            );
        });

        return (
            <>
                <Header as="h3">Add new instance:</Header>
                {newInstanceButtonsJsx}
            </>
        );
    }

    render() {
        if (this.props.mode === Mode.Overview) {
            return this.generateObjectDetectionNewInstanceButtonsJsx();
        }

        if (this.props.mode === Mode.NewInstance) {
            return (
                <Header as="h4">Adding new instance: {this.props.objectDescription.label}</Header>
            );
        }
    }
}
