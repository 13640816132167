import React from "react";
import { inject } from "mobx-react";

import { Table, Segment } from "semantic-ui-react";
import { Image } from "../../models/Image";
import { Answer } from "../../models/model_types/metric";
import { S3Image } from "../helpers/S3Image";
import { ProvidedAppStore } from "../../store/AppStore";

import { fnGenerateVerifiedByJSX } from "../helpers/AnnotationInfo";
import { TeamMetricsKits } from "../../models/TeamMetrics";

interface MetricExplorerProps {
    image: Image;
}
type Props = MetricExplorerProps & ProvidedAppStore;

interface State {
    kit :  TeamMetricsKits | null;
}

@inject("store")
export class MetricExplorer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state  = {kit : null};
    }


    async componentDidMount() {
        const { annotations } = this.props.image;
        if (annotations.length !== 0) {
            const lastAnnotation = annotations[annotations.length - 1];
            const answer = lastAnnotation.answerData as Answer;
            const kitId = answer[0].teamId
            console.log(kitId)
            let kit = kitId !== undefined ? await this.props.store?.imageStore.getKitByTeamId(kitId) : null; 
            this.setState({kit: kit})
        }
    }



    renderSegmentInfo(timeFormat: any, teamId: any, kit:any) {
        return (
            <div>
                <S3Image size="large" url={this.props.store!.hwkflowClient.getImageUrl( this.props.image.bucketPath)} />
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Image</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.image.id}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Sport:</Table.Cell>
                            <Table.Cell>{this.props.image.sport}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Stadium:</Table.Cell>
                            <Table.Cell>{this.props.image.stadium}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Date Uploaded:</Table.Cell>
                            <Table.Cell>{new Date(this.props.image.uploadedAt).toLocaleString( "en-US", timeFormat)}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Dataset Type: </Table.Cell>
                            <Table.Cell>{this.props.image.datasetType}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Team id :</Table.Cell>
                            <Table.Cell>{teamId}</Table.Cell>
                        </Table.Row>

                        {kit && ( 
                            <><Table.Row>
                                <Table.Cell>Jersey Color :</Table.Cell>
                                <Table.Cell>{kit.jerseyColors.join(', ')}</Table.Cell>
                            </Table.Row><Table.Row>
                                    <Table.Cell>Short Color :</Table.Cell>
                                    <Table.Cell>{kit.shortColor}</Table.Cell>
                                </Table.Row><Table.Row>
                                    <Table.Cell>Socks Color:</Table.Cell>
                                    <Table.Cell>{kit.socksColor}</Table.Cell>
                                </Table.Row></>
                        )}
                    </Table.Body>
                </Table>
            </div>
        )
    }


    render() {
        let timeFormat = { day: "numeric", month: "long", year: "numeric" };

        const { annotations } = this.props.image;
        const {kit} = this.state; 

        if (annotations.length === 0) {
            return (<Segment compact>{this.renderSegmentInfo(timeFormat, 'Not Annotated', kit)} </Segment>);
        }

        const lastAnnotation = annotations[annotations.length - 1];
        const answer = lastAnnotation.answerData as Answer;
        
        
        let verifiedByJSX = <div />;

        if (lastAnnotation.verifier) {
            verifiedByJSX = fnGenerateVerifiedByJSX(lastAnnotation);
        }

        return (
            <Segment compact>
                {verifiedByJSX}
                {this.renderSegmentInfo(timeFormat, answer[0].teamId, kit)}
            </Segment>
        );
    }
}
