import React from "react";

import { Menu, Icon } from "semantic-ui-react";

interface PaginationProps {
    page: number;
    maxPage: number;
    updatePage: (page: number) => void;
}

export class Pagination extends React.Component<PaginationProps> {
    onClick(idx: number) {
        idx = Math.max(0, Math.min(idx, this.props.maxPage - 1));

        if (idx !== this.props.page) {
            this.props.updatePage(idx);
        }
    }

    render() {
        const page = this.props.page;
        const barSize = Math.min(7, this.props.maxPage);
        const barOffset = Math.floor(barSize / 2);
        const minPage = Math.max(0, Math.min(page - barOffset, this.props.maxPage - barSize));
        const maxPage = minPage + barSize;

        let menuItems = [];
        for (let idx = minPage; idx < maxPage; ++idx) {
            menuItems.push(
                <Menu.Item key={idx} as="a" active={page === idx} onClick={() => this.onClick(idx)}>
                    {idx}
                </Menu.Item>
            );
        }

        return (
            <Menu floated="right" pagination>
                <Menu.Item as="a" icon onClick={() => this.onClick(0)}>
                    <Icon name="angle double left" />
                </Menu.Item>
                <Menu.Item as="a" icon onClick={() => this.onClick(page - 1)}>
                    <Icon name="chevron left" />
                </Menu.Item>
                {menuItems}
                <Menu.Item as="a" icon onClick={() => this.onClick(page + 1)}>
                    <Icon name="chevron right" />
                </Menu.Item>
                <Menu.Item as="a" icon onClick={() => this.onClick(this.props.maxPage - 1)}>
                    <Icon name="angle double right" />
                </Menu.Item>
            </Menu>
        );
    }
}
