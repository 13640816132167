import React from "react";
import { Table, Grid, Header } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";

import { ProvidedAppStore } from "../../store/AppStore";
import { BarChartWithOptions } from "./MetricsBarChart";
import { LineChartWithOptions } from "./MetricsLineChart";
import { departments } from "../../models/User";
import { SessionLineChartWithOptions } from "./MetricsSessionLineChart";
import { OptionsModifier } from "./MetricsHelpers";

type Props = ProvidedAppStore & RouteComponentProps;

@inject("store")
@observer
export class MetricsDepartmentOverview extends React.Component<Props> {
    render() {
        const tableRows = departments.map((department: string, idx: number) => {
            const url = `/admin/metrics/by-department/${department}`;

            return (
                <Table.Row
                    key={idx}
                    onClick={() => {
                        this.props.history.push(url);
                    }}
                >
                    <Table.Cell>{department}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Table selectable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Department</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{tableRows}</Table.Body>
            </Table>
        );
    }
}

@inject("store")
@observer
export class MetricsDepartment extends React.Component<Props> {
    render() {
        const { params } = this.props.match;
        const { department } = params as any;

        return (
            <>
                <Header as="h2">
                    {department}
                    <Header.Subheader>Department</Header.Subheader>
                </Header>
                <Grid>
                    <LineChartWithOptions
                        groupBys={["username", "model", "verified"]}
                        filterBy={{ department }}
                    />
                    <BarChartWithOptions
                        groupBys={["username", "model"]}
                        filterBy={{ department }}
                        modifier={OptionsModifier.ANNOTATIONS}
                    />
                    <SessionLineChartWithOptions filterBy={{ department }} />
                </Grid>
            </>
        );
    }
}
