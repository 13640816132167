import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import {
    AnnotationCategories,
    KeypointAnnotationTypes,
    MediaStore,
    ObjectDetectionAnnotationTypes,
    TopLevelAnnotationTypes,
} from "../../../store/MediaStore";
import { inject, observer } from "mobx-react";
import {
    Accordion,
    Button,
    Container,
    Divider,
    Header,
    Icon,
    Loader,
    Popup,
} from "semantic-ui-react";

import { Instance as KeypointInstance } from "../../../models/model_types/keypoints";
import { Instance as ObjectDetectionInstance } from "../../../models/model_types/object_detection";
import { Mode } from "../MediaAnnotation";

interface HierarchyProps {
    objectDetectionInstances: Record<ObjectDetectionAnnotationTypes, ObjectDetectionInstance[]>;
    keypointInstances: Record<KeypointAnnotationTypes, KeypointInstance[]>;
    mode: Mode;
}

interface MediaAnnotationHierarchyState {
    objectDetectionGenericInstances: ObjectDetectionInstance[];
    keypointHumansInstances: KeypointInstance[];
    activeAccordionIndicies: Record<TopLevelAnnotationTypes, number>;
}

type Props = ProvidedAppStore & HierarchyProps;

@inject("store")
@observer
export class Hierarchy extends React.Component<Props, MediaAnnotationHierarchyState> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            objectDetectionGenericInstances: this.props.objectDetectionInstances.Generic,
            keypointHumansInstances: this.props.keypointInstances.Humans,
            activeAccordionIndicies: { ObjectDetection: -1 },
        };
    }

    async componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<MediaAnnotationHierarchyState>,
        snapshot?: any
    ) {
        if (
            JSON.stringify(this.props.objectDetectionInstances) !=
            JSON.stringify(prevProps.objectDetectionInstances)
        ) {
            this.setState({
                objectDetectionGenericInstances: this.props.objectDetectionInstances.Generic,
            });
        }

        if (
            JSON.stringify(this.props.keypointInstances) !=
            JSON.stringify(prevProps.keypointInstances)
        ) {
            this.setState({ keypointHumansInstances: this.props.keypointInstances.Humans });
        }
    }

    // TODO: Add view/selection logic, new annotation logic and delete logic
    generateInstanceCard(key: React.Key, label: string, childCategory?: AnnotationCategories) {
        return (
            <Container
                key={key}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                className="media-annotation-hierarchy-instance"
            >
                {label}
                {childCategory && <Icon name="dropdown" />}
                <Container style={{ display: "flex", justifyContent: "flex-end" }}>
                    {childCategory && (
                        <Popup
                            trigger={<Button positive icon="add" />}
                            on="hover"
                            content={`Add ${childCategory}`}
                        />
                    )}
                    <Button negative icon="delete" />
                    <Button icon="pencil" />
                </Container>
            </Container>
        );
    }

    render() {
        if (!this.store?.annotationTypes || this.store.annotationTypes.length === 0)
            return <Loader active />;

        if (this.state.objectDetectionGenericInstances.length === 0)
            return <Header sub>No Object Annotations</Header>;

        const accordionJsx = this.state.objectDetectionGenericInstances.map(
            (objectInstance, index) => {
                if (objectInstance.label != "person")
                    return this.generateInstanceCard(index, objectInstance.label);

                // note(Alex.Shaw): Person annotations may have keypoints associated
                // with them, so we return an accordion instead of a single instance here.
                const keypointInstances = this.state.keypointHumansInstances.filter(
                    (keypointInstance) =>
                        keypointInstance.parentInstance === objectInstance.instanceId
                );

                return (
                    <Accordion key={index}>
                        <Accordion.Title
                            style={{ padding: 0 }}
                            active={
                                this.state.activeAccordionIndicies[
                                    AnnotationCategories.ObjectDetection
                                ] === index
                            }
                            onClick={() => {
                                this.setState({
                                    activeAccordionIndicies: {
                                        ...this.state.activeAccordionIndicies,
                                        ObjectDetection: index,
                                    },
                                });
                            }}
                        >
                            {this.generateInstanceCard(
                                index,
                                objectInstance.label,
                                AnnotationCategories.Keypoints
                            )}
                        </Accordion.Title>
                        <Accordion.Content
                            active={
                                this.state.activeAccordionIndicies[
                                    AnnotationCategories.ObjectDetection
                                ] === index
                            }
                            style={{ marginLeft: "16px" }}
                        >
                            <Header as="h4">Human Keypoints</Header>
                            {keypointInstances.map((keypointInstance, kpIndex) =>
                                this.generateInstanceCard(`${index}_${kpIndex}`, "Skeleton")
                            )}
                        </Accordion.Content>
                    </Accordion>
                );
            }
        );

        return (
            <>
                <Header as="h4">Object Detection:</Header>
                {accordionJsx}
            </>
        );
    }
}
