import React from "react";
import { Menu, Table, Button, Container } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { ModelTypes, ModelType } from "../../models/ModelType";

@inject("store")
@observer
export class ModelsOverview extends React.Component<ProvidedAppStore> {
    async seedTaskTypes() {
        await this.props.store!.modelTypesStore.seedTypes();
    }

    render() {
        const { modelTypesStore } = this.props.store!;
        const modelTypes = modelTypesStore.modelTypes;

        const modelTypesRows = modelTypes.map((modelType: ModelType) => {
            return (
                <Table.Row key={modelType.id}>
                    <Table.Cell collapsing={true}>{modelType.id}</Table.Cell>
                    <Table.Cell>{modelType.name}</Table.Cell>
                    <Table.Cell>{ModelTypes[modelType.type]}</Table.Cell>
                    <Table.Cell>{modelType.description}</Table.Cell>
                </Table.Row>
            );
        });

        return (
            <Container>
                <Menu secondary={true}>
                    <Menu.Item position="right">
                        <Button positive onClick={() => this.seedTaskTypes()}>
                            Import definitions
                        </Button>
                    </Menu.Item>
                </Menu>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Problem Type</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{modelTypesRows}</Table.Body>
                </Table>
            </Container>
        );
    }
}
