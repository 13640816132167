export const colours = ["#7BFAA0", "#5E85DB", "#F252DA", "#DB8E40", "#E2FF4A"];

export function timeOptions(now: Date, since: string) {
    const sinceOptions = [
        {
            key: "Yesterday",
            to: now,
            since: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
        },
        {
            key: "Last Week",
            to: now,
            since: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
        },
        {
            key: "This Month",
            to: now,
            since: new Date(now.getFullYear(), now.getMonth(), 1),
        },
        {
            key: "Last Month",
            to: new Date(now.getFullYear(), now.getMonth(), 1),
            since: new Date(now.getFullYear(), now.getMonth() - 1, 1),
        },
        {
            key: "Last Three Months",
            to: new Date(now.getFullYear(), now.getMonth(), 1),
            since: new Date(now.getFullYear(), now.getMonth() - 3, 1),
        },
        // note(alex.shaw): Temporarily removed as 12 months of data is too much for the FE. See MLT-1996
        // {
        //     key: "Last Six Months",
        //     to: new Date(now.getFullYear(), now.getMonth(), 1),
        //     since: new Date(now.getFullYear(), now.getMonth() - 6, 1),
        // },
        // {
        //     key: "Last Year",
        //     to: now,
        //     since: new Date(now.getFullYear() - 1, 0, 0),
        // },
    ];

    const sinceDropDown = sinceOptions.map((option) => {
        return {
            key: option.key,
            text: option.key,
            value: option.key,
        };
    });

    const selectedTime = sinceOptions.find((option) => option.key === since);

    return { sinceDropDown, selectedTime };
}

export interface ChartWithOptionsProps {
    groupBys: string[];
    filterBy: { [keyName: string]: string };
    modifier?: OptionsModifier;
}

export enum OptionsModifier {
    ANNOTATIONS = "Annotations",
    VERIFICATIONS = "Verifications",
}

export interface SessionChartWithOptionsProps {
    filterBy: { [keyName: string]: string };
}
