import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { AppStore } from "../../store/AppStore";
import { KeycloakRole } from "../../models/User";

// permission without any role
export const PrivateRoute = inject("store")(
    observer(({ component: Component, layout: Layout, ...rest }: any) => {
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (Layout) {
                        return (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        );
                    } else {
                        return <Component {...props} />;
                    }
                }}
            />
        );
    })
);

// permission with a role annotator or more
export const StandardRoute = inject("store")(
    observer(({ component: Component, layout: Layout, ...rest }: any) => {
        const { userAuthStore } = rest.store as AppStore;
        const { appError, user, authError } = userAuthStore;
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (appError && !user) {
                        return <Redirect to="/failed" />;
                    }
                    if (
                        (authError && !user) ||
                        (user && !user.roles?.find((item) => item === KeycloakRole.ANNOTATOR))
                    ) {
                        return <Redirect to="/failed-restricted" />;
                    }

                    if (Layout) {
                        return (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        );
                    } else {
                        return <Component {...props} />;
                    }
                }}
            />
        );
    })
);

// permission with a role admin
export const AdminRoute = inject("store")(
    observer(({ component: Component, layout: Layout, ...rest }: any) => {
        const { userAuthStore } = rest.store as AppStore;
        const { appError, user, authError } = userAuthStore;
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (appError && !user) {
                        return <Redirect to="/failed" />;
                    }
                    if (
                        (authError && !user) ||
                        (user && !user.roles?.find((item) => item === KeycloakRole.ADMIN))
                    ) {
                        return <Redirect to="/failed-restricted" />;
                    }

                    if (Layout) {
                        return (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        );
                    } else {
                        return <Component {...props} />;
                    }
                }}
            />
        );
    })
);

// permission with a role verifier or more
export const VerifierRoute = inject("store")(
    observer(({ component: Component, layout: Layout, ...rest }: any) => {
        const { userAuthStore } = rest.store as AppStore;
        const { appError, user, authError } = userAuthStore;
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (appError && !user) {
                        return <Redirect to="/failed" />;
                    }
                    if (
                        (authError && !user) ||
                        (user && !user.roles?.find((item) => item === KeycloakRole.VERIFIER))
                    ) {
                        return <Redirect to="/failed-restricted" />;
                    }

                    if (Layout) {
                        return (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        );
                    } else {
                        return <Component {...props} />;
                    }
                }}
            />
        );
    })
);
