import React from "react";
import { Menu, Table, Button, Container } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { AnnotationType, MediaStore } from "../../store/MediaStore";

@inject("store")
@observer
export class MediaAnnotationTypesPage extends React.Component<ProvidedAppStore, {}> {
    store: MediaStore | undefined;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.store = props.store?.mediaStore;
    }

    componentDidMount(): void {
        this.store!.fetchAnnotationTypes();
    }

    async importDefinitions() {
        this.store!.importAnnotationTypes().then(() => this.store!.fetchAnnotationTypes());
    }

    render() {
        const annotationTypes = this.store!.annotationTypes.map(
            (annotationType: AnnotationType) => {
                return (
                    <Table.Row key={annotationType.id}>
                        <Table.Cell collapsing={true}>{annotationType.id}</Table.Cell>
                        <Table.Cell>{annotationType.category}</Table.Cell>
                        <Table.Cell>{annotationType.type}</Table.Cell>
                        <Table.Cell>{annotationType.description}</Table.Cell>
                        <Table.Cell>{annotationType.expectedTime} seconds</Table.Cell>
                    </Table.Row>
                );
            }
        );
        return (
            <Container>
                <Menu secondary={true}>
                    <Menu.Item position="right">
                        <Button positive onClick={() => this.importDefinitions()}>
                            Import definitions
                        </Button>
                    </Menu.Item>
                </Menu>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Expected Time</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{annotationTypes}</Table.Body>
                </Table>
            </Container>
        );
    }
}
