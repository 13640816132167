import { inject, observer } from "mobx-react";
import React from "react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Media, MediaStore } from "../../store/MediaStore";
import {
    Accordion,
    Button,
    Container,
    Dropdown,
    Form,
    FormGroup,
    FormInput,
    FormSelect,
    Grid,
    Header,
    Icon,
    Label,
    Message,
} from "semantic-ui-react";
import { CameraType, DatasetType, ImageScanType } from "../../models/Image";

interface MediaInfoProps {
    media: Media;
    reloadMedia: Function;
}

type Props = ProvidedAppStore & MediaInfoProps;

interface MediaInfoState extends Media {
    tagAccordionOpen: boolean;
    edittingProperties: boolean;
    showSaveSuccessful: boolean;
    saveSuccessful?: boolean;
}

@inject("store")
@observer
export class MediaInfoPanel extends React.Component<Props, MediaInfoState> {
    store: MediaStore | undefined;
    timeFormat = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            ...this.props.media,
            tagAccordionOpen: false,
            edittingProperties: false,
            showSaveSuccessful: false,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<MediaInfoState>,
        snapshot?: any
    ): void {
        if (
            prevProps.media.id !== this.props.media.id ||
            prevProps.media.state !== this.props.media.state
        ) {
            this.setState({ ...prevState, ...this.props.media });
        }
    }

    saveProperties() {
        if (!this.store) return;

        this.store
            .updateMedia({
                id: this.state.id,
                sport: this.state.sport,
                stadium: this.state.stadium,
                cameraType: this.state.cameraType,
                imageScanType: this.state.imageScanType,
                datasetType: this.state.datasetType,
                application: this.state.application,
                tags: this.state.tags,
            })
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ saveSuccessful: false });
                } else {
                    this.setState({ saveSuccessful: true });
                }
                this.props.reloadMedia();
            })
            .catch((err) => this.setState({ saveSuccessful: false }));

        this.setState({ ...this.state, edittingProperties: false, showSaveSuccessful: true });
        setTimeout(() => this.setState({ showSaveSuccessful: false }), 5000);
    }

    enableEditting() {
        this.store?.fetchAllSports();
        this.store?.fetchAllTags();
        this.setState({ edittingProperties: true });
    }

    cancelEditting() {
        this.setState({ ...this.props.media, edittingProperties: false });
    }

    generateViewJsx() {
        return (
            <>
                <Header as="h3">State: {this.state.state}</Header>
                <Header as="h4">Dataset Type: {this.state.datasetType}</Header>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header sub>Sport:</Header>
                            {this.state.sport}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header sub>Stadium:</Header>
                            {this.state.stadium}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header sub>Uploaded:</Header>
                            {new Date(this.state.uploadedAt).toLocaleString(
                                "en-GB",
                                this.timeFormat as any
                            )}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header sub>Recorded:</Header>
                            {new Date(this.state.recordedAt).toLocaleString(
                                "en-GB",
                                this.timeFormat as any
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header sub>Application:</Header>
                            {this.state.application}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header sub>Camera Type:</Header>
                            {this.state.cameraType}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Header sub>Image Scan Type:</Header>
                            {this.state.imageScanType}
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header sub>Input Downsample Factor:</Header>
                            {this.state.inputDownsampleFactor}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Accordion style={{ marginBottom: "16px" }}>
                    <Accordion.Title
                        active={this.state.tagAccordionOpen}
                        index={0}
                        onClick={() => {
                            this.setState({ tagAccordionOpen: !this.state.tagAccordionOpen });
                        }}
                    >
                        <Icon name="dropdown" />
                        {this.state.tags.length} Tags
                    </Accordion.Title>
                    <Accordion.Content active={this.state.tagAccordionOpen}>
                        {this.state.tags.map((tag) => (
                            <Label key={tag}>{tag}</Label>
                        ))}
                    </Accordion.Content>
                </Accordion>
                <Container style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => {
                            this.enableEditting();
                        }}
                    >
                        <Icon name="edit" />
                        Edit Properties
                    </Button>
                </Container>
            </>
        );
    }

    generateDropdownItems<T>(items: T[]) {
        if (items.length === 0) return [];

        return items.map((i) => {
            return {
                key: i,
                text: i,
                value: i,
            };
        });
    }

    generateEditJsx() {
        return (
            <Form>
                <FormGroup>
                    <FormSelect
                        label="Dataset Type"
                        options={this.generateDropdownItems(Object.keys(DatasetType))}
                        placeholder={this.state.datasetType}
                        value={this.state.datasetType}
                        onChange={(e, value) =>
                            this.setState({
                                datasetType: DatasetType[value.value! as keyof typeof DatasetType],
                            })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <FormSelect
                        search
                        label="Sports"
                        options={this.generateDropdownItems(this.store!.allSports)}
                        placeholder={this.state.sport}
                        value={this.state.sport}
                        onChange={(e, value) =>
                            this.setState({
                                sport: value.value! as string,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <FormInput
                        label="Stadium"
                        placeholder={this.state.stadium}
                        value={this.state.stadium}
                        onChange={(e, value) => {
                            this.setState({
                                stadium: value.value! as string,
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <FormInput
                        label="Application"
                        placeholder={this.state.application}
                        value={this.state.application}
                        onChange={(e, value) => {
                            this.setState({
                                application: value.value! as string,
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <FormSelect
                        label="Camera Type"
                        options={this.generateDropdownItems(Object.keys(CameraType))}
                        placeholder={this.state.cameraType}
                        value={this.state.cameraType}
                        onChange={(e, value) =>
                            this.setState({
                                cameraType: CameraType[value.value! as keyof typeof CameraType],
                            })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <FormSelect
                        label="Image Scan Type"
                        options={this.generateDropdownItems(Object.keys(ImageScanType))}
                        placeholder={this.state.imageScanType}
                        value={this.state.imageScanType}
                        onChange={(e, value) =>
                            this.setState({
                                imageScanType:
                                    ImageScanType[value.value! as keyof typeof ImageScanType],
                            })
                        }
                    />
                </FormGroup>
                <Header as="h5">Tags</Header>
                <FormGroup>
                    <Dropdown
                        multiple
                        selection
                        search
                        options={this.generateDropdownItems(this.store!.allTags)}
                        value={this.state.tags}
                        onChange={(e, value) => this.setState({ tags: value.value as string[] })}
                    />
                </FormGroup>
                <Button basic onClick={() => this.cancelEditting()}>
                    <Icon name="cancel" />
                    Cancel
                </Button>
                <Button
                    floated="right"
                    onClick={() => this.saveProperties()}
                    disabled={!this.store}
                >
                    <Icon name="save" />
                    Save
                </Button>
            </Form>
        );
    }

    render() {
        return (
            <Container>
                {this.state.showSaveSuccessful &&
                    (this.state.saveSuccessful ? (
                        <Message positive>Saved successfully</Message>
                    ) : (
                        <Message negative>Saving failed</Message>
                    ))}
                <Header as="h2">Metadata</Header>
                {this.state.edittingProperties ? this.generateEditJsx() : this.generateViewJsx()}
            </Container>
        );
    }
}
