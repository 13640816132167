import { observable, action, makeAutoObservable, runInAction } from "mobx";

import { ModelType } from "../models/ModelType";
import { ImageState, ImageStateUse } from "../models/Image";
import { HwkFlowApiClient } from "./Client";

export interface StatsKey {
    sport: string;
    stadium: string;
    imageState: string;
    application: string;
}

export interface StatsData {
    key: StatsKey;
    count: number;
}

export class ModelStore {
    controller: AbortController = new AbortController();
    client: HwkFlowApiClient;
    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    @observable
    public generated: boolean = false;

    @observable
    public modelType?: ModelType;

    @observable
    public statistics: StatsData[] = [];

    @observable
    public sports: string[] = [];

    @observable
    public imageStates: string[] = [];

    @observable
    public stadiums: string[] = [];

    @observable
    public applications: string[] = [];

    @observable
    public tags: string[] = [];

    @action
    public async fetchTags(sports?: string[]) {
        if (this.modelType == null) {
            return;
        }

        await this.client
            .fetchTags(this.controller.signal, this.modelType.id, sports)
            .then((tags) => runInAction(() => (this.tags = tags.data)));
    }

    @action
    public async fetchStatistics(imageStateUse: ImageStateUse) {
        if (this.modelType == null) {
            return;
        }

        this.fetchTags();
        this.client
            .fetchModelStatistics(this.modelType.name, imageStateUse, this.controller.signal)
            .then((response) => {
                runInAction(() => {
                    this.statistics = response.data["data"];
                    this.sports = Array.from(
                        new Set(
                            this.statistics.map((elem: StatsData) => {
                                return elem.key.sport;
                            })
                        )
                    ).sort();
                    this.stadiums = Array.from(
                        new Set(
                            this.statistics.map((elem: StatsData) => {
                                return elem.key.stadium;
                            })
                        )
                    ).sort();
                    this.imageStates = Array.from(
                        new Set(
                            this.statistics.map((elem: StatsData) => {
                                return elem.key.imageState;
                            })
                        )
                    );
                    this.applications = Array.from(
                        new Set(
                            this.statistics.map((elem: StatsData) => {
                                return elem.key.application;
                            })
                        )
                    ).sort();
                    this.generated = true;
                });
            });
    }

    @action
    public async setModelType(model_type: string) {
        if (model_type === this.modelType?.name) {
            return;
        }
        const response = await this.client.fetchModelType(model_type);
        runInAction(() => {
            this.modelType = response.data;
            this.generated = false;
        });
    }

    @action
    public async discoverImages() {
        if (this.modelType == null) {
            alert("cant discover images on invalid model type!");
            return;
        }
        await this.client.discoverImages(this.modelType.name);
    }

    @action
    public async pruneImages() {
        if (this.modelType == null) {
            alert("cant prune images on invalid model type!");
            return;
        }
        await this.client.pruneImages(this.modelType.name);
    }

    @action
    public async moveImages(
        sports: string[],
        stadiums: string[],
        state_strings: string[],
        destination_string: string,
        amount: number
    ) {
        if (this.modelType == null) {
            alert("cant move images of invalid model type!");
            return;
        }

        let limit = undefined;

        if (amount >= 0) {
            limit = amount;
        }

        const destination = destination_string as ImageState;
        await this.client.moveImages(this.modelType.name, sports, stadiums, [], [], state_strings, {
            destination: destination,
            limit: limit,
        });
    }
}
