import React from "react";
import { inject, observer } from "mobx-react";
import { HorizontalBar } from "react-chartjs-2";
import { Grid, Dropdown } from "semantic-ui-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { StatsKey, StatsData } from "../../store/AdminStore";
import { ChartWithOptionsProps, timeOptions, colours, OptionsModifier } from "./MetricsHelpers";

interface BarChartProps {
    groupBy: string;
    filterBy: { [keyName: string]: string };
    since: Date;
    to: Date;
    measureBy: string;
    measureText: string;
    modifier: OptionsModifier;
}

type ChartProps = ProvidedAppStore & BarChartProps;

@inject("store")
@observer
class BarChart extends React.Component<ChartProps> {
    render() {
        const adminStore = this.props.store!.adminStore;
        let data =
            this.props.modifier === OptionsModifier.VERIFICATIONS
                ? adminStore.statisticsVerifications.slice()
                : adminStore.statisticsAnnotations.slice();
        data = data.filter((elem: StatsData) => {
            return Object.entries(this.props.filterBy).every((filterElem) => {
                const key = filterElem[0] as keyof StatsKey;

                const dateOfCreation = new Date(elem.key.dateOfCreation);
                const timeSinceSince = dateOfCreation.getTime() - this.props.since.getTime();
                const goodSince = timeSinceSince >= 0;
                const timeSinceTo = dateOfCreation.getTime() - this.props.to.getTime();
                const goodTo = timeSinceTo < 0;

                const goodFilterBy = filterElem[1] === "All" || elem.key[key] === filterElem[1];
                return goodSince && goodTo && goodFilterBy;
            });
        });

        const groupBy = this.props.groupBy as keyof StatsKey;
        const groups = Array.from(
            new Set(data.map((elem: StatsData) => String(elem.key[groupBy])))
        );

        let datasets: Chart.ChartDataSets[] = [];

        // Variables used to visualise the cumulative time in hours depending on the selected modifier. 
        let totalValues = 0;
        let selectedModifier = this.props.modifier === OptionsModifier.VERIFICATIONS
            ? "totalVerification"
            : "totalAnnotation";

        const stateOptions = ["Declined", "Verified", "Discarded"];
        if (this.props.modifier === OptionsModifier.ANNOTATIONS) {
            stateOptions.unshift("NeedsVerification");
        }
        stateOptions.forEach((state: string, idx: number) => {
            let values = groups.map((group) => 0);
            let counts = groups.map((group) => 0);

            data.forEach((elem: StatsData) => {
                const group = String(elem.key[groupBy]);
                const idx = groups.indexOf(group);

                if (String(elem.key.verified) === state) {
                    const key = this.props.measureBy as keyof StatsData;
                    values[idx] += elem[key] as number;
                    counts[idx] += 1;
                }
            });

            const key = this.props.measureBy as keyof StatsData;

            if (key === "avgTime") {
                for (let idx = 0; idx < values.length; ++idx) {
                    values[idx] = values[idx] / Math.max(counts[idx], 1);
                }

                values = values.map((elem) => elem / 1000);
            } else if (key === "totalTime") {
                values = values.map((elem) => elem / (1000 * 60 * 60));

            } else if (key === "verificationTime") {
                values = values.map((elem) => elem / (1000 * 60 * 60));

            } else if (key === selectedModifier) {
                values.forEach(value => totalValues += (value / (1000 * 60 * 60)));
            }

            datasets.push({
                label: state,
                data: values,
                borderWidth: 1,
                backgroundColor: colours[idx % colours.length],
            });
        });
        // if we group by username it is useful to show name too
        let labels = groups.map((group) => {
            if (groupBy === ("username" as keyof StatsKey)) {
                const name = data.find((item) => item.key.username === group)?.key?.name || "";
                return `${group} (${name})`;
            }
            return group;
        });

        // Unlike the others, the cumulative hours chart does only uses one label: total number of hours.  
        const key = this.props.measureBy as keyof StatsData;
        if (key === selectedModifier) {
            labels = ["Total"]
            datasets = [{
                label: "Total",
                data: [totalValues],
                borderWidth: 1,
                backgroundColor: colours[0 % colours.length],
            }]
        }

        const barData: Chart.ChartData = {
            labels: labels,
            datasets: datasets,
        };

        const stacked = (this.props.measureBy !== "avgTime");

        const barOptions: Chart.ChartOptions = {
            title: {
                display: true,
                text: `${this.props.modifier} grouped by ${this.props.groupBy
                    } from ${this.props.since.toDateString()} to ${this.props.to.toDateString()}`,
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        },
                        stacked: stacked,
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                            labelString: this.props.groupBy,
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: stacked,
                        scaleLabel: {
                            display: true,
                            fontSize: 14,
                            labelString: this.props.measureText,
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    bottom: 50,
                },
            },
            responsive: true,
        };

        return <HorizontalBar data={barData} options={barOptions} />;
    }
}

interface State {
    since: string;
    barGroupBy: string;
    measureBy: string;
}

type Props = ProvidedAppStore & ChartWithOptionsProps;

@inject("store")
@observer
export class BarChartWithOptions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            since: "Last Week",
            barGroupBy: this.props.groupBys[0],
            measureBy: "count",
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event: any, data: any) {
        type NewState = Pick<State, keyof State>;
        const { name, value } = data;
        const newState = { [name]: value } as NewState;
        this.setState(newState);
    }

    render() {
        const groupByDropDown = this.props.groupBys.map((elem) => {
            return {
                key: elem,
                text: elem,
                value: elem,
            };
        });

        const { sinceDropDown, selectedTime } = timeOptions(new Date(), this.state.since);

        let nonCumulativeTime = "totalTime"
        let cumulativeTime = "totalAnnotation"
        if (this.props.modifier === OptionsModifier.VERIFICATIONS) {
            nonCumulativeTime = "verificationTime"
            cumulativeTime = "totalVerification"
        }

        const measureByDropDown = [
            {
                key: "count",
                text: `Number of ${this.props.modifier}`,
                value: "count",
            },
            {
                key: "totalTime",
                text: "Total Time (Hours)",
                value: nonCumulativeTime,
            },
            {
                key: "avgTime",
                text: "Average Time (Seconds)",
                value: "avgTime",
            },
            {
                key: "cumulative",
                text: "Cumulative Time (Hours)",
                value: cumulativeTime,
            },
        ];

        const measureBy = measureByDropDown.find((elem) => elem.value === this.state.measureBy);

        if (!selectedTime || !measureBy) {
            return <div>error!</div>;
        }

        return (
            <>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={4}>
                        <Dropdown
                            name="since"
                            header={`${this.props.modifier} Since`}
                            fluid
                            selection
                            value={this.state.since}
                            options={sinceDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Dropdown
                            name="barGroupBy"
                            header="Group By"
                            fluid
                            selection
                            value={this.state.barGroupBy}
                            options={groupByDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Dropdown
                            name="measureBy"
                            header="Measure By"
                            fluid
                            selection
                            value={this.state.measureBy}
                            options={measureByDropDown}
                            onChange={this.onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={12}>
                        <BarChart
                            groupBy={this.state.barGroupBy}
                            filterBy={this.props.filterBy}
                            since={selectedTime.since}
                            to={selectedTime.to}
                            measureBy={measureBy.value}
                            measureText={measureBy.text}
                            modifier={this.props.modifier || OptionsModifier.ANNOTATIONS}
                        />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }
}
