import React from "react";
import { inject } from "mobx-react";

import { Grid, Label, Divider, SemanticCOLORS } from "semantic-ui-react";
import { Image } from "../../models/Image";
import { Definition, SkeletonAnnotationAnswer } from "../../models/model_types/keypoints";
import { Image as SVGImage, InstanceGroup } from "../annotation/KeyPoint/Image";
import { fnGenerateVerifiedByJSX } from "../helpers/AnnotationInfo";
import { ProvidedAppStore } from "../../store/AppStore";

interface KeyPointExplorerProps {
    image: Image;
}

interface GroupName {
    name: string;
    color: string;
}

type Props = KeyPointExplorerProps & ProvidedAppStore;
@inject("store")
export class KeyPointExplorer extends React.Component<Props> {
    render() {
        const { modelType, annotations, predictions, bucketPath } = this.props.image;
        const definition = modelType.definition as Definition;

        const groupNames: GroupName[] = [];
        const groups: InstanceGroup[] = [];
        let verifiedByJSX = <div />;

        if (annotations.length > 0) {
            const lastAnnotation = annotations[annotations.length - 1];
            const answer = lastAnnotation.answerData as SkeletonAnnotationAnswer;
            if (lastAnnotation.verifier) {
                verifiedByJSX = fnGenerateVerifiedByJSX(lastAnnotation);
            }

            groupNames.push({ color: "pink", name: "annotation" });
            groups.push({ color: "pink", instances: answer.instances });
        }

        const colors = [
            "red",
            "orange",
            "yellow",
            "olive",
            "green",
            "teal",
            "blue",
            "violet",
            "purple",
            "brown",
            "grey",
            "black",
        ];

        for (let idx = 0; idx < predictions.length; ++idx) {
            const prediction = predictions[idx];
            const color = colors[idx % colors.length];
            const answer = prediction.answerData as SkeletonAnnotationAnswer;

            groupNames.push({ color: color, name: prediction.model.name });
            groups.push({ color: color, instances: answer.instances });
        }

        const labels = groupNames.map((group: GroupName, idx: number) => {
            return (
                <Label color={group.color as SemanticCOLORS} key={idx}>
                    {group.name}
                </Label>
            );
        });

        return (
            <>
                <Grid.Row>{verifiedByJSX}</Grid.Row>
                <Grid.Row>{labels}</Grid.Row>
                <Divider hidden={true} />
                <Grid.Row>
                    <SVGImage
                        groups={groups}
                        url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                        definition={definition.keypoints}
                    />
                </Grid.Row>
            </>
        );
    }
}
